import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Icon,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { t } from '../../common/i18n/translate';

function NummerAuswaehlen({ showDialog, value, label, onDialogClose }) {
  const [nummer, setNummer] = useState(value);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDialog || false}
      TransitionProps={{
        onEnter: () => setNummer(value),
        onClose: () => onDialogClose(null),
      }}
      onKeyPress={e => (e.key === 'Enter' ? onDialogClose(nummer) : '')}
    >
      <MuiDialogTitle>
        {label}
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon>close</Icon>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <TextField
          value={nummer}
          onChange={event => setNummer(event.target.value)}
          style={{ width: '100%' }}
          autoFocus
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(nummer)}>
          {t('speichern')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NummerAuswaehlen;
