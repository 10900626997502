import type { Action } from 'redux';

const SET_FILTER = 'SET_FILTER';
const SET_SUCHE_TEXT = 'SET_SUCHE_TEXT';
const SET_PAGE = 'SET_PAGE';

const jahr = new Date().getFullYear();
const sportjahre = [
  { title: (jahr - 2).toString(10), value: jahr - 2 },
  { title: (jahr - 1).toString(10), value: jahr - 1 },
  { title: (jahr).toString(10), value: jahr },
  { title: (jahr + 1).toString(10), value: jahr + 1 },
  { title: (jahr + 2).toString(10), value: jahr + 2 },
];

interface ActionWithData extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: string]: any;
  // [propName: string]: unknown,
}

interface Filter {
  sportjahr: number[],
  regionalverband: number[],
  rv_anlaesse: string[],
  disziplin: string[],
  status: string[],
  sektion_rayon: string,
  nur_eigene: boolean,
}

const defaultFilter: Filter = {
  sportjahr: [
    sportjahre[2].value,
    sportjahre[3].value,
  ],
  regionalverband: [],
  rv_anlaesse: [],
  disziplin: [],
  status: [],
  sektion_rayon: '',
  nur_eigene: true,
};

export const INITIAL_STATE = {
  page: 1,
  filter: defaultFilter,
  filterWerte: {
    sportjahr: sportjahre,
  },
  available: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const filterReducer = (state = INITIAL_STATE, action: ActionWithData) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.neuerFilter ?? defaultFilter,
        // WICHITG: resette auf 1 damit erste page geladen + gezeigt wird
        page: 1,
      };
    case SET_PAGE:
      return { ...state, page: action.neuePage };
    default:
      return state;
  }
};

export const setFilter = (neuerFilter: Filter): ActionWithData => ({ type: SET_FILTER, neuerFilter });
export const setPage = (neuePage: number): ActionWithData => ({ type: SET_PAGE, neuePage });
