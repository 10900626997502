import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import withPageTracking from '../common/tracking/withPageTracking';
import Juryrapport from './Juryrapport';

function Router() {
  const routerBasename = '/juryrapporte';
  return (
    <BrowserRouter basename={routerBasename}>
      <Routes>
        <Route path="/:juryrapport_id" element={withPageTracking(Juryrapport, routerBasename)} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
