import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { downloadVeranstaltungenZumKopieren } from '../../../common/modules/api';
import Spinner from '../../../common/Spinner';
import { dateRange, formatDate } from '../../../common/modules/helpers';

function VeranstaltungKopieren(props) {
  const [veranstaltungen, setVeranstaltungen] = useState(undefined);

  useEffect(() => {
    if (veranstaltungen === undefined) {
      downloadVeranstaltungenZumKopieren(null, (data) => {
        setVeranstaltungen(data.map(v => ({
          ...v,
          label: [
            [
              dateRange(new Date(v.von), new Date(v.bis)),
              v.typ,
              v.ort,
              v.kanton,
            ].join('  '),
            v.status_label,
            v.sequenz_nummer,
          ].join(', '),
        })));
      });
    }
  }, []);

  if (veranstaltungen !== undefined) {
    const formFields = [
      {
        key: 1,
        feldTyp: 'wrapper',
        className: 'flex-row',
        children: [
          {
            feldTyp: 'autocomplete',
            name: 'veranstaltung_id',
            label: t('veranstaltung'),
            options: veranstaltungen,
            optionValue: 'id',
            renderOption: (o_props, option) => (
              <Grid {...o_props} key={option.id} container justify="space-between" alignItems="flex-start">
                <Typography style={{ width: '24%' }}>
                  {formatDate(new Date(option.von))}
                  {' '}
                  -
                  {' '}
                  {formatDate(new Date(option.bis))}
                </Typography>
                <Typography style={{ width: '6%' }}>{option.modus}</Typography>
                <Typography style={{ width: '30%' }}>
                  {option.typ}
                  {' '}
                  {option.ort}
                  {' '}
                  {option.kanton}
                </Typography>
                <Typography style={{ width: '25%' }}>{option.status_label}</Typography>
                <Typography style={{ width: '15%', textAlign: 'right' }}>{option.sequenz_nummer}</Typography>
              </Grid>
            ),
          },
        ],
      },
    ];
    return (
      <div className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1">
          <div className="flex-column flex-1">
            {
              (!!veranstaltungen.length) && (
              <>
                <Card>
                  <div style={{ padding: '20px' }}>
                    <CardContent>
                      <Typography variant="h3" component="h3">
                        {t('veranstaltung_kopieren_titel')}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {t('veranstaltung_kopieren_beschreibung')}
                      </Typography>
                      <div>&nbsp;</div>
                      <Formular
                        fields={formFields}
                        defaultValues={{}}
                        submitButtonKey="veranstaltung_kopieren_button"
                        handleSubmit={(formData) => {
                          if (formData.veranstaltung_id) {
                            window.location.href = `#/copy/${ formData.veranstaltung_id}`;
                          }
                        }}
                        handleCancel={null}
                      />
                    </CardContent>
                  </div>
                </Card>
                <div>&nbsp;</div>
              </>
              )
            }
            <Card>
              <div style={{ padding: '20px' }}>
                <CardContent>
                  <Typography variant="h3" component="h3">
                    {t('veranstaltung_nicht_kopieren_titel')}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {t('veranstaltung_nicht_kopieren_beschreibung')}
                  </Typography>
                  <div>&nbsp;</div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.href = '#/build'}
                  >
                    {t('veranstaltung_nicht_kopieren_button')}
                  </Button>
                </CardContent>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <Spinner />;
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VeranstaltungKopieren);
