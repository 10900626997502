import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Veranstaltungsdaten from '../Veranstaltungsdaten';
import '../index.scss';
import { downloadKopierteVeranstaltung } from '../../../common/modules/api';
import { veranstaltungLaden } from '../../store/modules/veranstaltungen';
import { objIsEmpty } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';
import { getLanguage } from '../../../common/i18n/translate';

function NeuAusKopie(props) {
  const { fromId } = useParams();

  useEffect(() => {
    downloadKopierteVeranstaltung(fromId, newData => props.veranstaltungLaden(newData));
  }, [fromId]);

  if (objIsEmpty(props.veranstaltungen.current) || objIsEmpty(props.stammdaten)) return <Spinner />;

  return <Veranstaltungsdaten />;
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NeuAusKopie);
