import { Controller } from "@hotwired/stimulus"
import { useClickOutside, useWindowResize } from 'stimulus-use'
import { isMobileApp } from "../packs/global/utils"

// Connects to data-controller="sidedrawer"
export default class extends Controller {
  static targets = ["sidedrawer"];

  connect() {
    this.windowWidth = window.innerWidth;
    this.mobileSidedrawerSize = "70vw";
    this.setupEventListeners();

    useWindowResize(this)
    if (this.needsToListenToClickOutside) {
      useClickOutside(this, { element: this.sidedrawer });
    }
  }

  disconnect() {
    window.removeEventListener("toggle-sidedrawer", this.boundToggleMobileVersion);
    window.removeEventListener("close-sidedrawer", this.boundCloseMobileVersion);
  }

  setupEventListeners() {
    this.boundToggleMobileVersion = this.toggleMobileVersion.bind(this)
    this.boundCloseMobileVersion = this.closeMobileVersion.bind(this)
    window.addEventListener("toggle-sidedrawer", this.boundToggleMobileVersion);
    window.addEventListener("close-sidedrawer", this.boundCloseMobileVersion);
  }

  windowResize({ width }) {
    this.windowWidth = width;
    if (this.needsToListenToClickOutside) {
      useClickOutside(this, { element: this.sidedrawer });
    }
  }

  openLink(event) {
    const href = event.target.closest('.sidedrawer-link').dataset.href
    const frontend = event.target.closest('.sidedrawer-link').dataset.frontend
    const currentFrontend = document.querySelector('meta[name="current-frontend"]').content;

    const shouldOpenInNewTab = event.metaKey || event.ctrlKey || event.shiftKey;
    if (shouldOpenInNewTab) {
      window.open(href, '_blank');
      return;
    }

    let navigateWithTurboDrive = frontend == currentFrontend;
    if (isMobileApp) {
      this.openLinkWithTimeout(href, navigateWithTurboDrive);
    } else {
      navigateWithTurboDrive ? Turbo.visit(href) : location.href = href;
    }
  }

  openLinkWithTimeout(href, navigateWithTurboDrive = true) {
    this.closeMobileVersion();

    // Wenn der Link zu früh aufgerufen wird, ist der Sidedrawer noch nicht geschlossen wenn TurboNative ein cache-screenshot macht.
    // Dies führt beim zurücknavigieren zu einem "flackern".
    // Hack: Durch das setTimeout sicherstellen, dass der Sidedrawer geschlossen ist, bevor der Link aufgerufen wird.
    setTimeout(() => {
      navigateWithTurboDrive ? Turbo.visit(href) : location.href = href
    }, 10);
  }

  toggleDesktopVersion() {
    document.querySelector('#top-bar-logo').classList.toggle('image-hidden');
    document.querySelector('body').classList.toggle('hidden-sidedrawer');
  }

  toggleMobileVersion() {
    if (this.sidedrawer.style.width === this.mobileSidedrawerSize) {
      this.closeMobileVersion()
    } else {
      this.openMobileVersion()
    }
  }

  openMobileVersion() {
    // Hack: Damit Turbo Native Android beim hochscrollen nicht das "pull-to-refresh" aktiviert.
    // https://github.com/hotwired/turbo-android/issues/294
    document.body.style.overflowY = "hidden";
    document.body.style.minHeight = "calc(100vh + 1px)";
    window.scrollBy(0, 1);

    this.sidedrawer.style.width = this.mobileSidedrawerSize;
    document.querySelector('#backdrop-element').style.display = "block";
  }

  closeMobileVersion() {
    document.body.style.overflowY = "auto";
    document.body.style.minHeight = "auto";
    window.scrollBy(0, -1);

    this.sidedrawer.style.width = "0";
    document.querySelector('#backdrop-element').style.display = "none";
  }

  clickOutside() {
    if (this.needsToListenToClickOutside) {
      this.closeMobileVersion();
    }
  }

  // Bei kleinen Bildschirmen, wird der Sidedrawer geschlossen wenn ausserhalb geklickt wird
  // Bei Turbo Native App wird der Sidedrawer immer geschlossen wenn ausserhalb geklickt wird
  get needsToListenToClickOutside() {
    return this.windowWidth <= 768 || isMobileApp;
  }

  get sidedrawer() {
    return this.sidedrawerTarget;
  }
}
