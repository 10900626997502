import type { Action } from 'redux';

const SET_FILTER = 'SET_VERANSTALTER_FILTER';
const SET_PAGE = 'SET_VERANSTALTER_PAGE';

interface ActionWithData extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: string]: any;
  // [propName: string]: unknown,
}

interface Filter {
  rv_mitglied: string,
  aktiv: string,
  sektionRayon: string,
  sucheText: string,
}

const defaultFilter: Filter = {
  rv_mitglied: 'alle',
  aktiv: 'alle',
  sektionRayon: '',
  sucheText: '',
};

export const INITIAL_STATE = {
  page: 1,
  filter: defaultFilter,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const veranstalterFilterReducer = (state = INITIAL_STATE, action: ActionWithData) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.neuerFilter ?? defaultFilter,
        // WICHITG: resette auf 1 damit erste page geladen + gezeigt wird
        page: 1,
      };
    case SET_PAGE:
      return { ...state, page: action.neuePage };
    default:
      return state;
  }
};

export const setFilter = (neuerFilter: Filter): ActionWithData => ({ type: SET_FILTER, neuerFilter });
export const setPage = (neuePage: number): ActionWithData => ({ type: SET_PAGE, neuePage });
