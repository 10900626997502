import React, { useState } from 'react';
import {
  Button,
  Box,
  Menu,
  MenuItem,
  Link,
  Badge,
  useMediaQuery,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LanguageToggle from './LanguageToggle';
import { t } from './common/i18n/translate';
import themeMUI5 from './common/EquisThemeMUI5';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none', // mit dieser Option wird der text auf einem button nicht automatisch gross geschrieben
    },
  },
});

export default function BenutzerMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const biggerScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ThemeProvider theme={themeMUI5}>
      {props.user_ist_eingeloggt
        && (
        <Link href="/finanzen" style={{ marginRight: 10 }}>
          <Badge id="warenkorb-badge" badgeContent={props.warenkorb_anzahl_positionen} color="primary">
            <ShoppingCartIcon color="action" />
          </Badge>
        </Link>
        )}
      <Button color="active"
        id="basic-button"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {biggerScreen && (
          <Box mt={0.5}>
            <Box>{props.user}</Box>
            <Box sx={{ color: '#a1a1a1', fontSize: 12 }}>{props.impersonation_benutzer}</Box>
          </Box>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <LanguageToggle links={props.links} lang={props.lang} />
        </MenuItem>
        {props.user_ist_eingeloggt && (
          <MenuItem>
            <Link href={props.logout_path} data-turbo-method="delete" color="primary" underline="none">{t('abmelden')}</Link>
          </MenuItem>
        )}
      </Menu>
    </ThemeProvider>
  );
}
