import type { Action } from 'redux';

export const INITIAL_STATE = {
  locale: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};
