function Flash(undefined) {
  var $flashContainer = $('#flash-messages'),
      $template = $('<div></div>').addClass('alert'),
      types = ['notice', 'error', 'alert'],
      types_length = types.length;

  var _add = function(flash) {
    var type = flash['type'],
        message = flash['message'];

    if(!_isValidateType(type)) {
      type = 'info';
    }

    $template
        .clone()
        .addClass('alert-' + type)
        .text(message)
        .appendTo($flashContainer);
  };

  var _clear = function() {
    $flashContainer.empty();
  };

  var _isValidateType = function(type) {
    var found = false;
        i = types_length;
    while(i -= 1) {
      found = types[i] == type;
      if(found) {
        break;
      }
    }
    return found;
  };

  var global = {
    add: function(flash) {
      _add(flash);
    },
    adds: function(flashes) {
      var flashes_length = flashes.length,
          i = 0;
      for(i; i < flashes_length; i++) {
        _add(flash[i]);
      }
    },
    clear: function() {
      _clear();
    }
  };

  return global;
}

document.addEventListener('turbo:load', function() {
  window.flash = new Flash();
});
