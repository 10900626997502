import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';

import { useAppSelector } from '../redux/hooks';
import { t, t_var } from '../../common/i18n/translate';

function BooleanFrage({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);
  const { antwort } = antwortDetails;
  return (
    <ListItem divider className="booleanFrage">
      <ListItemText primary={t_var(frage, 'titel')} secondary={t_var(frage, 'untertitel')} />
      <ListItemSecondaryAction>
        <Button
          variant={antwort === true ? 'contained' : null}
          color={antwort === true ? 'primary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => updateAntwort(true)}
        >
          {t('ja')}
        </Button>
        <Button
          variant={antwort === false ? 'contained' : null}
          color={antwort === false ? 'secondary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => updateAntwort(false)}
        >
          {t('nein')}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default BooleanFrage;
