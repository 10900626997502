import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableFooter,
  Typography,
  Button,
  Icon,
  Popover,
} from '@mui/material';
import { formatDate, currency } from '../../../common/modules/helpers';
import { t } from '../../../common/i18n/translate';

function NennungenTabelle(props) {
  const [popoverAnker, setPopoverAnker] = useState({});

  const popoverAnzeigen = (event, popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = event.target;
    setPopoverAnker(ankerListe);
  };

  const popoverSchliessen = (popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = null;
    setPopoverAnker(ankerListe);
  };

  return (
    <Table size="small" className="table nennungen-tabelle">
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: 'wrappable' }}>{t('gruppenname')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('longenfuehrer')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('pferd_e')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('status')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('bemerkungen')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('erstellt_modifiziert')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }} align="right">{t('ng')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.nennungen.map((nennung) => {
            let changelogDisplay = '';
            if (nennung.changelog.length > 0) {
              // zeitstempel, aktion, parameter
              const replacePlaceholders = (string, parameter) => {
                const regex = /%{[^}]*}/g;
                const matches = string.match(regex);
                let newString = '';
                if (matches && matches.length) {
                  matches.forEach((replaceKey) => {
                    newString = newString.replace(replaceKey, parameter[replaceKey.substr(2, replaceKey.length - 3)]);
                  });
                }
                return newString;
              };
              const popoverInhalt = (
                <div className="changelog-popup">
                  <Typography>
                    <strong>{t('changelog')}</strong>
                  </Typography>
                  <Typography>
                    {nennung.reiter_name}
                    {' '}
                    /
                    {nennung.pferde.map(p => (
                      p.name
                    )).join(' / ')}
                  </Typography>
                  <br />
                  <Table size="small" className="table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {formatDate(new Date(nennung.created_at), 'dd.mm.YY hh:mm')}
                        </TableCell>
                        <TableCell>
                          <strong>{t('changelog_created')}</strong>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {nennung.changelog.map(change => (
                        <TableRow key={change.zeitstempel}>
                          <TableCell>
                            {formatDate(new Date(change.zeitstempel), 'dd.mm.YY hh:mm')}
                          </TableCell>
                          <TableCell>
                            <strong>{t(`changelog_${change.aktion}`)}</strong>
                          </TableCell>
                          <TableCell>
                            {replacePlaceholders(t(`changelog_${change.aktion}_2`), change.parameter)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              );
              const popoverId = `nennung-${nennung.id}-changelog`;
              changelogDisplay = (
                <>
                  <Button onClick={(event) => {
                    popoverAnzeigen(event, popoverId);
                  }}
                  >
                    <Icon>info</Icon>
                  </Button>
                  <Popover
                    id={popoverId}
                    open={popoverAnker[popoverId] !== null && popoverAnker[popoverId] !== undefined}
                    anchorEl={popoverAnker[popoverId]}
                    onClose={() => popoverSchliessen(popoverId)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {popoverInhalt}
                  </Popover>
                </>
              );
            }
            return (
              <TableRow key={nennung.id} classes={{ root: `nennung-${nennung.id}` }}>
                <TableCell>{nennung.gruppenname}</TableCell>
                <TableCell>{nennung.longenfuehrer_name}</TableCell>
                <TableCell>
                  <table width="100%" className={nennung.pferde.length > 1 ? 'kleine-pferde' : ''}>
                    <tbody>
                      {nennung.pferde.map(p => (
                        <tr key={p.id}>
                          <td style={{ minWidth: '50px', width: '50px' }}>{p.id}</td>
                          <td>{p.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableCell>
                <TableCell>{nennung.status}</TableCell>
                <TableCell classes={{ root: 'wrappable bemerkungen' }}>{nennung.bemerkungen}</TableCell>
                <TableCell>
                  {formatDate(new Date(nennung.created_at), 'dd.mm.YY hh:mm')}
                  {changelogDisplay}
                </TableCell>
                <TableCell align="right">
                  {currency(nennung.bezahltes_nenngeld)}
                  {' '}
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan="6">
            {props.totalLabel}
            :
            {' '}
            {props.nennungen.length}
          </TableCell>
          <TableCell align="right">
            {currency(
              props.nennungen.map(n => n.bezahltes_nenngeld).reduce((a, b) => a + b, 0),
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

// const mapStateToProps = state => ({});

// const mapDispatchToProps = dispatch => ({});

export default connect(null, null)(NennungenTabelle);
