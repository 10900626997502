import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, TextField } from '@mui/material';
import AutocompletePferd from '../../common/AutocompletePferd';
import { t_var } from '../../common/i18n/translate';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { setPferdeAktionFuerJueryrapport, removePferdeAktionFromStore, deletePferdAktionen } from '../redux/Juryrapport';

function AutocompletePferdField({ pferd, handleUpdate }) {
  return (
    <AutocompletePferd
      value={pferd}
      label="Pferd"
      disabled={false}
      required
      onChange={(e, neuePferdeDetails) => handleUpdate(neuePferdeDetails)}
    />
  );
}

function ImpfproblemFrage({ frage, antwortDetails, updateAntwort }) {
  const config = frage.config || {};
  const nummer = antwortDetails.details || config.default || 0;

  const { rapport, pferdAktionen } = useAppSelector(state => state.juryrapport);
  const dispatch = useAppDispatch();
  const handleSetPferdeAktion = (...args) => setPferdeAktionFuerJueryrapport(...args)(dispatch);
  const handleRemovePferdeAktionFromStore = (...args) => removePferdeAktionFromStore(...args)(dispatch);
  const handleDeletePferdeAktionen = (...args) => deletePferdAktionen(...args)(dispatch);

  const handleChange = (value) => {
    const parsedValue = Math.max(0, parseInt(value));

    updateAntwort(null, parsedValue);

    // Wenn Pferdeanzahl runter geht, PferdeAktion löschen die nicht mehr angezeigt wird
    if (parsedValue < nummer) {
      const pferdAktionZumLoeschen = pferdAktionen[parsedValue];
      if (pferdAktionZumLoeschen != null) {
        const { pferd_aktion_id } = pferdAktionen[parsedValue];
        handleDeletePferdeAktionen(rapport.id, pferd_aktion_id);
        handleRemovePferdeAktionFromStore(pferd_aktion_id);
      }
    }
  };

  return (
    <>
      <ListItem divider className="nummerFrageSimple">
        <ListItemText primary={t_var(frage, 'titel')} secondary={t_var(frage, 'untertitel')} />
        <ListItemSecondaryAction>
          <TextField
            value={nummer}
            disabled={rapport.status !== 'offen'}
            onChange={event => handleChange(event.target.value)}
            fullWidth
            inputProps={{
              min: config.min,
              max: config.max,
              style: { textAlign: 'center', maxWidth: '50px' },
            }}
            type="number"
          />
        </ListItemSecondaryAction>
      </ListItem>
      {[...Array(nummer ?? 0).keys()].map(zaehler => (
        <ListItem divider={false} className="autocompletePferde" key={`pferdaktion-${zaehler}`}>
          <AutocompletePferdField
            pferd={pferdAktionen[zaehler]}
            handleUpdate={(neuePferdeDetails) => {
              const pferdAktionId = pferdAktionen[zaehler] === undefined ? Date.now() : pferdAktionen[zaehler].pferd_aktion_id;
              // Wenn ein Pferd aus dem Autocomplete Feld gelöscht wurde -> auf dem Server pferdAktion löschen und Store updaten
              if (neuePferdeDetails == null) {
                handleDeletePferdeAktionen(rapport.id, pferdAktionId);
                handleRemovePferdeAktionFromStore(pferdAktionId);
              } else {
                handleSetPferdeAktion(pferdAktionId, neuePferdeDetails, rapport.id);
              }
            }}
          />
        </ListItem>
      ))}
    </>
  );
}

export default ImpfproblemFrage;
