import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js'
import { isMobile } from "../../packs/global/utils";

// Connects to data-controller="partials--werbung"
export default class extends Controller {
  static targets = ["img"];
  static values = {
    scope: String,
    sprache: String,
    werbung: Object,
  }

  connect() {
    this.werbeBildURL()
  }

  werbungValueChanged() {
    this.imgTarget.src = this.werbungValue?.bildUrl
    this.imgTarget.dataset.url = this.werbungValue?.werbeUrl
  }

  // Fragt den Server nach der Werbebild-URL
  async werbeBildURL() {
    const typ = isMobile() ? 'mobile' : 'normal'
    const url = `/extern/werbeflaeche?scope=${this.scopeValue}&typ=${typ}&sprache=${this.spracheValue}`;

    const response = await get(url, {  contentType: "application/json"})
    if (response.ok) {
      const body = await response.json
      this.werbungValue = { bildUrl: body?.bildURL, werbeUrl: body?.werbeURL }
    }
  }
}
