import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';

import { t, getLanguage } from '../../../common/i18n/translate';
import Formular, { MultiSelectField } from '../../../common/Formular';
import { updateVeranstaltung, loescheVeranstaltung } from '../../../common/modules/api';
import { objIsEmpty, formatDate, getDatesInRange } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';

const getArrayChunks = (a, size) => Array.from(
  new Array(Math.ceil(a.length / size)),
  (_, i) => a.slice(i * size, i * size + size),
);

function VeranstaltungsdatenFormular(props) {
  const [rvAnlassTage, setRvAnlassTage] = useState({}); // useEffect wird initialisieren
  if (props.veranstaltung.id) props.formData.id = props.veranstaltung.id;

  // initialisiere rvAnlassTage immer wenn gewaehlte veranstaltung aendert
  useEffect(() => {
    setRvAnlassTage(props.veranstaltung?.forms?.veranstaltungsdaten.anlass_durchfuehrungen.reduce((obj, rvad) => ({
      ...obj,
      [rvad.anlass_id]: rvad.tage,
    }), {}) ?? {});
  }, [props.veranstaltung]);

  if (objIsEmpty(props.stammdaten)) {
    return <Spinner />;
  }

  if (!props.veranstaltung.id) {
    props.formData.oas_verantwortliche_r = props.stammdaten.current_user_person;
    props.formData.von = new Date();
    props.formData.bis = new Date();
    props.formData.sprache = 'D';
  } else if (!props.formData.oas_verantwortliche_r) {
    props.formData.oas_verantwortliche_r = props.stammdaten.current_user_person;
  }

  // initialisiere Anlaesse-Select
  props.formData.rv_anlaesse = props.veranstaltung?.forms?.veranstaltungsdaten.anlass_durchfuehrungen.map(rvad => rvad.anlass_id);

  let handleDelete = null;
  if (props.veranstaltung.status === 'in_erfassung') {
    handleDelete = () => loescheVeranstaltung(props.veranstaltung.id);
  }
  let istErsterfassung = false;
  if (!props.veranstaltung.status || props.veranstaltung.status === 'in_erfassung') {
    istErsterfassung = true;
  }
  if (props.veranstaltung.status === 'zur_voranzeige' && props.veranstaltung.ausschreibungs_ergaenzungen === undefined) {
    props.veranstaltung.ausschreibungs_ergaenzungen = '';
  }

  const formFields = [
    {
      key: 0,
      feldTyp: 'hidden',
      name: 'id',
    },
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '1.1',
          feldTyp: 'autocomplete',
          name: 'veranstalter_id',
          label: t('veranstalter'),
          options: props.stammdaten.veranstalter,
          optionValue: 'id',
          optionLabel: 'name',
          required: true,
          disabled: !istErsterfassung,
          additionalChanges: (value) => {
            const veranstalter = props.stammdaten.veranstalter.find(v => v.id === value) || {};
            return { veranstalter_ist_verbandsmitglied: !!veranstalter.regionalverband_mitglied };
          },
        },
        {
          key: '1.2',
          feldTyp: 'autocomplete',
          name: 'regionalverband_id',
          label: t('regionalverband'),
          options: props.stammdaten.regionalverbaende,
          optionValue: 'id',
          optionLabel: 'label',
          required: true,
          disabled: !istErsterfassung,
          additionalChanges: (value) => {
            setRvAnlassTage({});
            return { rv_anlaesse: [] };
          },
        },
      ],
    },
    {
      key: 2,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '2.1',
          feldTyp: 'plz_autocomplete',
          name: 'ort_id',
          label: t('plz_ort_kanton'),
          required: true,
          disabled: !istErsterfassung,
        },
        {
          key: '2.2',
          feldTyp: 'checkbox',
          name: 'veranstalter_ist_verbandsmitglied',
          disabled: true,
        },
      ],
    },
    {
      key: 3,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '3.1',
          feldTyp: 'autocomplete_person',
          name: 'ok_praesident_in',
          required: true,
          skipItem: !istErsterfassung,
        },
        {
          key: '3.2',
          feldTyp: 'autocomplete_person',
          name: 'oas_verantwortliche_r',
          required: true,
          disabledCondition: values =>
            // disabled wenn vorhanden - wird immer der Fall sein weil das (aktuell #17) auf den aktuellen Benutzer gesetzt wird falls leer
            !!values.oas_verantwortliche_r
          ,
        },
      ],
    },
    {
      key: 4,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '4.1',
          feldTyp: 'datepicker',
          name: 'von',
          required: true,
          disabled: !istErsterfassung,
          disablePast: istErsterfassung, // kein Range-Check mit Fehler wenn nicht bearbeitbar
          additionalChanges: (value) => {
            // resette die gewaehlten tage
            setRvAnlassTage(Object.keys(rvAnlassTage).reduce((a, rvaId) => ({ ...a, [rvaId]: [] }), {}));
            return { bis: value };
          },
        },
        {
          key: '4.2',
          feldTyp: 'datepicker',
          name: 'bis',
          required: true,
          disabled: !istErsterfassung,
          disablePast: true, // kein Range-Check mit Fehler wenn nicht bearbeitbar
          additionalChanges: (value) => {
            // resette die gewaehlten tage
            setRvAnlassTage(Object.keys(rvAnlassTage).reduce((a, rvaId) => ({ ...a, [rvaId]: [] }), {}));
            return {};
          },
          minDateGenerator: (values) => {
            if (istErsterfassung) {
              return values.von;
            }
            return undefined; // kein Range-Check mit Fehler wenn nicht bearbeitbar
          },
        },
        {
          key: '4.3',
          feldTyp: 'select',
          name: 'sprache',
          options: [{ value: 'D' }, { value: 'F' }, { value: 'I' }, { value: 'E' }],
          required: true,
          disabled: !istErsterfassung,
        },
      ],
    },
    {
      key: 5,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '5.1',
          feldTyp: 'autocomplete',
          name: 'typ_id',
          label: t('typ'),
          options: props.stammdaten.veranstaltung_typen,
          optionValue: 'id',
          optionLabel: 'label',
          required: true,
          disabled: !istErsterfassung,
          // da nur Reitturniere (Sub)-Disziplinen haben, setzen wir diese beim Wechsel des Typs zurück
          additionalChanges: value => ({ cache_disziplinen: null }),
        },
        {
          key: '5.2',
          feldTyp: 'autocomplete',
          name: 'modus_id',
          label: t('modus'),
          options: props.stammdaten.modus,
          optionValue: 'id',
          optionLabel: 'label',
          required: true,
          disabled: !istErsterfassung,
        },
      ],
    },
    {
      key: 6,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '6.1',
          feldTyp: 'text',
          name: 'vorgesehene_pruefungen',
          multiline: true,
          required: true,
          disabled: !istErsterfassung,
        },
        {
          key: '6.2',
          feldTyp: 'text',
          name: 'website',
        },
      ],
    },
    {
      key: 7,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '7.1',
          feldTyp: 'checkbox',
          name: 'hat_stilpruefungen',
          // resette beide anzahl auf 0 falls auf false zurueckgestellt wird
          additionalChanges: value => (value ? {} : { anzahl_b100_stilpruefungen: 0, anzahl_p95_stilpruefungen: 0 }),
        },
        {
          key: '7.2',
          feldTyp: 'text',
          name: 'anzahl_b100_stilpruefungen',
          displayCondition: data => data.hat_stilpruefungen,
          // disabled: !istErsterfassung,
        },
        {
          key: '7.3',
          feldTyp: 'text',
          name: 'anzahl_p95_stilpruefungen',
          displayCondition: data => data.hat_stilpruefungen,
          // disabled: !istErsterfassung,
        },
      ],
    },
    {
      key: 8,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '8.1',
          feldTyp: 'checkbox',
          name: 'keine_anlaesse',
          disabled: !istErsterfassung,
        },
        {
          key: '8.2',
          feldTyp: 'autocomplete',
          name: 'rv_anlaesse',
          multiple: true,
          options: props.stammdaten.rv_anlaesse,
          optionValue: 'id',
          optionLabel: 'label',
          additionalChanges: (value) => {
            // aufpassen dass key von anfang an string ist, wird sonst spater zu string wegen Object.keys conversion zu string
            setRvAnlassTage(value.reduce((a, rvaId) => ({ ...a, [String(rvaId)]: (rvAnlassTage[String(rvaId)] ?? []) }), {}));
            return {};
          },
          filterOptions: (data, options) => options.filter(o => o.regionalverband_id === data.regionalverband_id
            || o.organisation_id === props.stammdaten.regionalverbaende.find(rv => rv.id === data.regionalverband_id)?.ueber_organisation_id),
          displayCondition: data => !data.keine_anlaesse,
          disabled: !istErsterfassung,
        },
      ],
    },
    {
      key: 9,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '9.1',
          feldTyp: 'autocomplete',
          name: 'cache_disziplinen',
          label: t('disziplinen'),
          multiple: true,
          options: props.stammdaten.disziplinen,
          optionValue: 'code',
          optionLabel: 'label',
          displayCondition: data => data.typ_id === 66, // CH - Reitturnier
        },
      ],
    },
    {
      key: 10,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '10.1',
          feldTyp: 'text',
          name: 'ausschreibungen_kontaktdaten',
        },
      ],
    },
    {
      key: '10.2',
      feldTyp: 'whitespace',
    },
    {
      key: 11,
      feldTyp: 'fieldset',
      label: t('zusatzinfos_anlaesse'),
      children: getArrayChunks(Object.keys(rvAnlassTage), 2).map(rvaChunk => ({
        key: `11.1.${rvaChunk.join('-')}`,
        feldTyp: 'wrapper',
        className: 'flex-row mt-1',
        children: rvaChunk.map(gewaehlterRvAnlassId => ({
          key: `11.1.${gewaehlterRvAnlassId}`,
          feldTyp: 'wrapper',
          className: 'flex-column flex-1',
          children: [
            {
              key: `11.1.${gewaehlterRvAnlassId}.label`,
              feldTyp: 'label',
              label: props.stammdaten.rv_anlaesse.find(rva => rva.id === Number(gewaehlterRvAnlassId)).label,
              style: { whiteSpace: 'nowrap', paddingBottom: '1em' },
            },
            {
              key: `11.1.${gewaehlterRvAnlassId}.input`,
              feldTyp: 'dynamic_component',
              dynamicComponent: fieldValues => (
                <MultiSelectField
                  sx={{ mr: 2 }}
                  value={rvAnlassTage[gewaehlterRvAnlassId]}
                  options={getDatesInRange(new Date(fieldValues.von), new Date(fieldValues.bis)).map(optionDate => ({
                    id: formatDate(optionDate, 'YYYY-mm-dd'),
                    label: formatDate(optionDate, 'weekday dd.mm.YYYY'),
                  }))}
                  optionValue="id"
                  optionLabel="label"
                  handleChange={(_, newValue) => {
                    setRvAnlassTage({ ...rvAnlassTage, [gewaehlterRvAnlassId]: newValue });
                  }}
                  label={t('durchfuehrungstage')}
                  labelVariant="outlined"
                  variant="outlined"
                  disabled={!istErsterfassung}
                  useDefaultRenderValue
                />
              ),
            },
          ],
        })),
      })),
      displayCondition: data => !data.keine_anlaesse && Object.keys(rvAnlassTage).length > 0 && data.von && data.bis,
    },
    {
      key: 12,
      feldTyp: 'wrapper',
      className: 'flex-row',
      skipItem: istErsterfassung,
      children: [
        {
          key: '12.1',
          feldTyp: 'datepicker',
          name: props.veranstaltung.nennvariante === 'nennphase' ? 'nennphase_start' : 'nennschluss_definitiv',
          required: true,
          disabled: props.veranstaltung.status !== 'zur_voranzeige',
        },
        {
          key: '12.2',
          feldTyp: 'datepicker',
          name: 'nachnennphase_start',
          required: false,
          disabled: props.veranstaltung.status !== 'zur_voranzeige',
        },
        {
          key: '12.3',
          feldTyp: 'text',
          name: 'nachnennphase_nenngeld_erhoehung',
          required: false,
          disabled: props.veranstaltung.status !== 'zur_voranzeige',
        },
      ],
    },
    {
      key: 13,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '13.1',
          feldTyp: 'text',
          name: 'ausschreibungs_ergaenzungen',
        },
      ],
      skipItem: props.veranstaltung.status !== 'zur_voranzeige',
    },
  ];

  let sequenzNummer = null;
  if (props.veranstaltung.sequenz_nummer) {
    sequenzNummer = (
      <div style={{ textAlign: 'right' }}>
        <Typography>
          {props.veranstaltung.sequenz_nummer}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Formular
        formId="veranstaltungsdaten"
        fields={formFields}
        disabled={props.veranstaltung.id && !(
          props.veranstaltung.status === 'in_erfassung'
          || props.veranstaltung.status === 'zur_voranzeige'
          || props.veranstaltung.status === 'in_ausschreibung'
        )}
        submitButtonKey={props.veranstaltung.id ? 'save' : 'create'}
        handleSubmit={fieldValues => updateVeranstaltung({ ...fieldValues, rv_anlass_tage: rvAnlassTage })}
        handleDelete={props.veranstaltung.id ? handleDelete : null}
        defaultValues={props.formData}
        wrapInPaper
      />
      {sequenzNummer}
    </>
  );
}

const mapStateToProps = (state) => {
  const current = state.veranstaltungen.current || {};
  return {
    veranstaltungen: state.veranstaltungen,
    veranstaltung: current,
    stammdaten: state.stammdaten[getLanguage()],
    formData: (current.forms && current.forms.veranstaltungsdaten) || {},
  };
};

export default connect(mapStateToProps, null)(VeranstaltungsdatenFormular);
