import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="password-input"
export default class extends Controller {
  static values = {
    password: { type: String, default: '' },
    passwordConfirmation: { type: String, default: '' },
    minLength: { type: Number, default: 8 }
  }

  static targets = [
    "password",
    "passwordConfirmation",
    "length",
    "lowercaseLetter",
    "uppercaseLetter",
    "number",
    "specialCharacter",
    "identicalPassword"
  ]

  connect() {
    this.validatePassword();
  }

  validatePassword() {
    this.passwordValue = this.passwordTarget.value;
    this.passwordConfirmationValue = this.passwordConfirmationTarget.value;

    // Kleine Buchstaben
    let lowerCaseLetters = /[a-z]/;
    if (this.passwordValue.match(lowerCaseLetters)) {
      this.lowercaseLetterTarget.classList.toggle('invalid', false);
    } else {
      this.lowercaseLetterTarget.classList.toggle('invalid', true);
    }

    // Grosse Buchstaben
    let upperCaseLetters = /[A-Z]/;
    if (this.passwordValue.match(upperCaseLetters)) {
      this.uppercaseLetterTarget.classList.toggle('invalid', false);
    } else {
      this.uppercaseLetterTarget.classList.toggle('invalid', true);
    }

    // Zahlen
    let numbers = /[0-9]/;
    if (this.passwordValue.match(numbers)) {
      this.numberTarget.classList.toggle('invalid', false);
    } else {
      this.numberTarget.classList.toggle('invalid', true);
    }

    // Sonderzeichen
    let specialCharacters = /[#?!@$%^&*\-_\/.,]/;
    if (this.passwordValue.match(specialCharacters)) {
      this.specialCharacterTarget.classList.toggle('invalid', false);
    } else {
      this.specialCharacterTarget.classList.toggle('invalid', true);
    }

    // Länge
    if (this.passwordValue.length >= this.minLengthValue) {
      this.lengthTarget.classList.toggle('invalid', false);
    } else {
      this.lengthTarget.classList.toggle('invalid', true);
    }

    // Passwort bestätigen
    if (this.passwordValue === this.passwordConfirmationValue) {
      this.identicalPasswordTarget.classList.toggle('invalid', false);
    } else {
      this.identicalPasswordTarget.classList.toggle('invalid', true);
    }
  }
}
