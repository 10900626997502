import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class frageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    let platzhalterText = '';
    switch (value.language) {
      case 'de':
        platzhalterText = 'Frage Platzhalter:'
        break;
      case 'fr':
        platzhalterText = 'Question remplacement:'
        break;
      case 'it':
        platzhalterText = 'Domanda sostituzione:'
        break;
    }
    node.innerHTML = `<p class="frage-platzhalter">[${platzhalterText} ${value.frage}]</p>`;
    node.innerHTML += `<p id="frage-id" hidden="true">${value.id}</p>`
    return node;
  }
}

frageBlot.blotName = 'custom-frage';
frageBlot.tagName = 'div';
frageBlot.className = 'ql-custom-frage';

export default frageBlot;
