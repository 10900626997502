import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

import { t } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { updateFusstext, deleteFusstext } from '../../../common/modules/api';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';

function Fussdaten(props) {
  const [selected, setSelected] = useState({});
  const handleSubmit = (data) => {
    updateFusstext(props.veranstaltung.id, data);
  };

  const cancelOrBack = selected.id
    ? () => setSelected({})
    : () => (window.location.hash = '#/');

  const handleDelete = selected.id ? () => deleteFusstext(props.veranstaltung.id, selected.id) : null;

  const formFields = [
    {
      key: 0,
      feldTyp: 'hidden',
      name: 'id',
    },
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '1.1',
          feldTyp: 'text',
          name: 'index',
          parentClass: 'flex-1',
        },
        {
          key: '1.2',
          feldTyp: 'text',
          name: 'titel',
          parentClass: 'flex-3',
        },
      ],
    },
    {
      key: 2,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          key: '2.1',
          feldTyp: 'text',
          name: 'inhalt',
          multiline: true,
        },
      ],
    },
  ];

  const istDisabled = (props.veranstaltung.id && !(
    props.veranstaltung.status === 'in_erfassung'
    || props.veranstaltung.status === 'zur_voranzeige'
    || props.veranstaltung.status === 'in_ausschreibung'
  )) || selected.standard_text;

  return (
    <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('index')}</TableCell>
            <TableCell>{t('titel')}</TableCell>
            <TableCell>{t('inhalt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.fussdaten.map(fusstext => (
            <TableRow
              key={fusstext.id}
              hover
              className="link"
              onClick={() => setSelected(fusstext)}
            >
              <TableCell>{fusstext.index}</TableCell>
              <TableCell>{fusstext.titel}</TableCell>
              <TableCell>{fusstext.inhalt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="mt-2">
        <Typography variant="h6">{selected.id ? t('edit') : t('neuer_fusstext')}</Typography>
        <Formular
          disabled={istDisabled}
          formId="fussdaten"
          fields={formFields}
          defaultValues={selected}
          handleSubmit={handleSubmit}
          handleCancel={cancelOrBack}
          handleDelete={handleDelete}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  fussdaten: state.veranstaltungen.current.forms.fussdaten,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Fussdaten);
