import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class imageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    
    node.innerHTML = '';
    for (let i in value.sources) {
      node.innerHTML += `<img class="custom-image" src="${value.sources[i]}" id="${value.ids[i]}_${i}" width="${value.width}%">`;
    }
    return node;
  }

  static value(node) {
    if (node.querySelector('.custom-image')) {
      return {
        id: node.querySelector('.custom-image').id,
        src: node.querySelector('.custom-image').src,
        width: node.querySelector('.custom-image').width
      };
    }
  }
  deleteAt() {
    return false
  }
}

imageBlot.blotName = 'custom-image';
imageBlot.tagName = 'div';
imageBlot.className = 'ql-custom-image';

export default imageBlot;
