import React, { useState, useEffect, useMemo } from 'react';
import {
  Chip,
  Box,
  Button,
  Icon,
  Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { t } from '../../common/i18n/translate';
import { formatDate } from '../../common/modules/helpers';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants';
import { downloadVeranstaltungen } from '../../common/modules/api';
import { veranstaltungenLaden } from '../store/modules/veranstaltungen';
import { setPage } from '../store/modules/filter';
import VeranstaltungFilter from './Filter/index';

function Veranstaltungen({ filter, veranstaltungen, veranstaltungenLaden: handleVeranstaltungenLaden, setPage: handleSetPage }) {
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortModel, setSortModel] = useState({ field: 'datum', order: 'ASC' });

  useEffect(() => {
    setIsLoading(true);
    downloadVeranstaltungen(filter.page, filter.filter || {}, sortModel.field, sortModel.order, (data) => {
      handleVeranstaltungenLaden(data.veranstaltungen);
      setTotal(data.total);
      setIsLoading(false);
    });
  }, [filter, sortModel]);

  return (
    <Paper className="flex-row width-100 no-margin">
      <div className="margin-1 flex-1">
        <Box display="flex" alignItems="flex-end">
          <div style={{ flex: 1 }} />
          <div>
            <Button component={Link} to="/new" variant="contained" size="small">
              <Icon fontSize="small" color="action">add</Icon>
            </Button>
          </div>
        </Box>
        <VeranstaltungFilter />
        <DataGrid
          // density='compact'
          // STYLING
          sx={{ marginTop: 2 }}
          autoHeight
          getRowHeight={() => 'auto'}
          getCellClassName={p => 'autoheight-datagrid-cell'}
          disableColumnMenu
          disableSelectionOnClick
          // DATA CONFIG
          columns={[
            {
              field: 'datum',
              headerName: t('datum'),
              renderCell: p => (
                <Box sx={{ whiteSpace: 'nowrap' }}>
                  <span>{`${formatDate(new Date(p.row.von), 'dd.mm.YY')} -`}</span>
                  <br />
                  <span>{formatDate(new Date(p.row.bis), 'dd.mm.YY')}</span>
                </Box>
              ),
              flex: 2,
            },
            { field: 'ort', headerName: t('ort'), flex: 4 },
            { field: 'typ_code', headerName: t('typ'), flex: 1 },
            { field: 'modus_code', headerName: t('modus'), flex: 2 },
            { field: 'vorgesehene_pruefungen', headerName: t('vorgesehene_pruefungen'), flex: 8 },
            { field: 'veranstalter_name', headerName: t('veranstalter'), flex: 4 },
            { field: 'regionalverband_code', headerName: t('regionalverband_gekuerzt'), flex: 2 },
            {
              field: 'status',
              headerName: t('status'),
              renderCell: p => (
                <Chip label={p.row.status_label} variant="filled" size="small" />
              ),
              flex: 4,
            },
            {
              field: 'anlaesse',
              headerName: t('anlaesse'),
              renderCell: p => (
                <Box>
                  {p.row.anlaesse.map((anlass, i) => (
                    <React.Fragment key={anlass.id}>
                      {i > 0 && <br />}
                      <span>{anlass.bezeichnung}</span>
                    </React.Fragment>
                  ))}
                  {p.row.anlaesse.length === 0 && '-'}
                </Box>
              ),
              flex: 4,
              sortable: false,
            },
          ]}
          rows={veranstaltungen.liste}
          loading={isLoading}
          // SORTING
          sortingMode="server"
          onSortModelChange={(newSortModel) => {
            if (newSortModel.length > 0) {
              // resette auf erste page und loese neuen request mit sortierung aus
              handleSetPage(1);
              setSortModel({ field: newSortModel[0].field, order: newSortModel[0].sort.toLocaleUpperCase() });
            }
          }}
          // PAGINATION
          paginationMode="server"
          pageSize={PAGINATION_ITEMS_PER_PAGE}
          rowsPerPageOptions={[]}
          rowCount={total}
          page={filter.page - 1}
          onPageChange={newPage => handleSetPage(newPage + 1)}
          // OTHER PROPS
          onRowClick={(params) => {
            if (params.row.ist_eigene) {
              window.location.hash = `#/${params.row.id}/edit`;
            }
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  veranstaltungen: state.veranstaltungen,
});

const mapDispatchToProps = dispatch => ({
  veranstaltungenLaden: (veranstaltungen) => {
    dispatch(veranstaltungenLaden(veranstaltungen));
  },
  setPage: (neuePage) => {
    dispatch(setPage(neuePage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Veranstaltungen);
