import Toastify from 'toastify-js'

// Documentation: https://github.com/apvarun/toastify-js/blob/master/README.md
export function toast(type, message) {
  const toastifyColors = {
    "success": "linear-gradient(to right, #00476B, #00283c)",
    "notice": "linear-gradient(to right, #00476B, #00283c)",
    "info": "linear-gradient(to right, #00476B, #00283c)",
    "error": "linear-gradient(to right, #FF752B, #ff4b2b)",
    "warning": "linear-gradient(to right, #FF752B, #fc4a1a)",
    "alert": "linear-gradient(to right, #FF752B, #fc4a1a)",
  }

  const options = {
    text: message,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    style: {
      background: toastifyColors[type]
    }
  }

  Toastify(options).showToast();
}
