;(function($){
  'use strict'

  var _$buttonsTemplate;

  var buttonsTemplate = function() {
    if(!_$buttonsTemplate) {
      var $buttonTemplate = $('<b>').addClass('arrow');
      var $buttonAsc = $buttonTemplate.clone().addClass('desc');
      var $buttonDesc = $buttonTemplate.clone().addClass('asc');

      _$buttonsTemplate = $('<div>')
        .addClass('table-sortable-buttons')
        .append($buttonAsc)
        .append($buttonDesc);
    }
    return _$buttonsTemplate.clone();
  };

  var buildUrl = function(name, dir, settings) {
    var url = [];
    url[0] = settings.baseUrl;
    url[1] = settings.baseUrl.lastIndexOf('?') === -1 ? '?' : '&';
    if(settings.scope) {
      url.push(settings.scope);
      url.push('[');
    }
    url.push('sortcolumn');
    if(settings.scope) {
      url.push(']');
    }
    url.push('=');
    url.push(encodeURIComponent(name));
    url.push('&');
    if(settings.scope) {
      url.push(settings.scope);
      url.push('[');
    }
    url.push('sortdir')
    if(settings.scope) {
      url.push(']');
    }
    url.push('=');
    url.push(encodeURIComponent(dir));

    return encodeURI(url.join(''));
  };

  var methods = {
    init: function(options) {
      return this.each(function(){
        var $this = $(this);
        var data = $this.data('sortable-table');
        var settings = $.extend({}, $.fn.sortableTable_settings, $this.data());

        if(!data) {
          var currentUrl = decodeURI(window.location.href);
          settings['baseUrl'] = currentUrl.replace(settings.clearParamsRegEx, '');

          $this
            .on('click.sortable-table',
                'th[data-name]',
                function(ev) {
                  var sortDirection = $(ev.target).hasClass('desc') ? 'DESC' : 'ASC';
                  var sortColumn = $(this).data('name');
                  if(settings['name'].toUpperCase() == sortColumn.toUpperCase() && settings['dir'] == sortDirection) {
                    ev.preventDefault();
                  }
                  else {
                    window.open(buildUrl(sortColumn.toLowerCase(), sortDirection, settings), '_self');
                  }
                })
            .find('th[data-name]')
            .append(buttonsTemplate())
            .each(function(index, item) {
              var $item = $(item);
              if(settings['name'].toUpperCase() == $item.data('name').toUpperCase()) {
                $item.addClass(settings['dir'].toLowerCase());
                return false;
              }
            });

          $this
            .data('sortable-table', {
              target : $this
            });
        }
      });
    },
    destroy: function() {
      return this.each(function() {
        var $this = $(this);

        $(window).unbind('.sortable-table');
        $this.removeData('sortable-table');
      });
    },
  };

  $.fn.sortableTable_settings = {
    dir: 'DESC',
    clearParamsRegEx: /(?:[\?&])(?:\w+\[)?sort\w+\]?=[\w_,\-\.]+/gi
  };

  $.fn.sortableTable = function(method) {
    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    }
    else if (typeof method === 'object' || !method) {
      return methods.init.apply( this, arguments );
    }
    else {
      $.error('Method ' +  method + ' does not exist on jQuery.sortableTable');
    }
  };
})(jQuery);
