import React from 'react';
import { connect } from 'react-redux';

import VeranstaltungsdatenTabelle from './VeranstaltungsdatenTabelle';
import VeranstaltungsdatenFormular from './VeranstaltungsdatenFormular';

function Veranstaltungsdaten(props) {
  const veranstaltungsAnzeigeLookup = {
    in_erfassung: 'formular',
    gemeldet: 'tabelle',
    zur_annahme: 'tabelle',
    zur_voranzeige: 'formular',
    in_ausschreibung: props.veranstaltung.hat_inserat ? 'formular' : 'tabelle',
    zur_kontrolle_svps: 'tabelle',
    zur_kontrolle_extern: 'tabelle',
    geplant: 'tabelle',
    durchgefuehrt: 'tabelle',
    abgesagt: 'tabelle',
  };
  const veranstaltungsAnzeige = veranstaltungsAnzeigeLookup[props.veranstaltung.status];

  if (veranstaltungsAnzeige === 'tabelle') {
    return <VeranstaltungsdatenTabelle />;
  }
  // auch Fallback falls Status nicht in Lookup
  return <VeranstaltungsdatenFormular />;
}

const mapStateToProps = (state) => {
  const current = state.veranstaltungen.current || {};
  return {
    veranstaltung: current,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Veranstaltungsdaten);
