import { PAGINATION_ITEMS_PER_PAGE } from '../../../common/constants';
import { apiService } from '../../../common/services';
import { formatDate } from '../../../common/modules/helpers';

import type { Filter } from './veranstaltungenFilter';

const VERANSTALTUNGEN_ABFRAGEN = 'equis/selfservice/events/veranstaltungen_abfragen';
const VERANSTALTUNGEN_ABFRAGEN_SUCCESS = 'equis/selfservice/events/veranstaltungen_abfragen_success';
const VERANSTALTUNGEN_ABFRAGEN_FAIL = 'equis/selfservice/events/veranstaltungen_abfragen_fail';

const VERANSTALTUNG_ZEIGEN = 'equis/selfservice/events/veranstaltung_zeigen';
const VERANSTALTUNG_ZEIGEN_SUCCESS = 'equis/selfservice/events/veranstaltung_zeigen_success';
const VERANSTALTUNG_ZEIGEN_FAIL = 'equis/selfservice/events/veranstaltung_zeigen_fail';

const VERANSTALTUNG_GENEHMIGEN = 'equis/selfservice/events/veranstaltung_genehmigen';
const VERANSTALTUNG_GENEHMIGEN_SUCCESS = 'equis/selfservice/events/veranstaltung_genehmigen_success';
const VERANSTALTUNG_GENEHMIGEN_FAIL = 'equis/selfservice/events/veranstaltung_genehmigen_fail';

const VERANSTALTUNG_ZURUECKWEISEN = 'equis/selfservice/events/veranstaltung_zurueckweisen';
const VERANSTALTUNG_ZURUECKWEISEN_SUCCESS = 'equis/selfservice/events/veranstaltung_zurueckweisen_success';
const VERANSTALTUNG_ZURUECKWEISEN_FAIL = 'equis/selfservice/events/veranstaltung_zurueckweisen_fail';

const ALLE_GENEHMIGEN = 'equis/selfservice/events/alle_genehmigen';
const ALLE_GENEHMIGEN_SUCCESS = 'equis/selfservice/events/alle_genehmigen_success';
const ALLE_GENEHMIGEN_FAIL = 'equis/selfservice/events/alle_genehmigen_fail';

const VERANSTALTUNG_PRUEFUNGEN_ANPASSEN = 'equis/selfservice/events/veranstaltung_pruefungen_anpassen';
const VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_SUCCESS = 'equis/selfservice/events/veranstaltung_pruefungen_anpassen_success';
const VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_FAIL = 'equis/selfservice/events/veranstaltung_pruefungen_anpassen_fail';

const getFilterParams = (fuerFilter: Filter) => ({
  nur_eigene: fuerFilter.nur_eigene.toString(),
  sportjahre: fuerFilter.sportjahr.join(','),
  status: fuerFilter.status.join(','),
  sektion_rayon: fuerFilter.sektionRayon,
  disziplinen: fuerFilter.disziplin.join(','),
  regionalverbaende: fuerFilter.regionalverband.join(','),
  rv_anlaesse: fuerFilter.rv_anlaesse.join(','),
  ort: fuerFilter.ortSucheText,
  von: formatDate(fuerFilter.von, 'YYYY-mm-dd'),
  bis: formatDate(fuerFilter.bis, 'YYYY-mm-dd'),
});

const getQueryParams = (fuerFilter: Filter) => Object.entries(getFilterParams(fuerFilter)).map(entry => (`${entry[0]}=${entry[1]}`)).join('&');

export const veranstaltungenReducer = (state, action) => {
  switch (action.type) {
    case VERANSTALTUNGEN_ABFRAGEN:
    case VERANSTALTUNG_ZEIGEN:
    case VERANSTALTUNG_GENEHMIGEN:
    case VERANSTALTUNG_ZURUECKWEISEN:
    case ALLE_GENEHMIGEN:
    case VERANSTALTUNG_PRUEFUNGEN_ANPASSEN:
      return { ...state, isLoading: true };
    case VERANSTALTUNGEN_ABFRAGEN_SUCCESS:
      return {
        ...state,
        liste: action.loadedVeranstaltungen,
        total: action.total,
        isLoading: false,
      };
    case VERANSTALTUNG_ZEIGEN_SUCCESS:
      return {
        ...state,
        current: action.loadedVeranstaltung,
        isLoading: false,
      };
    case VERANSTALTUNG_GENEHMIGEN_SUCCESS:
      return { ...state, isLoading: false };
    case VERANSTALTUNG_ZURUECKWEISEN_SUCCESS:
      return { ...state, isLoading: false };
    case ALLE_GENEHMIGEN_SUCCESS:
      return { ...state, isLoading: false };
    case VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_SUCCESS:
      return { ...state, isLoading: false };
    case VERANSTALTUNGEN_ABFRAGEN_FAIL:
    case VERANSTALTUNG_ZEIGEN_FAIL:
    case VERANSTALTUNG_GENEHMIGEN_FAIL:
    case VERANSTALTUNG_ZURUECKWEISEN_FAIL:
    case ALLE_GENEHMIGEN_FAIL:
    case VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_FAIL:
      return { ...state, isLoading: false };
    default:
      return state || { liste: [], current: {}, isLoading: true };
  }
};

export const veranstaltungenAbfragen = (page: number, mitFilter: Filter, sortField: string, sortOrder: 'ASC' | 'DESC') => async (dispatch) => {
  dispatch({ type: VERANSTALTUNGEN_ABFRAGEN });

  const response = await apiService.get(`backend/events/veranstaltungen?page=${page}&limit=${PAGINATION_ITEMS_PER_PAGE}&sortField=${sortField}&sortOrder=${sortOrder}&${getQueryParams(mitFilter)}`);

  if (response?.data) {
    dispatch({
      type: VERANSTALTUNGEN_ABFRAGEN_SUCCESS,
      loadedVeranstaltungen: response.data.veranstaltungen,
      total: response.data.total,
    });
  } else {
    dispatch({ type: VERANSTALTUNGEN_ABFRAGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const veranstaltungenZeigen = veranstaltungId => async (dispatch) => {
  dispatch({ type: VERANSTALTUNG_ZEIGEN });
  const response = await apiService.get(`backend/events/veranstaltungen/${veranstaltungId}`);

  if (response?.data) {
    dispatch({
      type: VERANSTALTUNG_ZEIGEN_SUCCESS,
      loadedVeranstaltung: response.data,
    });
  } else {
    dispatch({ type: VERANSTALTUNG_ZEIGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const veranstaltungGenehmigen = veranstaltungId => async (dispatch) => {
  dispatch({ type: VERANSTALTUNG_GENEHMIGEN });
  const response = await apiService.post(`backend/events/veranstaltungen/${veranstaltungId}/genehmigen`);

  if (response?.data) {
    dispatch({
      type: VERANSTALTUNG_GENEHMIGEN_SUCCESS,
    });
  } else {
    dispatch({ type: VERANSTALTUNG_GENEHMIGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const veranstaltungZurueckweisen = (veranstaltungId, grund) => async (dispatch) => {
  dispatch({ type: VERANSTALTUNG_ZURUECKWEISEN });
  const response = await apiService.post(`backend/events/veranstaltungen/${veranstaltungId}/zurueckweisen`, { grund });

  if (response?.data) {
    dispatch({
      type: VERANSTALTUNG_ZURUECKWEISEN_SUCCESS,
    });
  } else {
    dispatch({ type: VERANSTALTUNG_ZURUECKWEISEN_FAIL, errorMsg: response.data.messages });
  }
};

export const alleGenehmigen = (mitFilter: Filter) => async (dispatch) => {
  dispatch({ type: ALLE_GENEHMIGEN });
  const response = await apiService.post(`backend/events/veranstaltungen/alle_genehmigen?${getQueryParams(mitFilter)}`);

  if (response?.data) {
    dispatch({
      type: ALLE_GENEHMIGEN_SUCCESS,
    });
  } else {
    dispatch({ type: ALLE_GENEHMIGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const pruefungenAnpassen = (veranstaltungId, angepasstePruefungen, dateVon, dateBis) => async (dispatch) => {
  dispatch({ type: VERANSTALTUNG_PRUEFUNGEN_ANPASSEN });
  const response = await apiService.post(`backend/events/veranstaltungen/${veranstaltungId}/pruefungen_anpassen`, { vorgesehene_pruefungen: angepasstePruefungen, von: dateVon, bis: dateBis });

  if (response?.data) {
    dispatch({
      type: VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_SUCCESS,
    });
  } else {
    dispatch({ type: VERANSTALTUNG_PRUEFUNGEN_ANPASSEN_FAIL, errorMsg: response.data.messages });
  }
};

// wegen axios etwas speziell, einfach ein a-tag geht nicht weil muss headers senden
export const excelExport = mitFilter => apiService({
  url: 'backend/events/veranstaltungen/export_liste.xlsx',
  method: 'GET',
  responseType: 'blob',
  params: getFilterParams(mitFilter),
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', 'VeranstaltungenListe.xlsx');
  document.body.appendChild(link);
  link.click();
});
