import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Button,
  Icon,
  IconButton,
  Tooltip,
  Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import { t } from '../../common/i18n/translate';
import { formatDate } from '../../common/modules/helpers';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants';
import TextEingebenDialog from '../../common/TextEingebenDialog';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import {
  veranstaltungGenehmigen,
  veranstaltungZurueckweisen,
  veranstaltungenAbfragen,
  alleGenehmigen,
  pruefungenAnpassen,
  excelExport,
} from '../redux/modules/veranstaltungen';
import { setPage } from '../redux/modules/veranstaltungenFilter';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import VeranstaltungenFilter from './VeranstaltungenFilter';

function Veranstaltungen() {
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState({ field: 'datum', order: 'ASC' });
  const { veranstaltungenFilter: filter, veranstaltungen } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const [showDialogForVeranstaltung, setShowDialogForVeranstaltung] = useState(null);
  const [editPruefungenForVeranstaltung, setEditPruefungenForVeranstaltung] = useState(null);
  const [showConfirmGenehmigenAll, setShowConfirmGenehmigenAll] = useState(false);
  const [showConfirmAcceptForVeranstaltung, setShowConfirmAcceptForVeranstaltung] = useState(null);
  const handleVeranstaltungenAbfragen = (fuerPage, mitFilter, sortField, sortOrder) => veranstaltungenAbfragen(fuerPage, mitFilter, sortField, sortOrder)(dispatch);
  // TODO: inefficient to handleVeranstaltungenAbfragen again just because status of one/some veranstaltung changed
  const handleVeranstaltungGenehmigen = async (veranstaltungId) => {
    setShowConfirmAcceptForVeranstaltung(null);
    await veranstaltungGenehmigen(veranstaltungId)(dispatch);
    handleVeranstaltungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };
  const handleVeranstaltungZurueckweisen = async (veranstaltungId, grund) => {
    await veranstaltungZurueckweisen(veranstaltungId, grund)(dispatch);
    handleVeranstaltungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };
  const handleAlleGenehmigen = async () => {
    setShowConfirmGenehmigenAll(false);
    await alleGenehmigen(filter.filter)(dispatch);
    handleVeranstaltungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };
  const handlePruefungenAnpassen = async (veranstaltungId, angepasstePruefungen, dateVon, dateBis) => {
    await pruefungenAnpassen(veranstaltungId, angepasstePruefungen, dateVon, dateBis)(dispatch);
    handleVeranstaltungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };
  const handleSetPage = neuePage => dispatch(setPage(neuePage));

  useEffect(() => {
    handleVeranstaltungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  }, [filter]);

  return (
    <Paper className="flex-row width-100 no-margin">
      <div className="margin-1 flex-1">
        <div className="flex-row">
          <div className="flex-1" />
          <div>
            {veranstaltungen.liste.some(veranstaltung => veranstaltung.kann_bearbeiten) && (
              <Button
                onClick={() => setShowConfirmGenehmigenAll(true)}
                variant="contained"
                size="small"
              >
                {t('alle_genehmigen')}
              </Button>
            )}
            {veranstaltungen.liste.length > 0 && (
              <Button
                sx={{ marginLeft: veranstaltungen.liste.some(veranstaltung => veranstaltung.kann_bearbeiten) ? '1em' : '' }}
                onClick={() => excelExport(filter.filter)}
                variant="contained"
                size="small"
              >
                {t('export_xlsx')}
              </Button>
            )}
          </div>
        </div>
        <VeranstaltungenFilter />
        <DataGrid
          // density='compact'
          // STYLING
          sx={{ marginTop: 2 }}
          autoHeight
          getRowHeight={() => 'auto'}
          getCellClassName={p => 'autoheight-datagrid-cell'}
          disableColumnMenu
          disableSelectionOnClick
          // DATA CONFIG
          columns={[
            {
              field: 'datum',
              headerName: t('datum'),
              renderCell: p => (
                <Box sx={{ whiteSpace: 'nowrap' }}>
                  <span>{`${formatDate(new Date(p.row.von), 'dd.mm.YY')} -`}</span>
                  <br />
                  <span>{formatDate(new Date(p.row.bis), 'dd.mm.YY')}</span>
                </Box>
              ),
              flex: 2,
            },
            { field: 'ort', headerName: t('ort'), flex: 4 },
            { field: 'typ_code', headerName: t('typ'), flex: 1 },
            { field: 'modus_code', headerName: t('modus'), flex: 2 },
            { field: 'vorgesehene_pruefungen', headerName: t('vorgesehene_pruefungen'), flex: 8 },
            { field: 'veranstalter_name', headerName: t('veranstalter'), flex: 4 },
            { field: 'regionalverband_code', headerName: t('regionalverband_gekuerzt'), flex: 2 },
            {
              field: 'status',
              headerName: t('status'),
              renderCell: p => (
                <Chip label={p.row.status_label} variant="filled" size="small" />
              ),
              flex: 4,
            },
            {
              field: 'anlaesse',
              headerName: t('anlaesse'),
              renderCell: p => (
                <Box>
                  {p.row.anlaesse.map((anlass, i) => (
                    <React.Fragment key={anlass.id}>
                      {i > 0 && <br />}
                      <span>{anlass.bezeichnung}</span>
                    </React.Fragment>
                  ))}
                  {p.row.anlaesse.length === 0 && '-'}
                </Box>
              ),
              flex: 4,
              sortable: false,
            },
            {
              field: 'aktionen',
              headerName: t('aktionen'),
              renderCell: p => (
                <div className="flex-row">
                  <Tooltip title={t('show')}>
                    <IconButton size="small">
                      <Icon fontSize="small">visibility</Icon>
                    </IconButton>
                  </Tooltip>
                  {p.row.kann_bearbeiten && (
                    <>
                      <Tooltip title={t('accept')}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setShowConfirmAcceptForVeranstaltung(p.row.id);
                            // verhindere dass auch nav zu detail onclick triggert auf TableRow
                            e.stopPropagation();
                          }}
                        >
                          <Icon fontSize="small">done</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('edit')}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setEditPruefungenForVeranstaltung(p.row.id);
                            // verhindere dass auch nav zu detail onclick triggert auf TableRow
                            e.stopPropagation();
                          }}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('zurueckweisen')}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setShowDialogForVeranstaltung(p.row.id);
                            // verhindere dass auch nav zu detail onclick triggert auf TableRow
                            e.stopPropagation();
                          }}
                        >
                          <Icon fontSize="small">skip_previous</Icon>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              ),
              flex: 4,
              sortable: false,
            },
          ]}
          rows={veranstaltungen.liste}
          loading={veranstaltungen.isLoading}
          // SORTING
          sortingMode="server"
          onSortModelChange={(newSortModel) => {
            if (newSortModel.length > 0) {
              // resette auf erste page und loese neuen request mit sortierung aus
              handleSetPage(1);
              setSortModel({ field: newSortModel[0].field, order: newSortModel[0].sort.toLocaleUpperCase() });
            }
          }}
          // PAGINATION
          paginationMode="server"
          pageSize={PAGINATION_ITEMS_PER_PAGE}
          rowsPerPageOptions={[]}
          rowCount={veranstaltungen.total ?? 0}
          page={filter.page - 1}
          onPageChange={newPage => handleSetPage(newPage + 1)}
          // OTHER PROPS
          onRowClick={params => navigate(`/veranstaltungen/${params.row.id}`)}
        />
      </div>
      <TextEingebenDialog
        key="zurueckweisen-grund-dialog"
        value=""
        showDialog={showDialogForVeranstaltung !== null}
        onDialogClose={(grund) => {
          if (grund !== null) {
            handleVeranstaltungZurueckweisen(showDialogForVeranstaltung, grund);
          }
          setShowDialogForVeranstaltung(null);
        }}
        title={t('begruendung')}
        inputLabel={t('begruendung')}
        description={t('gemeldete_veranstaltung_zurueckweisen')}
        config={{ inputAttributes: { rows: 5, multiline: true } }}
      />
      <TextEingebenDialog
        showVonBis ={true}
        key="edit-pruefungen-dialog"
        value={veranstaltungen.liste.find(v => v.id === editPruefungenForVeranstaltung)?.vorgesehene_pruefungen ?? ''}
        valueDateVon={veranstaltungen.liste.find(v => v.id === editPruefungenForVeranstaltung)?.von ?? ''}
        valueDateBis={veranstaltungen.liste.find(v => v.id === editPruefungenForVeranstaltung)?.bis ?? ''}
        showDialog={editPruefungenForVeranstaltung !== null}
        onDialogClose={(angepasstePruefungen, dateVon, dateBis) => {
          if (angepasstePruefungen !== null && dateVon <= dateBis) {
            handlePruefungenAnpassen(editPruefungenForVeranstaltung, angepasstePruefungen, dateVon, dateBis);
          }
          setEditPruefungenForVeranstaltung(null);
        }}
        title={t('edit')}
        inputLabel={t('vorgesehene_pruefungen')}
        config={{ inputAttributes: { rows: 5, multiline: true } }}
      />
      <ConfirmationDialog
        key="confirm-all-dialog"
        open={showConfirmGenehmigenAll}
        options={{ title: t('alle_genehmigen'), description: t('alle_genehmigen_text') }}
        onClose={() => setShowConfirmGenehmigenAll(false)}
        onCancel={() => setShowConfirmGenehmigenAll(false)}
        onConfirm={handleAlleGenehmigen}
      />
      <ConfirmationDialog
        key="confirm-accept-dialog"
        open={!!showConfirmAcceptForVeranstaltung}
        options={{ title: t('genehmigen'), description: t('genehmigen_text') }}
        onClose={() => setShowConfirmAcceptForVeranstaltung(null)}
        onCancel={() => setShowConfirmAcceptForVeranstaltung(null)}
        onConfirm={() => handleVeranstaltungGenehmigen(showConfirmAcceptForVeranstaltung)}
      />
    </Paper>
  );
}

export default Veranstaltungen;
