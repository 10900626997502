import React from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';

import './index.scss';
import { t, getLanguage } from '../../../common/i18n/translate';
import { formatDate } from '../../../common/modules/helpers';
import ProzessButtons from './ProzessButtons';
import ProzessFortschritt from './ProzessFortschritt';

function VeranstaltungSidebar({ veranstaltung, stammdaten }) {
  return (
    <div className="veranstaltung-sidebar flex-column">
      {
        (
          veranstaltung && veranstaltung.status && (
            veranstaltung.status === 'in_erfassung'
            || veranstaltung.status === 'zur_voranzeige'
            || veranstaltung.status === 'in_ausschreibung'
          )
        ) && (
        <div className="sidebar-aktionen">
          <Typography><strong>{t('aktionen')}</strong></Typography>
          <Typography>
            {
              (veranstaltung.status === 'in_ausschreibung' && !veranstaltung.hat_inserat)
                ? stammdaten.veranstaltung_status.find(s => s.code === 'zur_voranzeige')?.description
                : stammdaten.veranstaltung_status.find(s => s.code === veranstaltung.status)?.description
            }
          </Typography>
          <ProzessButtons veranstaltung={veranstaltung} />
        </div>
        )
      }
      {veranstaltung?.zurueckweisungsgruende?.length > 0 && (
        <div className="sidebar-notizen">
          <Typography>
            <strong>{`${t('zurueckweisungsgruende')}:`}</strong>
          </Typography>
          <br />
          {
            veranstaltung.zurueckweisungsgruende.map(({ grund, erstellt_am, initiator }) => (
              <Typography key={erstellt_am}>
                <strong>{initiator}</strong>
                {' - '}
                {formatDate(new Date(erstellt_am), 'dd.mm.YY')}
                <br />
                {grund}
              </Typography>
            ))
          }
        </div>
      )}
      {veranstaltung && <ProzessFortschritt />}
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

export default connect(mapStateToProps, null)(VeranstaltungSidebar);
