import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  static targets = [ "element" ]

  connect() {
    this.target = this.hasElementTarget ? this.elementTarget : this.element;
    this.previousCSSClasses = this.target.classList.value
  }

  replaceWithSpinner() {
    this.target.innerHTML = '<i class="fa-solid fa-spinner fa-spin"></i>'
  }

  addSpinnerClass() {
    this.target.classList = 'fa-solid fa-spinner fa-spin'
  }

  removeSpinnerClass() {
    this.target.classList = this.previousCSSClasses
  }
}
