import React from 'react';

function Todo() {
  return (
    <div>
      <h1>TODO</h1>
      <p>Noch nicht umgesetzt</p>
    </div>
  );
}

export default Todo;
