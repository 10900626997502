import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-links"
export default class extends Controller {
  static targets = ["tab"]
  static values = {
    autoOpenTab: { type: Boolean, default: false }
  }

  connect() {
    if (this.autoOpenTabValue) {
      this.openTabFromURL()
    }
    this.interceptTabClick()
  }

  // Wenn ein Tab geklickt wird, wird der Name des Tabs in der URL gespeichert
  // So kann wenn die Seite neu geladen wird, der Tab wieder geöffnet werden
  interceptTabClick() {
    this.tabTargets.forEach(tab => {
      tab.addEventListener('click', event => {
        const url = this.currentURL
        url.searchParams.set('tab', tab.dataset.bsTarget)
        window.history.replaceState(history.state, "", url)
      })
    })
  }

  // Wenn ein Tab Namen in der URL vorhanden ist, wird dieser Tab geöffnet
  openTabFromURL() {
    const desiredTabName = this.currentURL.searchParams.get('tab')
    if (desiredTabName) {
      const desiredTab = this.tabTargets.find(tab => tab.dataset.bsTarget === desiredTabName)
      bootstrap.Tab.getOrCreateInstance(desiredTab).show()
    }
  }

  get currentURL() {
    return new URL(window.location);
  }
}
