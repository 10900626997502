import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, Input } from '@mui/material';

import { t_var } from '../../common/i18n/translate';
import { useAppSelector } from '../redux/hooks';

function NummerFrageSimple({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);

  return (
    <ListItem divider className="nummerFrageSimple">
      <ListItemText primary={t_var(frage, 'titel')} secondary={t_var(frage, 'untertitel')} />
      <ListItemSecondaryAction>
        <Input
          type="number"
          value={antwortDetails?.details || frage?.config?.default || 0}
          disabled={rapport.status !== 'offen'}
          onChange={event => updateAntwort(null, Number(event.target.value))}
          fullWidth
          inputProps={{
            min: frage?.config?.min,
            max: frage?.config?.max,
            style: { textAlign: 'center', maxWidth: '50px' },
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default NummerFrageSimple;
