import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
} from '@mui/material';

import { t, getLanguage } from '../../../common/i18n/translate';
import { MultiSelectField } from '../../../common/Formular';
import { objIsEmpty } from '../../../common/modules/helpers';
import { setFilter } from '../../store/modules/filter';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';

function Filter({ filter, stammdaten, setFilter: handleSetFilter }) {
  const stammdatenReady = useMemo(() => !objIsEmpty(stammdaten), [stammdaten]);

  if (!stammdatenReady) {
    return null;
  }

  // zeige auch Anlaesse der Ueber-Organisationen der ausgewaehlten RV
  const getMitUeberRV = rvIds => rvIds.concat(rvIds
      .map(rvID => stammdaten.regionalverbaende.find(rv => rv.id === rvID)?.ueber_organisation_id)
      .filter(Number)
      .map(orgId => stammdaten.regionalverbaende.find(rv => rv.organisation_id === orgId)?.id)
      .filter(Number));

  return (
    <Paper sx={{ mt: 2 }} className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.FIRST + 1}>
      <Typography sx={{ mb: 1 }} fontWeight="bold">{t('filter')}</Typography>
      <div id="filter-form" className="flex-row">
        <FormControlLabel
          // damit gleich aussieht wie die anderen OutlinedInput select felder
          sx={{ whiteSpace: 'nowrap', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', color: 'rgba(0, 0, 0, 0.6)', marginLeft: 0, marginRight: '16px', paddingLeft: '1em' }}
          labelPlacement="start"
          label={t('nur_eigene')}
          control={(
            <Checkbox
              size="small"
              checked={filter.filter.nur_eigene}
              onChange={(e, newValue) => handleSetFilter({
                ...filter.filter,
                nur_eigene: newValue,
                regionalverband: newValue ? [] : filter.filter.regionalverband,
                rv_anlaesse: filter.filter.rv_anlaesse.filter(rvId => (newValue ? stammdaten.current_user_person.regionalverbaende_zur_auswahl : filter.filter.regionalverband).includes((stammdaten.rv_anlaesse || []).find(rva => rva.id === rvId)?.regionalverband_id)),
                status: filter.filter.status.filter(s => s !== 'in_erfassung'),
              })}
            />
          )}
        />
        <MultiSelectField
          sx={{ mr: 2, flex: 2 }}
          value={filter.filter.sportjahr}
          options={filter.filterWerte.sportjahr}
          optionLabel="title"
          optionValue="value"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, sportjahr: newValue })}
          label={t('sportjahr')}
          labelVariant="outlined"
          variant="outlined"
        />
        <MultiSelectField
          sx={{ mr: 2, flex: 2 }}
          value={filter.filter.disziplin}
          options={(stammdaten.disziplinen || []).map(d => ({ ...d, title: d.code })).sort((a, b) => (a.title < b.title ? -1 : a.title > b.title))}
          optionLabel="label"
          optionValue="code"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, disziplin: newValue })}
          label={t('disziplin')}
          labelVariant="outlined"
          variant="outlined"
        />
        {!filter.filter.nur_eigene && (
          <MultiSelectField
            sx={{ mr: 2, flex: 2 }}
            value={filter.filter.regionalverband}
            options={(stammdaten.regionalverbaende || []).map(r => (
              {
                ...r,
                title: r.code,
              }
            )).sort((a, b) => (a.title < b.title ? -1 : a.title > b.title))}
            optionLabel="label"
            optionValue="id"
            handleChange={(_, newValue) => handleSetFilter({
              ...filter.filter,
              regionalverband: newValue,
              rv_anlaesse: filter.filter.rv_anlaesse.filter(rvId => getMitUeberRV(newValue).includes((stammdaten.rv_anlaesse || []).find(rva => rva.id === rvId)?.regionalverband_id)),
            })}
            label={t('regionalverband')}
            labelVariant="outlined"
            variant="outlined"
          />
        )}
        {(filter.filter.regionalverband.length > 0 || filter.filter.nur_eigene) && (
          <MultiSelectField
            sx={{ mr: 2, flex: 2 }}
            value={filter.filter.rv_anlaesse}
            options={(stammdaten.rv_anlaesse || []).filter(rva => (filter.filter.nur_eigene
              ? stammdaten.current_user_person.regionalverbaende_zur_auswahl
              : getMitUeberRV(filter.filter.regionalverband)).includes(rva.regionalverband_id)
            )}
            optionLabel="label"
            optionValue="id"
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, rv_anlaesse: newValue })}
            label={t('anlass')}
            labelVariant="outlined"
            variant="outlined"
          />
        )}
        <MultiSelectField
          sx={{ mr: 2, flex: 2 }}
          value={filter.filter.status}
          // wenn nur_eigene aktiv ist, darf alle status benutzen, sonst nur die, die nicht in_erfassung sind
          options={(stammdaten.veranstaltung_status || []).filter(vs => filter.filter.nur_eigene || vs.code !== 'in_erfassung')}
          optionLabel="label"
          optionValue="code"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, status: newValue })}
          label={t('status')}
          labelVariant="outlined"
          variant="outlined"
        />
        <TextField
          sx={{ mr: 2, flex: 2 }}
          // className="mt-1"
          value={filter.filter.sektion_rayon}
          onChange={e => handleSetFilter({ ...filter.filter, sektion_rayon: e.target.value })}
          label={t('sektion_rayon')}
          // fullWidth
          size="small"
          variant="outlined"
          InputLabelProps={{
            variant: 'outlined',
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  setFilter: (filter) => {
    dispatch(setFilter(filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
