import React, { useState, useMemo, useEffect } from 'react';
import {
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Icon,
  Typography,
  TextField,
} from '@mui/material';

import { t, getLanguage } from '../../common/i18n/translate';
import { AutocompleteField, MultiSelectField } from '../../common/Formular';
import { KontaktOkPraesident, KontaktOasVerantwortlicher } from '../../common/PersonKontakt';
import { formatDate, getDatesInRange } from '../../common/modules/helpers';
import { useAppSelector } from '../redux/hooks';

import '../../common/styles/VeranstaltungsdatenTabelle.scss';

function AnlassEditDialog({
  anlassDurchfuehrung,
  onDialogClose,
  title,
}) {
  const { bearbeitbareVeranstaltungen, liste } = useAppSelector(state => state.anlaesse);
  const stammdaten = useAppSelector(state => state.stammdaten[getLanguage()]);
  const [anlassDurchfuehrungLocal, setAnlassDurchfuehrungLocal] = useState(null);
  const chosenVeranstaltung = useMemo(() => bearbeitbareVeranstaltungen.find(bv => bv.id === anlassDurchfuehrungLocal?.veranstaltung_id), [bearbeitbareVeranstaltungen, anlassDurchfuehrungLocal]);

  useEffect(() => {
    setAnlassDurchfuehrungLocal(anlassDurchfuehrung);
  }, [anlassDurchfuehrung]);

  if (Object.keys(stammdaten ?? {}).length === 0) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!anlassDurchfuehrung}
      TransitionProps={{
        onClose: () => onDialogClose(null),
      }}
    >
      <DialogTitle sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', fontWeight: 600 }}>
        {title}
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon style={{ color: 'white' }}>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        {!!anlassDurchfuehrungLocal && (
          <AutocompleteField
            value={anlassDurchfuehrungLocal?.veranstaltung_id}
            label={t('veranstaltung')}
            disabled={anlassDurchfuehrung?.id != null}
            handleChange={(_, newValue) => setAnlassDurchfuehrungLocal({ ...anlassDurchfuehrungLocal, veranstaltung_id: newValue, tage: [] })}
            // need label - similar as Ausschreibung/Veranstaltung -Kopieren
            // TODO: extract to common code
            options={bearbeitbareVeranstaltungen.map(v => ({
              ...v,
              label: [
                `${formatDate(new Date(v.von), 'dd.mm.YYYY')} - ${formatDate(new Date(v.bis), 'dd.mm.YYYY')}`,
                `${v.typ} ${v.ort}`,
              ].join('     '),
            }))}
            optionValue="id"
            renderOption={(o_props, option) => (
              <Grid {...o_props} key={option.id} container justify="space-between" alignItems="flex-start">
                <Typography style={{ width: '24%' }}>
                  {formatDate(new Date(option.von))}
                  {' '}
                  -
                  {' '}
                  {formatDate(new Date(option.bis))}
                </Typography>
                <Typography style={{ width: '6%' }}>{option.modus}</Typography>
                <Typography style={{ width: '30%' }}>
                  {option.typ}
                  {' '}
                  {option.ort}
                  {' '}
                  {option.kanton}
                </Typography>
                <Typography style={{ width: '25%' }}>{option.status_label}</Typography>
                <Typography style={{ width: '15%', textAlign: 'right' }}>{option.sequenz_nummer}</Typography>
              </Grid>
            )}
          />
        )}
        {!!chosenVeranstaltung && (
          <table className="veranstaltungsdaten-tabelle mt-2">
            <tbody>
              <tr>
                <th><Typography>{t('veranstalter')}</Typography></th>
                <td><Typography>{chosenVeranstaltung.veranstalter_name}</Typography></td>
              </tr>
              <tr>
                <th><Typography>{t('vorgesehene_pruefungen')}</Typography></th>
                <td><Typography>{chosenVeranstaltung.vorgesehene_pruefungen}</Typography></td>
              </tr>
              <tr>
                <th><Typography>{t('ok_praesident')}</Typography></th>
                <td><KontaktOkPraesident beteiligungen={chosenVeranstaltung.forms.beteiligungen} /></td>
              </tr>
              <tr>
                <th><Typography>{t('oas_verantwortlicher')}</Typography></th>
                <td><KontaktOasVerantwortlicher beteiligungen={chosenVeranstaltung.forms.beteiligungen} /></td>
              </tr>
            </tbody>
          </table>
        )}
        {!!anlassDurchfuehrungLocal && !!chosenVeranstaltung && (
          <>
            <div className="flex-row mt-1">
              <AutocompleteField
                sx={{ marginRight: '1em' }}
                className="flex-1"
                value={anlassDurchfuehrungLocal?.anlass_id}
                label={t('anlass')}
                // disabled={anlassDurchfuehrung?.id != null}
                handleChange={(_, newValue) => setAnlassDurchfuehrungLocal({ ...anlassDurchfuehrungLocal, anlass_id: newValue })}
                options={stammdaten.rv_anlaesse.filter(rva => stammdaten.current_user_person.regionalverbaende_zur_auswahl.includes(rva.regionalverband_id)
                  // nur welche, zu denen nicht bereits eine Durchfuehrung erfasst wurde
                  && (anlassDurchfuehrung?.id == null ? !liste.some(ad => ad.anlass_id === rva.id && ad.veranstaltung_id === chosenVeranstaltung.id) : true))}
                optionValue="id"
              />
              <TextField
                className="flex-1"
                value={anlassDurchfuehrungLocal?.bemerkung ?? ''}
                onChange={e => setAnlassDurchfuehrungLocal({ ...anlassDurchfuehrungLocal, bemerkung: e.target.value })}
                label={t('bemerkung_rayon')}
              />
            </div>
            <div className="mt-2">
              <MultiSelectField
                value={anlassDurchfuehrungLocal.tage ?? []}
                options={getDatesInRange(new Date(chosenVeranstaltung.von), new Date(chosenVeranstaltung.bis)).map(optionDate => ({
                  id: formatDate(optionDate, 'YYYY-mm-dd'),
                  label: formatDate(optionDate, 'weekday dd.mm.YYYY'),
                }))}
                optionValue="id"
                optionLabel="label"
                handleChange={(_, newValue) => setAnlassDurchfuehrungLocal({ ...anlassDurchfuehrungLocal, tage: newValue })}
                label={t('durchfuehrungstage')}
                labelVariant="outlined"
                variant="outlined"
                // disabled={!istErsterfassung}
                useDefaultRenderValue
              />
            </div>
            {anlassDurchfuehrung?.id != null && (
              <div className="flex-column flex-1 mt-1">
                <div className="flex-1" style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ paddingBottom: '0.25em' }}>{t('status')}</Typography>
                </div>
                <div className="flex-row flex-1">
                  {stammdaten.anlass_durchfuehrung_status.map(ads => (
                    <Chip key={ads.code} sx={{ marginRight: '1em' }} size="medium" color={ads.code === anlassDurchfuehrungLocal.status ? 'secondary' : 'default'} variant="filled" label={ads.label} />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(anlassDurchfuehrungLocal)}>
          {anlassDurchfuehrung?.id != null ? t('speichern') : t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AnlassEditDialog;
