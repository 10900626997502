// Diese Bridge-Klasse dient als Schnittstelle zwischen der Turbo Native App und der Web-App.
// Dokumentation:
// https://github.com/hotwired/turbo-android/blob/main/docs/ADVANCED-OPTIONS.md#native---javascript-integration
// https://masilotti.com/turbo-ios/the-javascript-bridge

import { pushNotificationTokenSpeichern } from "./native_actions/push_notification_tokens.js";

export default class Bridge {
  // Nachricht von der Turbo Native App empfangen
  static postNativeToJS(action, jsonString) {
    switch (action) {
      case "didPressMenu":
        const event = new CustomEvent("toggle-sidedrawer");
        window.dispatchEvent(event);
        break;
      case "currentFCMToken":
        pushNotificationTokenSpeichern(jsonString);
        break;
      case "didEnterForeground":
        // App wurde geöffnet
        break;
      case "didEnterBackground":
        // App wurde geschlossen
        break;
      case "didTerminate":
        // App wurde beendet
        break;
      default:
        console.log("#postNativeToJS unknown action " + action);
    }
  }

  // Nachricht an die Turbo Native App senden
  static postJSToNative(action, payload = {}) {
    // Android
    window.nativeApp?.postMessage(action, JSON.stringify(payload));

    // iOS
    window.webkit?.messageHandlers?.nativeApp?.postMessage({ action: action, payload: payload });
  }
}
