import { PAGINATION_ITEMS_PER_PAGE } from '../../../common/constants';
import { apiService } from '../../../common/services';

const VERANSTALTER_ABFRAGEN = 'equis/selfservice/events/veranstalter_abfragen';
const VERANSTALTER_ABFRAGEN_SUCCESS = 'equis/selfservice/events/veranstalter_abfragen_success';
const VERANSTALTER_ABFRAGEN_FAIL = 'equis/selfservice/events/veranstalter_abfragen_fail';

const VERANSTALTER_SPEICHERN = 'equis/selfservice/events/veranstalter_speichern';
const VERANSTALTER_SPEICHERN_SUCCESS = 'equis/selfservice/events/veranstalter_speichern_success';
const VERANSTALTER_SPEICHERN_FAIL = 'equis/selfservice/events/veranstalter_speichern_fail';

const getFilterParams = fuerFilter => ({
  sektion_rayon: fuerFilter.sektionRayon,
  rv_mitglied: fuerFilter.rv_mitglied,
  aktiv: fuerFilter.aktiv,
  sucheText: fuerFilter.sucheText,
});

const getQueryParams = fuerFilter => Object.entries(getFilterParams(fuerFilter)).map(entry => (`${entry[0]}=${entry[1]}`)).join('&');

export const veranstalterReducer = (state, action) => {
  switch (action.type) {
    case VERANSTALTER_ABFRAGEN:
    case VERANSTALTER_SPEICHERN:
      return { ...state, isLoading: true };
    case VERANSTALTER_ABFRAGEN_SUCCESS:
      return {
        ...state,
        liste: action.loadedVeranstalter,
        total: action.total,
        isLoading: false,
      };
    case VERANSTALTER_SPEICHERN_SUCCESS:
      return { ...state, isLoading: false };
    case VERANSTALTER_ABFRAGEN_FAIL:
    case VERANSTALTER_SPEICHERN_FAIL:
      return { ...state, isLoading: false };
    default:
      return state || { liste: [], current: {}, total: 0, isLoading: true };
  }
};

export const veranstalterAbfragen = (page, mitFilter, sortField, sortOrder) => async (dispatch) => {
  dispatch({ type: VERANSTALTER_ABFRAGEN });

  const response = await apiService.get(`backend/events/veranstalter?page=${page}&limit=${PAGINATION_ITEMS_PER_PAGE}&sortField=${sortField}&sortOrder=${sortOrder}&${getQueryParams(mitFilter)}`);

  if (response?.data) {
    dispatch({
      type: VERANSTALTER_ABFRAGEN_SUCCESS,
      loadedVeranstalter: response.data.veranstalter,
      total: response.data.total,
    });
  } else {
    dispatch({ type: VERANSTALTER_ABFRAGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const veranstalterSpeichern = veranstalter => async (dispatch) => {
  dispatch({ type: VERANSTALTER_SPEICHERN });
  let response;

  const payload = {
    veranstalter: {
      id: veranstalter?.id,
      name: veranstalter?.name,
      regionalverband_id: veranstalter.regionalverband_id,
      rayon: veranstalter.rayon,
      partner_id: veranstalter.partner_id,
      regionalverband_mitglied: veranstalter.regionalverband_mitglied,
      aktiv: veranstalter.aktiv,
    },
  };

  if (veranstalter?.id) {
    response = await apiService.put(`backend/events/veranstalter/${veranstalter.id}`, payload);
  } else {
    response = await apiService.post('backend/events/veranstalter', payload);
  }

  if (response?.data) {
    dispatch({
      type: VERANSTALTER_SPEICHERN_SUCCESS,
    });
  } else {
    dispatch({ type: VERANSTALTER_SPEICHERN_FAIL, errorMsg: response.data.messages });
  }
};

// wegen axios etwas speziell, einfach ein a-tag geht nicht weil muss headers senden
export const excelExport = mitFilter => apiService({
  url: 'backend/events/veranstalter/export_liste.xlsx',
  method: 'GET',
  responseType: 'blob',
  params: getFilterParams(mitFilter),
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', 'veranstalter_liste.xlsx');
  document.body.appendChild(link);
  link.click();
});
