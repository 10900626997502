import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { withPageTracking } from '../common/tracking';
import RootScreen from './RootScreen';
import Veranstaltungen from './Veranstaltungen';
import Anlaesse from './Anlaesse';
import Veranstalter from './Veranstalter';
import Benutzer from './Benutzer';
import VeranstaltungsdatenTabelle from './VeranstaltungsdatenTabelle';

export const routes = () => [
  {
    key: 'events',
    url: '/',
    // exact: true,
    element: RootScreen,
    children: [
      {
        key: 'veranstaltung',
        url: 'veranstaltungen/:veranstaltungId',
        element: VeranstaltungsdatenTabelle,
      },
      {
        key: 'veranstaltungen',
        url: 'veranstaltungen',
        element: Veranstaltungen,
      },
      {
        key: 'rv_anlaesse',
        url: 'rv_anlaesse',
        element: Anlaesse,
      },
      {
        key: 'veranstalter',
        url: 'veranstalter',
        element: Veranstalter,
      },
      {
        key: 'benutzer',
        url: 'benutzer',
        element: Benutzer,
      },
      {
        // da default route ist (= index) braucht es keine url
        key: 'veranstaltungen_index',
        url: null,
        element: Veranstaltungen,
        index: true,
      },
      {
        key: 'veranstaltungen_default',
        url: '*',
        element: Veranstaltungen,
      },
    ],
  },
];

function Router() {
  const routerBasename = '/events';
  return (
    <BrowserRouter basename={routerBasename}>
      <Routes>
        {routes().map(route => (
          <Route key={route.key} path={route.url} element={withPageTracking(route.element, routerBasename)} exact={route.exact} index={route.index}>
            {route?.children?.map(childRoute => <Route key={childRoute.key} path={childRoute.url} element={withPageTracking(childRoute.element, routerBasename)} exact={childRoute.exact} index={childRoute.index} />)}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
