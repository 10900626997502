import React from 'react';
import { connect } from 'react-redux';

import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { apiURL, updateKontodaten, updateVeranstaltung } from '../../../common/modules/api';

function KontodatenUndInfos({ formDataKonto, formDataInfos, veranstaltung, stammdaten }) {
  const kontoarten = [
    { id: 'bank', name: t('bank') },
    { id: 'post', name: t('post') },
  ];

  const formFieldsKonto = [
    { feldTyp: 'hidden', name: 'id' },
    {
      key: 1,
      feldTyp: 'fieldset',
      label: t('kontodaten'),
      children: [
        {
          key: 2,
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            {
              feldTyp: 'select',
              name: 'kontoart',
              options: kontoarten,
              optionValue: 'id',
              optionLabel: 'name',
              required: true,
            },
            { feldTyp: 'text', name: 'iban', required: true },
          ],
        },
        {
          key: 3,
          feldTyp: 'wrapper',
          className: 'flex-row',
          displayCondition: data => data.kontoart === 'bank',
          children: [
            { feldTyp: 'text', name: 'bank_name', label: t('name'), className: 'flex-2' },
            { feldTyp: 'text', name: 'bank_plz', label: t('plz') },
            { feldTyp: 'text', name: 'bank_ort', label: t('ort') },
          ],
        },
      ],
    },
    {
      key: 4,
      feldTyp: 'whitespace',
    },
    {
      key: 5,
      feldTyp: 'fieldset',
      label: t('kontoinhaber'),
      children: [
        {
          key: 6,
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            { feldTyp: 'text', name: 'name', label: t('name_firma'), required: true, maxLength: 70 },
            { feldTyp: 'text', name: 'zusatz' },
            { feldTyp: 'text', name: 'strasse' },
          ],
        },
        {
          key: 7,
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            { feldTyp: 'text', name: 'plz', required: true },
            { feldTyp: 'text', name: 'ort', required: true },
            {
              feldTyp: 'select',
              name: 'land_id',
              label: t('land'),
              options: stammdaten.laender_schweiz_lichtenstein,
              optionValue: 'id',
              optionLabel: 'label',
              required: true,
            },
          ],
        },
      ],
    },
  ];

  const formFieldsInfos = [
    {
      key: 9,
      feldTyp: 'fieldset',
      label: t('weitere_informationen'),
      children: [
        {
          key: 10,
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            {
              key: 11,
              feldTyp: 'autocomplete',
              name: 'software_id',
              label: t('software'),
              // required: true,
              options: [{ id: undefined, bezeichnung: t('keine') }, ...stammdaten.software],
              optionValue: 'id',
              optionLabel: 'bezeichnung',
            },
            {
              key: 12,
              // Wenn keine Software gewaehlt ist braucht auch nicht zu akzeptieren
              requiredCondition: values => values.software_id != null,
              feldTyp: 'checkbox',
              name: 'accept_sw_terms',
              label: (<a href={`${apiURL }/vereinbarung_veranstalter_${getLanguage()}_2020.pdf`} target="_blank" rel="noreferrer">{t('accept_sw_terms')}</a>),
            },
          ],
        },
        {
          key: 13,
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            {
              key: 14,
              feldTyp: 'text',
              name: 'anzahl_bestellte_startkarten',
              required: true,
            },
            {
              key: 15,
              requiredCondition: values => values.anzahl_bestellte_startkarten > 0,
              feldTyp: 'autocomplete_person',
              name: 'startkarten_versandadresse_id',
              label: t('startkarten_versandadresse'),
              optionValue: 'id',
              optionLabel: 'name',
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Formular
        formId="kontodaten"
        disabled={veranstaltung.id && !(
          veranstaltung.status === 'in_erfassung'
        || veranstaltung.status === 'zur_voranzeige'
        || veranstaltung.status === 'in_ausschreibung'
        )}
        fields={formFieldsKonto}
        defaultValues={formDataKonto}
        handleSubmit={newFormData => updateKontodaten(veranstaltung.id, newFormData)}
        wrapInPaper
      />
      <br />
      <Formular
        formId="informationen"
        disabled={veranstaltung.id && !(
          veranstaltung.status === 'in_erfassung'
        || veranstaltung.status === 'zur_voranzeige'
        || veranstaltung.status === 'in_ausschreibung'
        )}
        fields={formFieldsInfos}
        defaultValues={formDataInfos}
        handleSubmit={(newFormData) => {
          // wenn keine software gewaehlt ist, muss vereinbarung nicht akzeptiert werden
          if (newFormData.software_id == null || newFormData.accept_sw_terms) {
            updateVeranstaltung(newFormData, veranstaltung.id);
          } else {
            alert(t('please_accept_sw_terms'));
          }
        }}
        wrapInPaper
      />
    </>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  formDataKonto: state.veranstaltungen.current.forms.kontodaten,
  formDataInfos: state.veranstaltungen.current.forms.weitere_informationen,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(KontodatenUndInfos);
