document.addEventListener('turbo:load', function() {
  $('.table-selectable>tbody')
    .on('click', 'tr[data-selected-url]>td:not(.action)', function(ev) {
      if (ev.target.tagName.toUpperCase() !== 'A') {
        var url = $(this).parent().data('selected-url');
        const shouldOpenInNewTab = ev.metaKey || ev.ctrlKey || ev.shiftKey;

        if (window.Turbo && !shouldOpenInNewTab) {
          Turbo.visit(url);
        } else {
          const target = shouldOpenInNewTab ? '_blank' : '_self';
          window.open(url, target);
        }
      }
    })
    .on('mouseenter mouseleave', '.delete-action', function() {
      $(this).parents('tr').toggleClass('highlighted-attention');
    })
    .on('mouseenter mouseleave', '.success-action', function() {
      $(this).parents('tr').toggleClass('highlighted-success');
    })
    .on('mouseenter mouseleave', '.warning-action', function() {
      $(this).parents('tr').toggleClass('highlighted-warning');
    });
 });
