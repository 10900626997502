import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  TextField,
  Paper,
} from '@mui/material';
import { Box } from '@mui/system';

import { t } from '../../common/i18n/translate';
import { SelectField, MultiSelectField } from '../../common/Formular';
import { setFilter } from '../redux/modules/veranstalterFilter';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../common/constants';

function VeranstalterFilter({ filter, veranstalter, setFilter }) {
  return (
    <Paper sx={{ mt: 2 }} className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.FIRST + 1}>
      <Typography sx={{ mb: 1 }} fontWeight="bold">{t('filter')}</Typography>
      <div id="anlaesse-filter-form" className="flex-row">
        <SelectField
          sx={{ mr: 2 }}
          value={filter.filter.rv_mitglied}
          options={[
            {
              title: t('alle'),
              value: 'alle',
            },
            {
              title: t('mitglied'),
              value: 'nur_mitglieder',
            },
            {
              title: t('nichtmitglied'),
              value: 'nicht_mitglieder',
            },
          ]}
          optionLabel="title"
          optionValue="value"
          handleChange={(_, newValue) => setFilter({ ...filter.filter, rv_mitglied: newValue })}
          label={t('mitglied')}
          labelVariant="outlined"
          variant="outlined"
        />
        <SelectField
          sx={{ mr: 2 }}
          value={filter.filter.aktiv}
          options={[
            {
              title: t('alle'),
              value: 'alle',
            },
            {
              title: t('aktiv'),
              value: 'aktiv',
            },
            {
              title: t('inaktiv'),
              value: 'inaktiv',
            },
          ]}
          optionLabel="title"
          optionValue="value"
          handleChange={(_, newValue) => setFilter({ ...filter.filter, aktiv: newValue })}
          label={t('aktiv')}
          labelVariant="outlined"
          variant="outlined"
        />
        <TextField
          sx={{ mr: 2, flex: 2 }}
          // className="mt-1"
          value={filter.filter.sektionRayon}
          onChange={e => setFilter({ ...filter.filter, sektionRayon: e.target.value })}
          label={t('sektion_rayon')}
          // fullWidth
          size="small"
          variant="outlined"
          InputLabelProps={{
            variant: 'outlined',
          }}
        />
        <Box sx={{ flex: 3 }} />
        <TextField
          sx={{ mr: 2, flex: 2 }}
          // className="mt-1"
          value={filter.filter.sucheText}
          onChange={e => setFilter({ ...filter.filter, sucheText: e.target.value })}
          label={t('suche')}
          // fullWidth
          size="small"
          variant="outlined"
          InputLabelProps={{
            variant: 'outlined',
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  filter: state.veranstalterFilter,
  veranstalter: state.veranstalter,
});

const mapDispatchToProps = dispatch => ({
  setFilter: (filter) => {
    dispatch(setFilter(filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VeranstalterFilter);
