import { post } from "@rails/request.js";
import { mobileAppPlatform, mobileAppVersion } from "../../global/utils";

export const pushNotificationTokenSpeichern = async (jsonString) => {
  const parsedJSON = JSON.parse(jsonString);
  const fcmToken = parsedJSON["token"];
  const uniqueDeviceId = parsedJSON["uniqueDeviceId"];
  const previousFCMToken = localStorage.getItem("currentFCMToken");

  // Nur weitermachen, wenn wir den FCM Token noch nicht kennen
  if (previousFCMToken === fcmToken) return

  const body = {
    token: fcmToken,
    platform: mobileAppPlatform,
    unique_device_id: uniqueDeviceId,
    app_version: mobileAppVersion,
  };
  const response = await post("/push_notification_tokens", { body: body });

  if (response.ok) {
    localStorage.setItem("currentFCMToken", fcmToken);
    console.log("FCM Token wurde an den Server gesendet und gespeichert");
  } else {
    console.log("FCM Token wurde an den Server gesendet aber Server sagt Nein");
  }
}

export const pushNotificationTokenCacheLoeschen = () => {
  localStorage.removeItem("currentFCMToken");
}
