document.addEventListener('turbo:load', function() {
  if ($('.auto-pferd-name').length == 0) return;

  let altePferdId = null;
  $('.auto-pferd-name').on('keyup change', function(e) {
    let feld = $(e.target);
    if (feld.val() != altePferdId) {
      altePferdId = feld.val();
      clearTimeout(this.namenTimeout);
      this.namenTimeout = setTimeout(function() {
        $.ajax({
          method: 'get',
          url: '/pferde_namen/' + feld.val(),
          success: function(data) {
            $('#pferd-name').html(data.name);
          },
          error: function() {
            $('#pferd-name').html('');
          }
        });
      }, 200);
    }
  });
});
