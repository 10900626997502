import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@mui/material';

function DropZone({ callback }) {
  const onDrop = useCallback((acceptedFiles) => {
    callback(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`dropzone drag-${isDragActive ? '' : 'in'}active`}>
      <input {...getInputProps()} />
      <Icon color="primary">image</Icon>
    </div>
  );
}

export default DropZone;
