import React, { useState, useEffect } from 'react';
import { default as MDTextField } from '@mui/material/TextField';
import { default as MDCheckbox } from '@mui/material/Checkbox';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Chip,
  Card,
  CardContent,
  Typography,
  Switch,
  Popover,
  Autocomplete,
  MenuItem,
  Paper,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';

import AutocompletePerson from './AutocompletePerson';
import { formatDate } from './modules/helpers';
import PLZAutocompleteField from './PLZAutocompleteField';
import { setDirty } from '../OAS/store/modules/formulare';
import { t } from './i18n/translate';
import './formular.scss';
import { MUI_PAPER_ELEVATION_LEVELS } from './constants';

function Formular({
  formId,
  fields,
  disabled,
  defaultValues,
  submitButtonKey = 'save',
  cancelButtonKey = 'cancel',
  handleSubmit,
  handleCancel = () => { document.location.hash = '#/'; },
  handleDelete,
  deleteText = t('delete'),
  wrapInPaper = false,
  setDirty,
}) {
  const [fieldValues, setFieldValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [loeschAnker, setLoeschAnker] = useState(undefined);

  useEffect(() => {
    defaultValues.formularIdentifier = formId || 'unknown';
    updateDefaultValues(fields);
    setFieldValues(defaultValues);
    setIsDisabled(disabled);
  }, [defaultValues, disabled]);

  useEffect(() => {
    setDirty(false);
  }, []);

  const updateDefaultValues = (felder) => {
    for (const feld of felder.filter(f => f.defaultValue !== undefined)) {
      defaultValues[feld.name] = defaultValues[feld.name] || feld.defaultValue;
    }
    for (const feld of felder.filter(f => f.children)) {
      updateDefaultValues(feld.children);
    }
  };

  const renderField = ({
    feldTyp,
    children,
    parentClass,
    displayCondition,
    requiredCondition,
    disabledCondition,
    filterOptions,
    additionalChanges,
    defaultValue,
    skipItem,
    minDateGenerator,
    defaultValueDependingOnFieldValue,
    ...rest
  }) => {
    const key = rest.key || rest.name;
    if (skipItem) {
      return <div key={key} style={{ margin: 0, padding: 0 }} />;
    }
    if (displayCondition && !displayCondition(fieldValues)) {
      return <React.Fragment key={key} />;
    }
    if (filterOptions && rest.options) {
      rest.options = filterOptions(fieldValues, rest.options);
    }
    if (requiredCondition) {
      rest.required = requiredCondition(fieldValues);
    }
    if (disabledCondition) {
      rest.disabled = disabledCondition(fieldValues);
    }
    if (minDateGenerator) {
      rest.minDate = minDateGenerator(fieldValues);
    }
    if (defaultValueDependingOnFieldValue) {
      rest.defaultValue = defaultValueDependingOnFieldValue(fieldValues);
    }
    if (rest.name) {
      rest.value = fieldValues[rest.name];
      if (rest.disabled === undefined) rest.disabled = isDisabled;

      rest.handleChange = (key, value) => {
        const alsoChange = (additionalChanges && additionalChanges(value)) || {};
        const newFieldValues = { ...fieldValues, ...alsoChange, [key]: value };
        setTimeout(() => {
          setFieldValues(newFieldValues);
        }, 0);
        setDirty(true);
      };
      if (feldTyp === 'hidden') {
        return <React.Fragment key={key}>{getField({ feldTyp, children, ...rest })}</React.Fragment>;
      }
      return (
        <div key={key} className={parentClass || 'flex-1'} id={`field-${rest.name}`}>
          {getField({ feldTyp, children, ...rest })}
        </div>
      );
    }
    return <React.Fragment key={key}>{getField({ feldTyp, children, ...rest })}</React.Fragment>;
  };

  const getField = ({ feldTyp, children, ...rest }) => {
    if (!rest.label) rest.label = t(rest.name);

    switch (feldTyp) {
      case 'karte':
        return (
          <>
            <div className="flex-column flex-1">
              <Card elevation={wrapInPaper ? MUI_PAPER_ELEVATION_LEVELS.THIRD : MUI_PAPER_ELEVATION_LEVELS.SECOND}>
                <Typography color="textSecondary" style={{ textTransform: 'uppercase', fontSize: 12, padding: '10px 0 0 15px' }}>
                  {rest.kartenTitel}
                </Typography>
                <div style={{ padding: '20px' }} className={rest.className}>
                  <CardContent>
                    {(children || []).map(child => renderField(child))}
                  </CardContent>
                </div>
              </Card>
            </div>
            <div>&nbsp;</div>
          </>
        );
      case 'wrapper':
        return (
          <div className={rest.className}>
            {(children || []).map(child => renderField(child))}
          </div>
        );
      case 'fieldset':
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{rest.label}</FormLabel>
            <FormGroup>{(children || []).map(child => renderField(child))}</FormGroup>
          </FormControl>
        );
      case 'hidden':
        return <HiddenField {...rest} />;
      case 'label':
        const className = rest.className || 'flex-1';
        return <div className={className} style={{ display: 'flex', alignItems: 'center' }}><Typography {...rest}>{rest.label}</Typography></div>;
      case 'text':
        return <TextField {...rest} />;
      case 'select':
        return <SelectField {...rest} size="medium" />;
      case 'select_multiple':
        return <MultiSelectField {...rest} />;
      case 'switch':
        return <SwitchField {...rest} />;
      case 'plz_autocomplete':
        rest.handleChange = (e, v) => {
          if (v) {
            setTimeout(() => {
              setFieldValues({
                ...fieldValues,
                [rest.name]: v.id,
                ort: v.ort,
                kanton: v.kanton,
                postleitzahl: v.value,
              });
            }, 0);
          }
          setDirty(true);
        };
        rest.value = { value: rest.value };
        // muss das label hier setzen mit dem derzeitigen state weil getOptionLabel nicht fuer das initiale laeuft
        rest.plzOrtKantonDefault = fieldValues?.ort_id == null ? null : `${fieldValues.postleitzahl} ${fieldValues.ort}, ${fieldValues.kanton}`;
        return <PLZAutocompleteField {...rest} />;
      case 'autocomplete':
        return <AutocompleteField {...rest} />;
      case 'autocomplete_max_selections':
        rest.handleChange = (e, v) => {
          const currentSelectionAmount = v.length;
          if (currentSelectionAmount <= rest.maxSelections) {
            setTimeout(() => {
              setFieldValues({
                ...fieldValues,
                [rest.name]: v,
              });
            }, 0);
          }
        }
        return <AutocompleteField {...rest} />;
      case 'autocomplete_person':
        return <AutocompletePersonField {...rest} />;
      case 'datepicker':
        return <DateField {...rest} />;
      case 'checkbox':
        return <Checkbox {...rest} />;
      case 'whitespace':
        return <div className="flex-1">&nbsp;</div>;
      case 'component':
        return rest.component;
      case 'dynamic_component':
        return rest.dynamicComponent(fieldValues, setFieldValues);
      default:
        return (
          <div>
            unknown:
            {feldTyp}
          </div>
        );
    }
  };

  const popoverId = 'loesch-confirm-popover';
  const loeschConfirm = (
    <Popover
      id={popoverId}
      open={loeschAnker !== null && loeschAnker !== undefined}
      anchorEl={loeschAnker}
      onClose={() => setLoeschAnker(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="oas-formular-loesch-confirm">
        <Typography classes={{ root: 'loesch-confirm-text' }}>
          {t('datensatz_loeschen_text')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDelete()}
        >
          {t('loeschen')}
        </Button>
      &nbsp;
        <Button
          onClick={(e) => {
            setLoeschAnker(null);
          }}
          variant="contained"
        >
          {t('cancel')}
        </Button>
      </div>
    </Popover>
  );

  const renderForm = () => (
    <form
      className="form oas-form"
      id={`formular-${formId}`}
      autoComplete="off"
    >
      {fields.map(field => renderField(field))}
      <div className="form-actions flex-row">
        <Button
          key="speichernButton"
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={() => (document.getElementById(`formular-${formId}`).reportValidity()
            ? handleSubmit(fieldValues)
            : () => {
              // noop
            })}
        >
          {t(submitButtonKey)}
        </Button>
        {
          handleCancel && (
          <>
&nbsp;
            <Button
              key="abbrechenButton"
              onClick={handleCancel}
            >
              {t(cancelButtonKey)}
            </Button>
          </>
          )
        }
        <div className="flex-1" />
        {
          handleDelete && (
            <>
              {loeschConfirm}
              <Button
                key="loeschenButton"
                variant="contained"
                color="secondary"
                className="delete"
                disabled={isDisabled}
                onClick={(e) => {
                  setLoeschAnker(e.target);
                }}
              >
                {deleteText}
              </Button>
            </>
          )
        }
      </div>
    </form>
  );

  if (wrapInPaper) {
    return (
      <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>{renderForm()}</Paper>
    );
  }

  return renderForm();
}

export function HiddenField({ handleChange, value, ...rest }) {
  return (
    <input
      type="hidden"
      name={rest.name}
      value={value === 0 ? value : (value || '')}
      onChange={(e) => {
        handleChange(rest.name, e.target.value);
      }}
    />
  );
}

export function TextField({ handleChange, value, defaultValue, ...rest }) {
  if (value === undefined && defaultValue !== undefined) {
    value = defaultValue;
  }
  return (
    <MDTextField
      {...rest}
      value={value === 0 ? value : (value || '')}
      inputProps={{ maxLength: rest.maxLength }}
      onChange={(e) => {
        e.persist();
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        handleChange(rest.name, e.target.value);
        // only on actual text inputs (text is default)
        if (rest?.type == null || rest?.type === 'text') {
          setTimeout(() => {
            // Dies ist ein Hack. Nötig, weil wir nicht Redux nutzen, sondern useState.
            // React merkt nicht, dass der Wert aus dem onChange hier kommt. Er müsste per Doku mit setState gesetzt werden.
            // Das geht aber nicht, weil nicht das Textfeld selbst den Text im State hat, sondern die darüberliegende Komponente.
            // Ohne setTimeout würde es auch nicht funktioneren, weil der neue Render erst nach handleChange kommt, was den State im Formular ändert.
            // Siehe auch https://github.com/facebook/react/issues/955
            e.target.setSelectionRange(caretStart, caretEnd);
          }, 0);
        }
      }}
      fullWidth
    />
  );
}

export function SelectField({
  value,
  options,
  optionLabel,
  optionValue,
  handleChange,
  labelVariant = 'standard',
  size = 'small',
  sx,
  ...rest
}) {
  if (!optionValue) optionValue = 'value';
  if (!optionLabel) optionLabel = 'label';
  rest.name = rest.name ?? rest.label;
  return (
    <FormControl size={size} sx={sx}>
      <InputLabel required={!!rest.required} htmlFor={`${rest.name}-native-simple`} variant={labelVariant}>
        {rest.label}
      </InputLabel>
      <Select
        native
        {...rest}
        onChange={e => handleChange(rest.name, e.target.value)}
        value={value === 0 ? value : (value || '')}
        inputProps={{
          name: rest.name,
          id: `${rest.name}-native-simple`,
        }}
      >
        {/* <option disabled value="" /> */}
        {options.map(o => (
          <option key={o[optionValue]} value={o[optionValue]}>
            {o[optionLabel] || o[optionValue]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export function MultiSelectField({
  value, options,
  optionLabel,
  optionValue,
  handleChange,
  labelVariant = 'standard',
  isFullWidth = true,
  size = 'small',
  useDefaultRenderValue = false,
  sx,
  ...rest
}) {
  if (value === undefined) {
    // Späteres Ändern von defaultValue ändert nicht den initialen Wert des Autocomplete - wir ändern
    // aber in der Formular-Komponente die Werte mit useEffect nach dem ersten Rendern (...), wodurch
    // es hier nötig ist erst zu rendern wenn wir wirklich Werte haben.
    // Wichtig: Damit muss für diese Komponente immer ein Wert definiert sein, sonst rendert sie nicht.
    // Ich sehe aber aktuell keinen anderen Weg, ohne useAutocomplete zu hacken o.ä.
    return null;
  }

  if (!optionValue) optionValue = 'value';
  if (!optionLabel) optionLabel = 'label';
  rest.name = rest.name ?? rest.label;

  return (
    <FormControl fullWidth={isFullWidth} size={size} sx={sx}>
      <InputLabel id={`${rest.name}-native-simple`} required={!!rest.required} variant={labelVariant}>
        {rest.label}
      </InputLabel>
      <Select
        // native
        multiple
        labelId={`${rest.name}-native-simple`}
        {...rest}
        label={rest.label}
        onChange={(event) => {
          const { target: { value } } = event;
          // On autofill we get a stringified value
          const newValue = typeof value === 'string' ? value.split(',') : value;
          handleChange(rest.name, newValue);
        }}
        value={value}
        renderValue={useDefaultRenderValue ? null : (value => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Chip label={value.length} size="small" />
          </Box>
        ))}
      >
        {/* <option disabled value="DEFAULT" /> */}
        {options.map(o => (
          <MenuItem key={o[optionValue]} value={o[optionValue]}>
            {o[optionLabel] || o[optionValue]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function AutocompleteField({
  value,
  options,
  optionLabel,
  optionValue,
  label,
  required,
  handleChange,
  ...rest
}) {
  if (!options) options = [];
  if (!optionValue) optionValue = 'value';
  if (!optionLabel) optionLabel = 'label';

  const getOptionLabel = o => o[optionLabel] || '';

  let selected = rest.multiple ? [] : null;
  if (Array.isArray(value)) {
    selected = options.filter(o => value.indexOf(o[optionValue]) > -1);
  } else if (value) {
    selected = options.find(o => o[optionValue] && value && o[optionValue].toString() === (value.id || value).toString());
  }

  return (
    <Autocomplete
      {...rest}
      value={selected}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(event, value) => {
        let newValue;
        if (Array.isArray(value)) {
          newValue = value.map(v => v[optionValue]);
        } else if (value) {
          newValue = value[optionValue];
        }
        handleChange(rest.name, newValue);
      }}
      renderInput={params => (
        <MDTextField
          {...params}
          label={label}
          required={required}
          fullWidth
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip
          label={option[optionLabel]}
          {...getTagProps({ index })}
          disabled={!!rest.disabled}
        />
      ))}
    />
  );
}

export function AutocompletePersonField({ value, handleChange, ...rest }) {
  return (
    <AutocompletePerson
      value={value ?? null}
      label={rest.label}
      disabled={!!rest.disabled}
      required={rest.required}
      onChange={(e, person) => handleChange(rest.name, person)}
    />
  );
}

export function DateField({ value, handleChange, inputVariant = 'standard', size = 'medium', sx, ...rest }) {
  return (
    <DatePicker
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      value={value ? new Date(value) : value}
      onChange={(parsedDate, inputVal) => {
        // nur wenn gueltiges datum
        if (!isNaN(parsedDate)) {
          // wenn per picker gewaehlt ist = undefined, sonst ist eingetippter string der folglich die length vom inputFormat haben muss
          if (inputVal == null || inputVal?.length === 10) {
            handleChange(rest.name, formatDate(parsedDate, 'YYYY-mm-dd'));
          }
        }
      }}
      renderInput={props => <MDTextField {...props} sx={sx} size={size} variant={inputVariant} InputLabelProps={{ variant: inputVariant }} error={false} />}
      {...rest}
    />
  );
}

export function Checkbox({ value, name, label, disabled, handleChange }) {
  return (
    <FormControlLabel
      control={(
        <MDCheckbox
          checked={!!value}
          disabled={!!disabled}
          onChange={(e, newValue) => handleChange(name, newValue)}
        />
      )}
      label={label}
    />
  );
}

export function SwitchField({ value, name, label, disabled, handleChange }) {
  return (
    <FormControlLabel
      control={(
        <Switch
          checked={!!value}
          disabled={!!disabled}
          onChange={(e, newValue) => handleChange(name, newValue)}
          color="primary"
        />
      )}
      label={label}
    />
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setDirty: (isDirty) => {
    dispatch(setDirty(isDirty));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Formular);
