// https://turbo.hotwired.dev/handbook/building#opting-out-of-caching-from-the-client-side
document.addEventListener("turbo:load", function() {
  if (document.querySelector(".turbo-no-cache")) Turbo.cache.exemptPageFromCache()
  if (document.querySelector(".turbo-no-preview")) Turbo.cache.exemptPageFromPreview()

  document.addEventListener("turbo:before-cache", function () {
    // Alle Toasts entfernen, damit sie nicht beim Zurücknavigieren wieder angezeigt werden
    document.querySelectorAll(".toastify").forEach((toast) => toast.remove());

    // Alle Tooltips entfernen
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltipTriggerEl => bootstrap.Tooltip.getInstance(tooltipTriggerEl)?.dispose())

    // Alle Modals entfernen
    document.querySelector("#remote-modal .modal")?.remove();
  })
})
