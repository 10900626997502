// Modaler Dialog mit Fragen zu einer Sammelfrage
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  List,
  Icon,
  IconButton,
} from '@mui/material';

import { t } from '../../common/i18n/translate';

function Teilfragen({ title, showDialog, onClose, onSave, inputsJsx }) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDialog || false}
      TransitionProps={{
        onEnter: () => {
          const focusField = document.querySelector('.unterfragen input, .unterfragen select');
          if (focusField) {
            focusField.focus();
          }
        },
        onClose,
      }}
    >
      <DialogTitle>
        {title}
        <IconButton onClick={onClose} className="close-btn">
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form id="dialog-teilfragen-form">
          <List className="unterfragen">
            {inputsJsx}
          </List>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onSave}>
          {t('speichern')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Teilfragen;
