import React, { useState } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopIcon from '@mui/icons-material/Stop';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatIcon from '@mui/icons-material/Chat';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  Popover,
  Button,
  Tooltip,
  Paper,
} from '@mui/material';

import { formatDate } from '../../../common/modules/helpers';
import { t } from '../../../common/i18n/translate';
import {
  updateVeranstalterStartplaetze,
  nachnennphaseStarten,
  nachnennphaseStoppen,
  nachnennphaseKommentarAktualisieren,
} from '../../../common/modules/api';

function Nennungen(state) {
  const [dragFromTextField, setDragFromTextField] = useState(false);
  const [popoverAnker, setPopoverAnker] = useState({});
  const [veranstalterStartplaetze, setVeranstalterStartplaetze] = useState({});
  const [nachnennphaseContent, setNachnennphaseContent] = useState({});

  const popoverAnzeigen = (event, popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = event.target;
    setPopoverAnker(ankerListe);
  };

  const popoverSchliessen = (popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = null;
    setPopoverAnker(ankerListe);
  };

  const veranstalterStartplaetzeSetzen = (pruefungId, startplaetze) => {
    const neueStartplaetze = {
      ...veranstalterStartplaetze,
    };
    neueStartplaetze[pruefungId] = startplaetze;
    setVeranstalterStartplaetze(neueStartplaetze);
  };

  const nachnennphaseContentSetzen = (pruefungId, wertName, wert) => {
    const neuerContent = {
      ...nachnennphaseContent,
    };
    neuerContent[pruefungId] = neuerContent[pruefungId] || {};
    neuerContent[pruefungId][wertName] = wert;
    setNachnennphaseContent(neuerContent);
  };

  const bezahltesNenngeld = (pruefung) => {
    const bezahlt = pruefung.nennungen.map(n => n.bezahltes_nenngeld).reduce((acc, val) => acc + parseFloat(val), 0)
    return bezahlt > 0 ? `${bezahlt.toFixed(2) } CHF` : '';
  };

  const popoverGenerieren = (popoverId, popoverHover, popoverInhalt, buttonIcon, buttonClass) => (
    <>
      <Tooltip title={popoverHover} arrow>
        <IconButton
          onClick={(e) => {
            popoverAnzeigen(e, popoverId);
            e.stopPropagation();
          }}
          classes={{ root: buttonClass }}
        >
          {buttonIcon}
        </IconButton>
      </Tooltip>
      <Popover
        id={popoverId}
        open={popoverAnker[popoverId] !== null && popoverAnker[popoverId] !== undefined}
        anchorEl={popoverAnker[popoverId]}
        onClose={(e) => {
          popoverSchliessen(popoverId);
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverInhalt}
      </Popover>
    </>
  );

  const startplaetzeAendernContent = (pruefung) => {
    if (pruefung.kann_startplaetze_aendern) {
      const startplaetzeAendernPopoverId = `startplaetze-aendern-popup-${pruefung.id}`;
      const anzahlStartplaetze = veranstalterStartplaetze[pruefung.id] === undefined ? pruefung.anzahl_veranstalter_startplaetze : veranstalterStartplaetze[pruefung.id];
      const startplaetzeAendernPopoverInhalt = (
        <div className="nennungen-popover-inhalt mit-textfeldern" style={{ textAlign: 'center' }}>
          <TextField
            autoComplete="nope"
            label={t('veranstalter_startplaetze')}
            variant="outlined"
            value={anzahlStartplaetze}
            onChange={(e) => {
              veranstalterStartplaetzeSetzen(pruefung.id, e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onMouseDown={(e) => {
              setDragFromTextField(true);
            }}
            fullWidth
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <br />
          <br />
          <Button
            onClick={(e) => {
              updateVeranstalterStartplaetze(pruefung.veranstaltung_id, pruefung.id, anzahlStartplaetze);
            }}
            variant="contained"
            color="primary"
          >
            {t('speichern')}
          </Button>
        &nbsp;
          <Button
            onClick={(e) => {
              popoverSchliessen(startplaetzeAendernPopoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      return popoverGenerieren(
        startplaetzeAendernPopoverId,
        t('veranstalter_startplaetze'),
        startplaetzeAendernPopoverInhalt,
        <>
          <div className="startplaetze">{pruefung.anzahl_veranstalter_startplaetze}</div>
          <PostAddIcon />
        </>,
        'startplaetze-aendern-button',
      );
    }
    return null;
  };

  const nachnennphaseStartenContent = (pruefung) => {
    if (pruefung.kann_nachnennphase_starten) {
      const nachnennphaseStartenPopoverId = `nachnennphase-starten-popup-${pruefung.id}`;
      const text = t('nachnennphase_starten_pruefung_text').replace('%{nummer}', pruefung.nummer).replace('%{datum}', formatDate(new Date(pruefung.datum), 'dd.mm.YY')).replace('%{kategorie}', pruefung.kategorie_text);
      const nachnennphaseStartenPopoverInhalt = (
        <div className="nennungen-popover-inhalt mit-textfeldern" style={{ textAlign: 'center' }}>
          <Typography style={{ fontSize: '14px' }}>
            {text}
          </Typography>
          <div>&nbsp;</div>
          <TextField
            autoComplete="nope"
            label={t('anzahl_nennungen_nachnennphase')}
            variant="outlined"
            value={(nachnennphaseContent[pruefung.id] || {}).max_nennungen === undefined ? (pruefung.max_nennungen) : (nachnennphaseContent[pruefung.id] || {}).max_nennungen}
            onChange={(e) => {
              nachnennphaseContentSetzen(pruefung.id, 'max_nennungen', e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onMouseDown={(e) => {
              setDragFromTextField(true);
            }}
            fullWidth
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <br />
          <br />
          <TextField
            autoComplete="nope"
            label={t('nenngeld_nachnennphase')}
            variant="outlined"
            value={(nachnennphaseContent[pruefung.id] || {}).nenngeld2 === undefined ? (pruefung.nenngeld + parseFloat(pruefung.nachnennphase_nenngeld_erhoehung)) : (nachnennphaseContent[pruefung.id] || {}).nenngeld2}
            disabled={true}
            fullWidth
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <br />
          <br />
          <TextField
            autoComplete="nope"
            label={t('kommentar_nachnennphase')}
            variant="outlined"
            value={(nachnennphaseContent[pruefung.id] || {}).hinweis_nachnennphase}
            onChange={(e) => {
              nachnennphaseContentSetzen(pruefung.id, 'hinweis_nachnennphase', e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onMouseDown={(e) => {
              setDragFromTextField(true);
            }}
            fullWidth
            multiline
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <br />
          <br />
          <Button
            onClick={(e) => {
              nachnennphaseStarten(pruefung.veranstaltung_id, pruefung.id, {
                ...nachnennphaseContent[pruefung.id],
                max_nennungen: (nachnennphaseContent[pruefung.id] || {}).max_nennungen === undefined ? (pruefung.max_nennungen) : (nachnennphaseContent[pruefung.id] || {}).max_nennungen,
                nenngeld2: (nachnennphaseContent[pruefung.id] || {}).nenngeld2 === undefined ? (pruefung.nenngeld + parseFloat(pruefung.nachnennphase_nenngeld_erhoehung)) : (nachnennphaseContent[pruefung.id] || {}).nenngeld2,
              });
            }}
            variant="contained"
            color="primary"
          >
            {t('nachnennphase_starten')}
          </Button>
        &nbsp;
          <Button
            onClick={(e) => {
              popoverSchliessen(nachnennphaseStartenPopoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      return popoverGenerieren(nachnennphaseStartenPopoverId, t('nachnennphase_starten'), nachnennphaseStartenPopoverInhalt, <PlayCircleFilledIcon />, 'nachnennphase-starten-button');
    }
    return null;
  };

  const nachnennphaseStoppenContent = (pruefung) => {
    if (pruefung.kann_nachnennphase_stoppen) {
      const nachnennphaseStoppenPopoverId = `nachnennphase-stoppen-popup-${pruefung.id}`;
      const text = t('nachnennphase_stoppen_pruefung_text').replace('%{nummer}', pruefung.nummer).replace('%{datum}', formatDate(new Date(pruefung.datum), 'dd.mm.YY')).replace('%{kategorie}', pruefung.kategorie_text);
      const nachnennphaseStoppenPopoverInhalt = (
        <div className="nennungen-popover-inhalt" style={{ textAlign: 'center' }}>
          <Typography style={{ fontSize: '14px' }}>
            {text}
          </Typography>
          <div>&nbsp;</div>
          <Button
            onClick={(e) => {
              nachnennphaseStoppen(pruefung.veranstaltung_id, pruefung.id);
            }}
            variant="contained"
            color="primary"
          >
            {t('nachnennphase_stoppen')}
          </Button>
        &nbsp;
          <Button
            onClick={(e) => {
              popoverSchliessen(nachnennphaseStoppenPopoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      return popoverGenerieren(nachnennphaseStoppenPopoverId, t('nachnennphase_stoppen'), nachnennphaseStoppenPopoverInhalt, <StopIcon />, 'nachnennphase-stoppen-button');
    }
    return null;
  };

  const nachnennphaseKommentarContent = (pruefung) => {
    if (pruefung.kann_nachnennphase_kommentar) {
      const nachnennphaseKommentarPopoverId = `nachnennphase-kommentar-popup-${pruefung.id}`;
      let hinweisNachnennphase = (nachnennphaseContent[pruefung.id] || {}).hinweis_nachnennphase;
      if (hinweisNachnennphase === undefined) {
        hinweisNachnennphase = pruefung.hinweis_nachnennphase;
      }
      const nachnennphaseKommentarPopoverInhalt = (
        <div className="nennungen-popover-inhalt mit-textfeldern" style={{ textAlign: 'center' }}>
          <TextField
            autoComplete="nope"
            label={t('kommentar_nachnennphase')}
            variant="outlined"
            value={hinweisNachnennphase}
            onChange={(e) => {
              nachnennphaseContentSetzen(pruefung.id, 'hinweis_nachnennphase', e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onMouseDown={(e) => {
              setDragFromTextField(true);
            }}
            fullWidth
            multiline
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <br />
          <br />
          <Button
            onClick={(e) => {
              nachnennphaseKommentarAktualisieren(pruefung.veranstaltung_id, pruefung.id, hinweisNachnennphase);
            }}
            variant="contained"
            color="primary"
          >
            {t('speichern')}
          </Button>
        &nbsp;
          <Button
            onClick={(e) => {
              popoverSchliessen(nachnennphaseKommentarPopoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      const nachnennphaseKommentarBubble = pruefung.hinweis_nachnennphase
        ? <ChatIcon />
        : <ChatBubbleOutlineIcon />;
      return popoverGenerieren(
        nachnennphaseKommentarPopoverId,
        t('kommentar_nachnennphase'),
        nachnennphaseKommentarPopoverInhalt,
        nachnennphaseKommentarBubble,
        'nachnennphase-kommentar-button',
      );
    }
    return null;
  };

  return (
    <div className="nennungen-pro-veranstaltung">
      <Paper className="padding-1">
        <Table size="small" className="nennungen-tabelle">
          <TableHead>
            <TableRow>
              <TableCell>{t('nummer_nr')}</TableCell>
              <TableCell>{t('datum')}</TableCell>
              <TableCell classes={{ root: 'wrappable' }}>
                {t('kategorie')}
                {' '}
                /
                {t('wertung')}
              </TableCell>
              <TableCell align="right">{t('ng')}</TableCell>
              <TableCell align="right" classes={{ root: 'wrappable' }}>{t('total_nenngeld')}</TableCell>
              <TableCell align="right" classes={{ root: 'wrappable' }}>{t('total_nennungen')}</TableCell>
              <TableCell align="right" classes={{ root: 'wrappable' }}>{t('hc_abgem_falschn_zurueckgew')}</TableCell>
              <TableCell align="right" classes={{ root: 'wrappable' }}>{t('zaehlbare_nennungen')}</TableCell>
              <TableCell align="right" classes={{ root: 'wrappable' }}>{t('max_nennungen')}</TableCell>
              <TableCell classes={{ root: 'wrappable' }}>{t('ons_status')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {state.pruefungen.map(pruefung => (
              <TableRow
                key={pruefung.id}
                hover
                className="table-hover-row"
                onClick={(e) => {
                  if (!e.target.closest('div.nennungen-popover-inhalt') && !dragFromTextField) {
                    window.location.hash = `#/${state.veranstaltung.id}/edit/ausschreibung_pruefung/${pruefung.id}/nennungen`;
                  }
                  setDragFromTextField(false);
                }}
              >
                <TableCell>{pruefung.nummer}</TableCell>
                <TableCell>{formatDate(new Date(pruefung.datum), 'dd.mm.YY')}</TableCell>
                <TableCell classes={{ root: 'wrappable' }}>
                  {pruefung.kategorie_text}
                  {' '}
                  /
                  {pruefung.wertung_text}
                </TableCell>
                <TableCell align="right">
                  {[pruefung.nenngeld, pruefung.nenngeld2].filter(n => n).join('/') || 0}
                </TableCell>
                <TableCell align="right">
                  {bezahltesNenngeld(pruefung)}
                </TableCell>
                <TableCell align="right">{pruefung.anzahl_nennungen}</TableCell>
                <TableCell align="right">
                  {pruefung.anzahl_hc}
                  {' '}
                  /
                  {pruefung.anzahl_abmeldungen}
                  {' '}
                  /
                  {pruefung.anzahl_falschnennungen}
                  {' '}
                  /
                  {pruefung.anzahl_zurueckgewiesen}
                </TableCell>
                <TableCell align="right">{pruefung.anzahl_zaehlbar}</TableCell>
                <TableCell align="right">{pruefung.max_nennungen || '-'}</TableCell>
                <TableCell>{t(`ons_status_${pruefung.ons_status}`)}</TableCell>
                <TableCell>
                  {startplaetzeAendernContent(pruefung)}
                  {nachnennphaseStartenContent(pruefung)}
                  {nachnennphaseStoppenContent(pruefung)}
                  {nachnennphaseKommentarContent(pruefung)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  pruefungen: state.veranstaltungen.current.forms.nennungen.pruefungen,
});

export default connect(mapStateToProps, null)(Nennungen);
