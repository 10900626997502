import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="partials--int-nennung"
export default class extends Controller {
  static targets = ["feiEventDropdown", "slotCheckbox", "pferdeField", "zusaetzlicheFelderFrame"];
  static values = {
    nennungUpdateUrl: String,
    isSlotAktiv: Boolean,
  }

  connect() {
    if (this.isSlotAktivValue) {
      this.#togglePferdeFields()
    }
  }

  slotCheckboxTargetConnected(toggle) {
    toggle.checked = this.isSlotAktivValue;
  }

  clickedSlotCheckbox() {
    this.isSlotAktivValue = this.slotCheckboxTarget.checked;
    this.#togglePferdeFields();
  }

  updatePferdFrame() {
    const url = new URL(this.nennungUpdateUrlValue);
    url.searchParams.set("fei_event_code", this.feiEventDropdownTarget.value);
    window.history.replaceState(history.state, "", url)

    url.searchParams.set("temp_id", this.zusaetzlicheFelderFrameTarget.dataset.tempId);
    Turbo.visit(url, { frame: this.zusaetzlicheFelderFrameTarget.id });
  }

  #togglePferdeFields() {
    this.pferdeFieldTargets.forEach((pferdeField) => {
      pferdeField.classList.toggle("d-none");
    });
  }
}
