import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Icon,
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
} from '@mui/material';

import { suchePersonen } from './requests';

function SelectPerson({ name, label, handleUpdate, initValue }) {
  const [timeoutHandle, setTimeoutHandle] = useState();
  const [personen, setPersonen] = useState();
  const [values, setValues] = useState({ query: '' });
  const [selectedPerson, setSelectedPerson] = useState({});

  useEffect(() => {
    if (initValue.id) {
      setValues({ query: `${initValue.name}, ${initValue.ort}` });
    } else {
      setValues({ query: '' });
    }
    setSelectedPerson(initValue);
  }, [initValue]);

  const handleBlur = (event) => {
    setTimeout(() => {
      setPersonen([]);
    }, 500);
  };

  const handleChange = (event, secondsDelay = 1000) => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }

    const { value } = event.target;
    setValues({ query: value });

    if (value.length > 2) {
      const timeout = setTimeout(async () => {
        const gefundenePersonen = await suchePersonen(value);
        setPersonen(gefundenePersonen);
      }, secondsDelay);

      setTimeoutHandle(timeout);
    }
  };

  const selectPerson = (person) => {
    setValues({ query: `${person.name}, ${person.ort}` });
    setSelectedPerson(person);
    setPersonen(null);
    handleUpdate(person);
  };

  const clearSelectedPerson = () => {
    setSelectedPerson({});
    handleUpdate({});
    setValues({ query: '' });
  };

  const isPersonSelected = !!selectedPerson.id;

  return (
    <div>
      <FormControl>
        <InputLabel className={values.query ? 'MuiInputLabel-shrink MuiFormLabel-filled' : ''}>
          {label}
        </InputLabel>
        <Input
          name={`${name}_input`}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isPersonSelected}
          value={values.query}
          onFocus={event => handleChange(event, 0)}
          endAdornment={
            isPersonSelected && (
              <InputAdornment position="end">
                <IconButton onClick={clearSelectedPerson}>
                  <Icon>close</Icon>
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <List hidden={!personen}>
        {personen?.map(person => (
          <ListItem key={person.id} button onClick={() => selectPerson(person)}>
            <ListItemText primary={`${person.name}, ${person.ort}`} />
            {' '}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default SelectPerson;
