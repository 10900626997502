// import 'jquery-ui/themes/base/core.css';
// import 'jquery-ui/themes/base/theme.css';
// import 'jquery-ui/themes/base/selectable.css';
// import 'jquery-ui/ui/core';
// import 'jquery-ui/ui/widgets/selectable';
// import 'jquery-ui/ui/widgets/datepicker';
// import 'jquery-ui/ui/widgets/autocomplete';
// import 'jquery-ui/ui/widgets/draggable';
// import 'jquery-ui/ui/widgets/sortable';

import 'jquery-ui/dist/jquery-ui';
