import { PAGINATION_ITEMS_PER_PAGE } from '../../../common/constants';
import { apiService } from '../../../common/services';
import { formatDate } from '../../../common/modules/helpers';

import type { Filter } from './anlaesseFilter';

const DURCHFUEHRUNGEN_ABFRAGEN = 'equis/selfservice/events/durchfuehrungen_abfragen';
const DURCHFUEHRUNGEN_ABFRAGEN_SUCCESS = 'equis/selfservice/events/durchfuehrungen_abfragen_success';
const DURCHFUEHRUNGEN_ABFRAGEN_FAIL = 'equis/selfservice/events/durchfuehrungen_abfragen_fail';

const BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN = 'equis/selfservice/events/bearbeitbare_veranstaltungen_abfragen';
const BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_SUCCESS = 'equis/selfservice/events/bearbeitbare_veranstaltungen_abfragen_success';
const BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_FAIL = 'equis/selfservice/events/bearbeitbare_veranstaltungen_abfragen_fail';

const DURCHFUEHRUNG_GENEHMIGEN = 'equis/selfservice/events/durchfuehrung_genehmigen';
const DURCHFUEHRUNG_GENEHMIGEN_SUCCESS = 'equis/selfservice/events/durchfuehrung_genehmigen_success';
const DURCHFUEHRUNG_GENEHMIGEN_FAIL = 'equis/selfservice/events/durchfuehrung_genehmigen_fail';

const DURCHFUEHRUNG_ALLE_GENEHMIGEN = 'equis/selfservice/events/durchfuehrung_alle_genehmigen';
const DURCHFUEHRUNG_ALLE_GENEHMIGEN_SUCCESS = 'equis/selfservice/events/durchfuehrung_alle_genehmigen_success';
const DURCHFUEHRUNG_ALLE_GENEHMIGEN_FAIL = 'equis/selfservice/events/durchfuehrung_alle_genehmigen_fail';

const DURCHFUEHRUNG_ZURUECKWEISEN = 'equis/selfservice/events/durchfuehrung_zurueckweisen';
const DURCHFUEHRUNG_ZURUECKWEISEN_SUCCESS = 'equis/selfservice/events/durchfuehrung_zurueckweisen_success';
const DURCHFUEHRUNG_ZURUECKWEISEN_FAIL = 'equis/selfservice/events/durchfuehrung_zurueckweisen_fail';

const DURCHFUEHRUNG_SPEICHERN = 'equis/selfservice/events/durchfuehrung_speichern';
const DURCHFUEHRUNG_SPEICHERN_SUCCESS = 'equis/selfservice/events/durchfuehrung_speichern_success';
const DURCHFUEHRUNG_SPEICHERN_FAIL = 'equis/selfservice/events/durchfuehrung_speichern_fail';

const getFilterParams = (fuerFilter: Filter) => ({
  status: fuerFilter.status.join(','),
  sektion_rayon: fuerFilter.sektionRayon,
  rv_anlaesse: fuerFilter.rv_anlaesse.join(','),
  ort: fuerFilter.ortSucheText,
  sportjahre: fuerFilter.sportjahr.join(','),
  von: formatDate(fuerFilter.von, 'YYYY-mm-dd'),
  bis: formatDate(fuerFilter.bis, 'YYYY-mm-dd'),
});

const getQueryParams = (fuerFilter: Filter) => Object.entries(getFilterParams(fuerFilter)).map(entry => (`${entry[0]}=${entry[1]}`)).join('&');

export const anlaesseReducer = (state, action) => {
  switch (action.type) {
    case DURCHFUEHRUNGEN_ABFRAGEN:
    case BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN:
    case DURCHFUEHRUNG_GENEHMIGEN:
    case DURCHFUEHRUNG_ALLE_GENEHMIGEN:
    case DURCHFUEHRUNG_ZURUECKWEISEN:
    case DURCHFUEHRUNG_SPEICHERN:
      return { ...state, isLoading: true };
    case DURCHFUEHRUNGEN_ABFRAGEN_SUCCESS:
      return {
        ...state,
        liste: action.loadedAnlassDurchfuehrungen,
        total: action.total,
        isLoading: false,
      };
    case BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_SUCCESS:
      return {
        ...state,
        bearbeitbareVeranstaltungen: action.loadedVeranstaltungen,
        isLoading: false,
      };
    case DURCHFUEHRUNG_ALLE_GENEHMIGEN_SUCCESS:
      return { ...state, isLoading: false };
    case DURCHFUEHRUNG_GENEHMIGEN_SUCCESS:
      return { ...state, isLoading: false };
    case DURCHFUEHRUNG_ZURUECKWEISEN_SUCCESS:
      return { ...state, isLoading: false };
    case DURCHFUEHRUNG_SPEICHERN_SUCCESS:
      return { ...state, isLoading: false };
    case DURCHFUEHRUNGEN_ABFRAGEN_FAIL:
    case BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_FAIL:
    case DURCHFUEHRUNG_ALLE_GENEHMIGEN_FAIL:
    case DURCHFUEHRUNG_GENEHMIGEN_FAIL:
    case DURCHFUEHRUNG_ZURUECKWEISEN_FAIL:
    case DURCHFUEHRUNG_SPEICHERN_FAIL:
      return { ...state, isLoading: false };
    default:
      return state || { liste: [], current: {}, bearbeitbareVeranstaltungen: [], isLoading: true };
  }
};

export const bearbeitbareVeranstaltungenAbfragen = () => async (dispatch) => {
  dispatch({ type: BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN });

  const response = await apiService.get('backend/events/veranstaltungen_zum_anlaesse_bearbeiten');

  if (response?.data) {
    dispatch({
      type: BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_SUCCESS,
      loadedVeranstaltungen: response.data.bearbeitbare_veranstaltungen,
    });
  } else {
    dispatch({ type: BEARBEITBARE_VERANSTALTUNGEN_ABFRAGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const durchfuehrungenAbfragen = (page: number, mitFilter: Filter, sortField: string, sortOrder: 'ASC' | 'DESC') => async (dispatch) => {
  dispatch({ type: DURCHFUEHRUNGEN_ABFRAGEN });

  const response = await apiService.get(`backend/events/anlass_durchfuehrungen?page=${page}&limit=${PAGINATION_ITEMS_PER_PAGE}&sortField=${sortField}&sortOrder=${sortOrder}&${getQueryParams(mitFilter)}`);

  if (response?.data) {
    dispatch({
      type: DURCHFUEHRUNGEN_ABFRAGEN_SUCCESS,
      loadedAnlassDurchfuehrungen: response.data.anlass_durchfuehrungen,
      total: response.data.total,
    });
  } else {
    dispatch({ type: DURCHFUEHRUNGEN_ABFRAGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const durchfuehrungGenehmigen = (durchfuehrungId: number) => async (dispatch) => {
  dispatch({ type: DURCHFUEHRUNG_GENEHMIGEN });
  const response = await apiService.post(`backend/events/anlass_durchfuehrungen/${durchfuehrungId}/genehmigen`);

  if (response?.data) {
    dispatch({
      type: DURCHFUEHRUNG_GENEHMIGEN_SUCCESS,
    });
  } else {
    dispatch({ type: DURCHFUEHRUNG_GENEHMIGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const durchfuehrungAlleGenehmigen = () => async (dispatch) => {
  dispatch({ type: DURCHFUEHRUNG_ALLE_GENEHMIGEN });
  const response = await apiService.post('backend/events/anlass_durchfuehrungen/alle_genehmigen');

  if (response?.data) {
    dispatch({
      type: DURCHFUEHRUNG_ALLE_GENEHMIGEN_SUCCESS,
    });
  } else {
    dispatch({ type: DURCHFUEHRUNG_ALLE_GENEHMIGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const durchfuehrungZurueckweisen = (durchfuehrungId: number) => async (dispatch) => {
  dispatch({ type: DURCHFUEHRUNG_ZURUECKWEISEN });
  const response = await apiService.post(`backend/events/anlass_durchfuehrungen/${durchfuehrungId}/zurueckweisen`);

  if (response?.data) {
    dispatch({
      type: DURCHFUEHRUNG_ZURUECKWEISEN_SUCCESS,
    });
  } else {
    dispatch({ type: DURCHFUEHRUNG_ZURUECKWEISEN_FAIL, errorMsg: response.data.messages });
  }
};

export const durchfuehrungSpeichern = anlassDurchfuehrung => async (dispatch) => {
  dispatch({ type: DURCHFUEHRUNG_SPEICHERN });
  let response;

  const payload = {
    anlass_durchfuehrung: {
      id: anlassDurchfuehrung?.id,
      anlass_id: anlassDurchfuehrung.anlass_id,
      veranstaltung_id: anlassDurchfuehrung.veranstaltung_id,
      tage: anlassDurchfuehrung.tage,
      // status: anlassDurchfuehrung.status,
      bemerkung: anlassDurchfuehrung.bemerkung,
    },
  };

  if (anlassDurchfuehrung?.id) {
    response = await apiService.put(`backend/events/anlass_durchfuehrungen/${anlassDurchfuehrung.id}`, payload);
  } else {
    response = await apiService.post('backend/events/anlass_durchfuehrungen', payload);
  }

  if (response?.data) {
    dispatch({
      type: DURCHFUEHRUNG_SPEICHERN_SUCCESS,
    });
  } else {
    dispatch({ type: DURCHFUEHRUNG_SPEICHERN_FAIL, errorMsg: response.data.messages });
  }
};

// wegen axios etwas speziell, einfach ein a-tag geht nicht weil muss headers senden
export const excelExport = (mitFilter: Filter) => apiService({
  url: 'backend/events/anlass_durchfuehrungen/export_liste.xlsx',
  method: 'GET',
  responseType: 'blob',
  params: getFilterParams(mitFilter),
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', 'anlass_durchfuehrungen_liste.xlsx');
  document.body.appendChild(link);
  link.click();
});
