document.addEventListener("turbo:load", function() {
  var vereinID = $('#voltige').attr('data-verein-id');

  var $athleten = $('#voltige-athleten');
  $athleten.find('#athlet-suchen')
  .on('keyup', function() {
    var eingabe = this.value.toLowerCase();
    $athleten.find('.athlet').each(function() {
      var $athlet = $(this);
      var name = $athlet.find('.name').text().toLowerCase();
      var athlet_anzeigen = name.indexOf(eingabe) != -1; // NB: true bei leerer Eingabe
      if (athlet_anzeigen) {
        $athlet.show();
      } else {
        $athlet.hide();
        $athlet.find('input[type=checkbox]').prop('checked', false);
      }
    });
  })
  .show();

  $athleten.find('.athlet .name')
  .draggable({
    containment: '#voltige',
    scope: 'voltige_gruppen_zugehoerigkeiten',
    revert: 'invalid',
    helper: 'clone'
  })
  .disableSelection();
});
