import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { t } from '../../common/i18n/translate';
import {
  benutzerAbfragen,
  benutzerSpeichern,
  benutzerEntfernen,
} from '../redux/modules/benutzer';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import BenutzerEditDialog from './BenutzerEditDialog';

function Benutzer() {
  const [benutzerInBearbeitung, setBenutzerInBearbeitung] = useState(null);
  const { benutzer } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const handleBenutzerAbfragen = () => benutzerAbfragen()(dispatch);
  // TODO: inefficient to handleBenutzerAbfragen again just because status of one/some benutzer changed
  const handleBenutzerSpeichern = async (benutzerData) => {
    await benutzerSpeichern(benutzerData)(dispatch);
    handleBenutzerAbfragen();
  };
  const handleBenutzerEntfernen = async (personId) => {
    await benutzerEntfernen(personId)(dispatch);
    handleBenutzerAbfragen();
  };

  useEffect(() => {
    handleBenutzerAbfragen();
  }, []);

  return (
    <>
      <Paper className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1">
          <div className="flex-row">
            <div className="flex-1">&nbsp;</div>
            {/* add/delete vorerst deaktiviert - benoetigt weitere abklaerung vor freischaltung */}
            <Button
              size="small"
              variant="contained"
              onClick={e => setBenutzerInBearbeitung({})}
              disabled
            >
              <Icon fontSize="small" color="action">add</Icon>
            </Button>
          </div>
          <DataGrid
            // STYLING
            density="compact"
            sx={{ marginTop: 2 }}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            // DATA CONFIG
            columns={[
              { field: 'person_id', headerName: t('id'), sortComparator: (v1, v2) => Number(v1) - Number(v2), flex: 1 },
              {
                field: 'vorname_name',
                headerName: t('vorname_name'),
                valueGetter: p => `${p.row.person.vorname} ${p.row.person.nachname}`,
                flex: 3,
              },
              { field: 'ort', headerName: t('ort'), valueGetter: p => p.row.person.ort, flex: 2 },
              { field: 'email', headerName: t('email'), valueGetter: p => p.row.person.email, flex: 4 },
              {
                field: 'mitgliedschaften',
                headerName: t('rolle'),
                valueGetter: p => p.row.mitgliedschaften.map(r => r.bezeichnung).join(' / '),
                flex: 3,
              },
              {
                field: 'aktionen',
                headerName: t('aktionen'),
                renderCell: p => (
                  <div className="flex-row">
                    {/* add/delete vorerst deaktiviert - benoetigt weitere abklaerung vor freischaltung */}
                    {p.row.kann_loeschen && (
                      <IconButton size="small" onClick={e => handleBenutzerEntfernen(p.row.person_id)} disabled>
                        <Icon fontSize="small">clear</Icon>
                      </IconButton>
                    )}
                  </div>
                ),
                flex: 1,
                sortable: false,
              },
            ]}
            rows={benutzer.liste}
            loading={benutzer.isLoading}
            // PAGINATION
            hideFooterPagination
          />
        </div>
      </Paper>
      <BenutzerEditDialog
        benutzer={benutzerInBearbeitung}
        onDialogClose={(data) => {
          if (data) {
            handleBenutzerSpeichern(data);
          }
          setBenutzerInBearbeitung(null);
        }}
        // TODO: besser uebersetzung fur das ganze, weil reihenfolge je nach sprache evtl verschieden
        title={`${t('benutzer')} ${(Object.keys(benutzerInBearbeitung ?? {}).length > 0 ? t('edit') : t('add')).toLocaleLowerCase()}`}
      />
    </>
  );
}

export default Benutzer;
