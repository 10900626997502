// Init
import "./turbo/turbo"
import "./init/react_rails"
import "./init/datepicker"
import "./init/flash"
import "./init/sortable-table"
import "./init/initializer"
import "./init/jquery_plugins/imgareaselect"

// Global
import "./global/warnung_vor_formular_verlassen"

// Custom
import "./custom/cookie-banner"
import "./custom/autocompletion_config"
import "./custom/form"
import "./custom/dokumente"
import "./custom/format_phone"
import "./custom/iframe_session"
import "./custom/ons_konto"
import "./custom/selectable_table_rows"
import "./custom/anchor_links"
import "./custom/prozess_navigation"
import "./custom/crop_editor_image"

// Partials
import "./partials/nennungen"
import "./partials/auslandstarts"
import "./partials/brevetkurse"
import "./partials/nachwuchskader"
import "./partials/auszahlungsantrag"
import "./partials/voltige_gruppen_zugehoerigkeiten"
import "./partials/ausbildung"

// Stimulus
import "../controllers"
