import React, { useState, useEffect } from 'react';
import { Tab, Tabs, useMediaQuery, useTheme, Icon } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';

import { t } from '../common/i18n/translate';

const navItems = [
  { key: 'veranstaltungen', title: t('veranstaltungen') },
  { key: 'rv_anlaesse', title: t('rv_anlaesse') },
  { key: 'veranstalter', title: t('veranstalter') },
  { key: 'benutzer', title: t('benutzer') },
];

function EventsTabNav() {
  const veranstaltungenActive = useMatch('veranstaltungen');
  const veranstaltungDetailActive = useMatch('veranstaltungen/:veranstaltungId');
  const anlaesseActive = useMatch('rv_anlaesse');
  const veranstalterActive = useMatch('veranstalter');
  const benutzerActive = useMatch('benutzer');
  const [primaryNavValue, setPrimaryNavValue] = useState();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xl'));

  // init from URL location
  useEffect(() => {
    let activeKey;

    if (veranstaltungenActive || veranstaltungDetailActive) {
      activeKey = 'veranstaltungen';
    } else if (anlaesseActive) {
      activeKey = 'rv_anlaesse';
    } else if (veranstalterActive) {
      activeKey = 'veranstalter';
    } else if (benutzerActive) {
      activeKey = 'benutzer';
    } else {
      activeKey = 'veranstaltungen';
    }

    setPrimaryNavValue(activeKey);
  }, []);

  if (primaryNavValue == null) {
    return null;
  }

  return (
    <Tabs
      value={primaryNavValue}
      onChange={(e, newPrimaryValue) => setPrimaryNavValue(newPrimaryValue)}
      variant={isMobileView ? 'fullWidth' : 'standard'}
    >
      {navItems.map(item => (
        <Tab
          key={item.key}
          value={item.key}
          label={!isMobileView || (isMobileView && item.icon == null) ? item.title : null}
          icon={item.icon ? <Icon>{item.icon}</Icon> : null}
          iconPosition="start"
          component={Link}
          to={item.key}
        />
      ))}
    </Tabs>
  );
}

export default EventsTabNav;
