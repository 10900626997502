import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';

import StarIcon from '@mui/icons-material/Star';
import TextEingebenDialog from '../../common/TextEingebenDialog';
import { fromHtml, toHtml } from '../../common/modules/helpers';
import { t, t_var } from '../../common/i18n/translate';
import { useAppSelector } from '../redux/hooks';

function RatingFrage({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);
  const [showDialog, setShowDialog] = useState(false);
  const ratingDetails = antwortDetails.details || {};
  let { rating } = ratingDetails;
  const bemerkung = ratingDetails.bemerkung || '';

  const updateRating = (newRating) => {
    rating = newRating;
    let newBemerkung = bemerkung;
    if (newRating < 3) {
      setShowDialog(true);
    } else {
      newBemerkung = '';
    }
    updateAntwort(null, { rating, bemerkung: newBemerkung });
  };

  const onDialogClose = (newBemerkung) => {
    setShowDialog(false);
    if (newBemerkung !== null) {
      newBemerkung = toHtml(newBemerkung);
      updateAntwort(null, { rating, bemerkung: newBemerkung });
    }
  };

  const config = frage.config || {};

  return (
    <ListItem divider className="ratingFrage">
      <TextEingebenDialog
        value={fromHtml(bemerkung)}
        showDialog={showDialog}
        onDialogClose={onDialogClose}
        title={t('begruendung')}
        inputLabel={t('begruendung')}
        description={t_var(config, 'dialog_helptext')}
        config={config}
      />
      <ListItemText
        primary={t_var(frage, 'titel')}
        secondary={(
          <>
            <Typography component="span" variant="body2">
              {t_var(frage, 'untertitel')}
            </Typography>
            <Typography
              className="antwort dblock"
              component="span"
              variant="body2"
              dangerouslySetInnerHTML={{ __html: `${bemerkung}` }}
            />
          </>
        )}
      />
      <ListItemSecondaryAction>
        <div className="rating">
          {config.ratings.map((ratingElement) => {
            let color = 'inherit';
            if (rating >= ratingElement.value) {
              color = 'primary';
            }
            if (rapport.status !== 'offen') {
              return (
                <StarIcon key={ratingElement.value} color={color} />
              );
            }
            return (
              <Tooltip
                title={t_var(ratingElement, 'label')}
                placement="top"
                key={ratingElement.value}
              >
                <IconButton
                  size="small"
                  color={color}
                  onClick={() => updateRating(ratingElement.value)}
                >
                  <StarIcon color={color} />
                </IconButton>
              </Tooltip>
            );
          })}
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default RatingFrage;
