import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';

import Spinner from '../../../common/Spinner';
import { formatDate, objIsEmpty } from '../../../common/modules/helpers';
import { t, getLanguage } from '../../../common/i18n/translate';
import { downloadNennungen, downloadVeranstaltung } from '../../../common/modules/api';
import { nennungenLaden } from '../../store/modules/nennungen';
import { veranstaltungLaden } from '../../store/modules/veranstaltungen';
import NennungenTabelle from './NennungenTabelle';
import NennungenTabelleVoltige from './NennungenTabelleVoltige';

function NennungenProPruefung(props) {
  const params = useParams();
  const veranstaltungId = parseInt(params.veranstaltungId);
  const pruefungId = parseInt(params.ausschreibungPruefungId);

  useEffect(() => {
    downloadNennungen(veranstaltungId, pruefungId, data => props.nennungenLaden(data));
  }, [veranstaltungId, pruefungId]);

  useEffect(() => {
    downloadVeranstaltung(veranstaltungId, newData => props.veranstaltungLaden(newData));
  }, [veranstaltungId]);

  if (objIsEmpty(props.veranstaltung) || objIsEmpty(props.stammdaten) || objIsEmpty(props.infos)) return <Spinner />;

  return (
    <div className="nennungen-pro-pruefung">
      <div className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1">
          <Paper className="padding-1">
            <Table
              size="small"
              className="nennungen-zusammenfassungs-tabelle"
              style={{ maxWidth: '400px', marginBottom: '1em' }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>{t('veranstaltung')}</b>
                  </TableCell>
                  <TableCell>{props.infos.titel}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('pruefung_nr')}</b>
                  </TableCell>
                  <TableCell>{props.infos.nummer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('datum')}</b>
                  </TableCell>
                  <TableCell>{formatDate(new Date(props.infos.datum), 'dd.mm.YY')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('kategorie')}</b>
                  </TableCell>
                  <TableCell>{props.infos.kategorie_text}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('ons_status')}</b>
                  </TableCell>
                  <TableCell>{t(`ons_status_${props.infos.ons_status}`)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="h5">
              {t('nennungen_bis_nennschluss')}
              {' '}
              {formatDate(new Date(props.infos.nennschluss), 'dd.mm.YY')}
            </Typography>
            {
              props.infos.ist_voltige
                ? <NennungenTabelleVoltige nennungen={props.nennungen.filter(n => !n.nach_nennschluss)} totalLabel={t('total_bis_nennschluss')} />
                : <NennungenTabelle nennungen={props.nennungen.filter(n => !n.nach_nennschluss)} totalLabel={t('total_bis_nennschluss')} />
            }

            {
              props.nennungen.filter(n => n.nach_nennschluss).length > 0
                ? (
                  <>
                    <Typography variant="h5">
                      {t('nennungen_nachnennphase')}
                    </Typography>
                    {
                    props.infos.ist_voltige
                      ? <NennungenTabelleVoltige nennungen={props.nennungen.filter(n => n.nach_nennschluss)} totalLabel={t('total_nachnennphase')} />
                      : <NennungenTabelle nennungen={props.nennungen.filter(n => n.nach_nennschluss)} totalLabel={t('total_nachnennphase')} />
                  }
                  </>
                ) : null
            }

            <Table
              size="small"
              className="nennungen-zusammenfassungs-tabelle"
              style={{ maxWidth: '400px', marginTop: '1em' }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>{t('nennungen_per')}</b>
                  </TableCell>
                  <TableCell>{props.infos.nennungen_per}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('total_nennungen')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_nennungen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('anzahl_hc')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_hc}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('abmeldungen')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_abmeldungen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('falschnennungen')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_falschnennungen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('zurueckgewiesene')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_zurueckgewiesen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('total_zaehlbare_nennungen')}</b>
                  </TableCell>
                  <TableCell>{props.infos.anzahl_zaehlbar}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('anzahl_startplaetze_max')}</b>
                  </TableCell>
                  <TableCell>{props.infos.max_nennungen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('ons_status')}</b>
                  </TableCell>
                  <TableCell>{props.infos.ons_status}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  nennungen: state.nennungen.nennungen,
  stammdaten: state.stammdaten[getLanguage()],
  infos: state.nennungen.pruefung_infos,
});

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
  nennungenLaden: (nennungen) => {
    dispatch(nennungenLaden(nennungen));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NennungenProPruefung);
