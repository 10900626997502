import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { withPageTracking } from '../common/tracking';
import Veranstaltungen from './Veranstaltungen';
import VeranstaltungErstellen from './Veranstaltungen/VeranstaltungErstellen';
import VeranstaltungBearbeiten from './Veranstaltungen/VeranstaltungBearbeiten';
import NennungenProPruefung from './Veranstaltungen/Nennungen/NennungenProPruefung';
import VeranstaltungKopieren from './Veranstaltungen/VeranstaltungKopieren';
import NeuAusKopie from './Veranstaltungen/VeranstaltungKopieren/NeuAusKopie';
import PruefungBearbeiten from './Veranstaltungen/Pruefungen/PruefungBearbeiten';
import RechteBeantragen from './Veranstaltungen/RechteBeantragen';
import StandaloneAusschreibung from './Veranstaltungen/AusschreibungVorschau/StandaloneAusschreibung';
import Veranstaltungsdaten from './Veranstaltungen/Veranstaltungsdaten';
import AusschreibungVorschau from './Veranstaltungen/AusschreibungVorschau';
import Stallungen from './Veranstaltungen/Stallungen';
import KontodatenUndInfos from './Veranstaltungen/KontodatenUndInfos';
import Kopfdaten from './Veranstaltungen/Kopfdaten';
import Beteiligungen from './Veranstaltungen/Beteiligungen';
import Pruefungen from './Veranstaltungen/Pruefungen';
import Fussdaten from './Veranstaltungen/Fussdaten';
import Nennungen from './Veranstaltungen/Nennungen';
import Dokumente from './Veranstaltungen/Dokumente';
import AusschreibungKopieren from './Veranstaltungen/AusschreibungKopieren';

export const routes = () => [
  {
    key: 'veranstaltungen',
    title: 'Meine Veranstaltungen',
    url: '/',
    exact: true,
    element: Veranstaltungen,
  },
  {
    key: 'veranstaltung_kopieren_liste',
    url: '/new',
    element: VeranstaltungKopieren,
  },
  {
    key: 'veranstaltung_kopieren',
    url: '/copy/:fromId',
    element: NeuAusKopie,
  },
  {
    key: 'veranstaltung_erstellen',
    url: '/build',
    element: VeranstaltungErstellen,
  },
  {
    key: 'veranstaltung_bearbeiten',
    url: '/:veranstaltungId/edit',
    element: VeranstaltungBearbeiten,
    children: [
      {
        key: 'ausschreibung_starten',
        url: 'ausschreibung_starten',
        element: AusschreibungKopieren,
      },
      {
        key: 'ausschreibung',
        url: 'ausschreibung',
        element: AusschreibungVorschau,
      },
      {
        key: 'stallungen',
        url: 'stallungen',
        element: Stallungen,
      },
      {
        key: 'konto_infos',
        url: 'konto_infos',
        element: KontodatenUndInfos,
      },
      {
        key: 'kopfdaten',
        url: 'kopfdaten',
        element: Kopfdaten,
      },
      {
        key: 'beteiligungen',
        url: 'beteiligungen',
        element: Beteiligungen,
      },
      {
        key: 'pruefungen',
        url: 'pruefungen',
        element: Pruefungen,
      },
      {
        key: 'pruefung_bearbeiten',
        url: 'pruefungen/:pruefungId',
        element: PruefungBearbeiten,
      },
      {
        key: 'fussdaten',
        url: 'fussdaten',
        element: Fussdaten,
      },
      {
        key: 'nennungen',
        url: 'nennungen',
        element: Nennungen,
      },
      {
        key: 'nennungen_index',
        url: 'ausschreibung_pruefung/:ausschreibungPruefungId/nennungen',
        element: NennungenProPruefung,
      },
      {
        key: 'dokumente',
        url: 'dokumente',
        element: Dokumente,
      },
      {
        key: 'veranstaltungsdaten',
        url: 'veranstaltungsdaten',
        element: Veranstaltungsdaten,
      },
      {
        // da default route ist (= index) braucht es keine url
        key: 'veranstaltungsdaten_index',
        url: null,
        element: Veranstaltungsdaten,
        index: true,
      },
    ],
  },
  {
    key: 'rechte_beantragen',
    url: '/rechte_beantragen',
    element: RechteBeantragen,
  },
  {
    key: 'ausschreibung',
    url: '/:veranstaltungId/ausschreibung/',
    element: StandaloneAusschreibung,
  },
  {
    key: 'ausschreibung',
    url: '/:veranstaltungId/ausschreibung_drucken',
    element: StandaloneAusschreibung,
  },
];

function Router() {
  return (
    // bit of special case because of hashrouter, no need to set basename here, but still needs to be passed to withPageTracking
    <HashRouter basename="/">
      <Routes>
        {routes().map(route => (
          <Route key={route.key} path={route.url} element={withPageTracking(route.element, '/oas_veranstaltungen')} exact={route.exact} index={route.index}>
            {route?.children?.map(childRoute => <Route key={childRoute.key} path={childRoute.url} element={withPageTracking(childRoute.element, '/oas_veranstaltungen')} exact={childRoute.exact} index={childRoute.index} />)}
          </Route>
        ))}
      </Routes>
    </HashRouter>
  );
}

export default Router;
