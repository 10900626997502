import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Icon,
} from '@mui/material';

import { SelectField } from '../../common/Formular';
import AutocompletePerson from '../../common/AutocompletePerson';
import { t } from '../../common/i18n/translate';

function BenutzerEditDialog({
  benutzer,
  onDialogClose,
  title,
}) {
  const [benutzerLocal, setBenutzerLocal] = useState(null);

  useEffect(() => {
    setBenutzerLocal(benutzer);
  }, [benutzer]);

  if (benutzerLocal == null) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!benutzer}
      TransitionProps={{
        onClose: () => onDialogClose(null),
      }}
    >
      <DialogTitle sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', fontWeight: 600 }}>
        {title}
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon style={{ color: 'white' }}>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <AutocompletePerson
          value={benutzerLocal?.person}
          label={t('person')}
          disabled={benutzer?.id != null}
          required
          onChange={(e, person) => setBenutzerLocal({ ...benutzerLocal, person_id: person.id, person })}
        />
        {/* momentan kann nur Rolle aktuar gesetzt werden, wird im backend automatisch gesetzt */}
        <SelectField
          sx={{ mt: 3 }}
          value="aktuar"
          options={[
            {
              title: t('aktuar'),
              value: 'aktuar',
            },
          ]}
          optionLabel="title"
          optionValue="value"
          disabled
          // handleChange={(_, newValue) => setFilter({ ...filter.filter, rv_mitglied: newValue })}
          label={t('rolle')}
          labelVariant="outlined"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(benutzerLocal)}>
          {benutzer?.id != null ? t('speichern') : t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BenutzerEditDialog;
