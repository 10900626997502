import React, { Suspense } from 'react';

import Spinner from '../Spinner';
import ErrorBoundary from '../ErrorBoundary';
import './Layout.css';

function Layout(props) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        {props.children}
      </Suspense>
    </ErrorBoundary>
  );
}

export default Layout;
