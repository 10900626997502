const Mustache = require('mustache');
import { FetchRequest } from '@rails/request.js'

document.addEventListener('turbo:load', () => {
  function prepareModalDialog(dialogSelector, url, bestaetigungen) {
    var $dialog = $(dialogSelector),
        template = $(dialogSelector + '_template').html(),
        hat_bestaetigungen = bestaetigungen && bestaetigungen.items.length != 0;

    if(hat_bestaetigungen) {
      $dialog
        .find('.bestaetigungen')
        .html(Mustache.to_html(template, bestaetigungen));
    }

    var $firstInput = $dialog
      .find('form')
      .prop('action', url)
      .find('input[type=text],textarea,select')
      .filter(':first');

    if($firstInput.length != 0) {
      $dialog
        .on('shown', function(){
          $firstInput.focus();
        });
    }

    return $dialog.modal();
  };

  $('.has-dialogues')
    .on('click', '.reachable a', function(ev){
      console.log('click');
      ev.preventDefault();

      var data = $(this).data(),
          url = data['eventUrl'],
          bestaetigungen = data['bestaetigungen'],
          hat_bestaetigungen = bestaetigungen && bestaetigungen.items.length != 0,
          $dialog,
          $link;
      console.log({url, bestaetigungen, hat_bestaetigungen});
      // Wenn Grund nötig, dann Begründungs-Dialog anzeigen
      if(data['grundNoetig']) {
        prepareModalDialog('#reason_dialog', url, bestaetigungen)
          .modal();
      }
      // Wenn Grund nicht nötig aber Bestaetigungen vorhanden sind, dann Bestätigungs-Dialog mit den Meldungen anzeigen
      else if(hat_bestaetigungen) {
        prepareModalDialog('#confirm_dialog', url, bestaetigungen)
          .modal();
      }
      else {
        requestPostUrl(url);
      }
    });
});

async function requestPostUrl(url) {
  const request   = new FetchRequest('post', url)
  const response  = await request.perform();
  if (response.ok) {
    location.reload();
  }
}
