import React from 'react';
import Rollbar from 'rollbar';

import { fetchRollbarConfig } from './requests';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.rollbar = undefined;
    this.state = { hasError: false };
    fetchRollbarConfig((rollbarConfig) => {
      this.rollbar = new Rollbar(rollbarConfig);
    });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.state = { hasError: true };
    if (this.rollbar) {
      this.rollbar.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ flex: 1, textAlign: 'center' }}>
          <h1>Something went wrong.</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
