import React, { useState } from 'react';

import './ProzessButtons.scss';

import ForwardIcon from '@mui/icons-material/Forward';
import { Typography, Button, Popover, TextField, Switch, FormControlLabel, List, ListItem, ListItemText } from '@mui/material';

import { connect } from 'react-redux';
import { t } from '../../../common/i18n/translate';
import { loescheVeranstaltung, updateVeranstaltungStatus } from '../../../common/modules/api';

function ProzessButtons({ veranstaltung, formulare }) {
  const [popoverAnker, setPopoverAnker] = useState({});
  const [absageBegruendung, setAbsageBegruendung] = useState('');
  const [freigabeNotiz, setFreigabeNotiz] = useState('');
  const [freigabeAbfragen, setFreigabeAbfragen] = useState({
    parcours: false,
    tierarzt: false,
    juryTechnik: false,
  });

  const popoverAnzeigen = (event, popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = event.target;
    setPopoverAnker(ankerListe);
  };

  const popoverSchliessen = (popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = null;
    setPopoverAnker(ankerListe);
  };

  const einButtonFormular = (popoverId, titel, text, color, callback) => (
    <div className="prozess-popup">
      {text && (
        <Typography classes={{ root: 'prozess-popup-text' }}>
          {text}
        </Typography>
      )}
      <Button
        disabled={formulare.isDirty}
        variant="contained"
        color={color}
        onClick={(e) => {
          callback(e);
        }}
      >
        {titel}
      </Button>
        &nbsp;
      <Button
        onClick={() => {
          popoverSchliessen(popoverId);
        }}
        variant="contained"
      >
        {t('cancel')}
      </Button>
    </div>
  );

  const popoverGenerieren = (popoverId, popoverInhalt) => (
    <Popover
      id={popoverId}
      open={popoverAnker[popoverId] !== null && popoverAnker[popoverId] !== undefined}
      anchorEl={popoverAnker[popoverId]}
      onClose={() => popoverSchliessen(popoverId)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {popoverInhalt}
    </Popover>
  );

  if (veranstaltung && veranstaltung.status) {
    if (veranstaltung.status === 'in_erfassung') {
      const meldenPopoverId = 'melden-rueckfrage';
      const loeschenPopoverId = 'loeschen-rueckfrage';
      return (
        <div className="prozess-buttons">
          <Button
            disabled={formulare.isDirty}
            onClick={(e) => {
              popoverAnzeigen(e, meldenPopoverId);
            }}
            variant="contained"
            color="primary"
          >
            {t('melden')}
          </Button>
          &nbsp;
          <Button
            onClick={(e) => {
              popoverAnzeigen(e, loeschenPopoverId);
            }}
            variant="contained"
            color="secondary"
          >
            {t('loeschen')}
          </Button>
          {popoverGenerieren(meldenPopoverId, einButtonFormular(meldenPopoverId, t('melden'), t('melden_text').replace('%{regionalverband}', veranstaltung.forms.veranstaltungsdaten.regionalverband_name), 'primary', () => {
            updateVeranstaltungStatus(veranstaltung.id, 'gemeldet');
          }))}
          {popoverGenerieren(loeschenPopoverId, einButtonFormular(loeschenPopoverId, t('loeschen'), t('loeschen_text'), 'secondary', () => {
            loescheVeranstaltung(veranstaltung.id);
          }))}
        </div>
      );
    } if (veranstaltung.status === 'zur_voranzeige' || (veranstaltung.status === 'in_ausschreibung' && !veranstaltung.hat_inserat)) {
      const popoverId = 'absagen-formular';
      const popoverInhalt = (
        <div className="prozess-popup begruendung-popup">
          <Typography>
            {t('absagen_text')}
          </Typography>
          <div>&nbsp;</div>
          <TextField
            value={absageBegruendung}
            label={t('begruendung')}
            required
            fullWidth
            variant="outlined"
            multiline
            onChange={(e) => {
              setAbsageBegruendung(e.target.value);
            }}
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
          <div>&nbsp;</div>
          <Button
            disabled={formulare.isDirty}
            onClick={() => {
              updateVeranstaltungStatus(veranstaltung.id, 'abgesagt', {
                begruendung: absageBegruendung,
              });
            }}
            variant="contained"
            color="primary"
          >
            {t('absagen')}
          </Button>
        &nbsp;
          <Button
            onClick={() => {
              popoverSchliessen(popoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      return (
        <div className="prozess-buttons">
          <Button
            disabled={formulare.isDirty}
            onClick={(e) => {
              popoverAnzeigen(e, popoverId);
            }}
            variant="contained"
            color="secondary"
          >
            {t('absagen')}
          </Button>
          {popoverGenerieren(popoverId, popoverInhalt)}
        </div>
      );
    } if (veranstaltung.status === 'in_ausschreibung' && veranstaltung.hat_inserat) {
      const hatPruefungen = !!(veranstaltung.forms.pruefungen.filter(p => !p.titel).length);
      let allePruefungenHabenMaxNennungen = hatPruefungen;
      veranstaltung.forms.pruefungen.forEach((pruefung) => {
        if (!(pruefung.max_nennungen || pruefung.titel)) {
          allePruefungenHabenMaxNennungen = false;
        }
      });
      if (!veranstaltung.hat_prozessvalidierung || ((veranstaltung.nennvariante !== 'nennphase' || allePruefungenHabenMaxNennungen) && veranstaltung.forms.weitere_informationen.software_id && veranstaltung.forms.kontodaten.id && hatPruefungen)) {
        const popoverId = 'freigeben-formular';
        const popoverInhalt = (
          <div className="prozess-popup freigabe-popup">
            <Typography variant="h5">{t('freigeben_titel')}</Typography>
            <div>&nbsp;</div>
            <Typography>{t('freigeben_frage')}</Typography>
            <div>&nbsp;</div>
            <Typography><strong>{t('freigeben_funktionen')}</strong></Typography>
            <div>&nbsp;</div>
            <FormControlLabel
              control={(
                <Switch
                  checked={freigabeAbfragen.parcours}
                  onChange={(e, newValue) => setFreigabeAbfragen({
                    ...freigabeAbfragen,
                    parcours: newValue,
                  })}
                />
            )}
              label={t('freigeben_parcours')}
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={freigabeAbfragen.tierarzt}
                  onChange={(e, newValue) => setFreigabeAbfragen({
                    ...freigabeAbfragen,
                    tierarzt: newValue,
                  })}
                />
            )}
              label={t('freigeben_tierarzt')}
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={freigabeAbfragen.juryTechnik}
                  onChange={(e, newValue) => setFreigabeAbfragen({
                    ...freigabeAbfragen,
                    juryTechnik: newValue,
                  })}
                />
            )}
              label={t('freigeben_jury_technik')}
            />
            <div>&nbsp;</div>
            <TextField
              value={freigabeNotiz}
              label={t('freigeben_notiz')}
              fullWidth
              variant="outlined"
              multiline
              onChange={(e) => {
                setFreigabeNotiz(e.target.value);
              }}
              InputLabelProps={{
                variant: 'outlined',
              }}
            />
            <div>&nbsp;</div>
            <Button
              disabled={formulare.isDirty}
              onClick={() => {
                if (freigabeAbfragen.parcours && freigabeAbfragen.tierarzt && freigabeAbfragen.juryTechnik) {
                  updateVeranstaltungStatus(veranstaltung.id, 'zur_kontrolle_svps', {
                    freigabe_notiz: freigabeNotiz,
                  });
                } else {
                  alert(t('freigeben_felder_fehlen'));
                }
              }}
              variant="contained"
              color="primary"
            >
              {t('freigeben')}
            </Button>
          &nbsp;
            <Button
              onClick={() => {
                popoverSchliessen(popoverId);
              }}
              variant="contained"
            >
              {t('cancel')}
            </Button>
          </div>
        );
        return (
          <div className="prozess-buttons">
            <Button
              disabled={formulare.isDirty}
              onClick={(e) => {
                popoverAnzeigen(e, popoverId);
              }}
              variant="contained"
              color="primary"
            >
              {t('freigeben')}
            </Button>
            {popoverGenerieren(popoverId, popoverInhalt)}
          </div>
        );
      }
      const popoverId = 'freigeben-formular';
      const popoverInhalt = (
        <div className="prozess-popup freigabe-popup">
          <Typography variant="h5">{t('freigeben_titel')}</Typography>
          <div>&nbsp;</div>
          <Typography>{t('freigeben_daten_fehlen')}</Typography>
          <List>
            {
              !hatPruefungen && (
              <ListItem
                button
                component="a"
                href={`#/${veranstaltung.id}/edit/pruefungen`}
                onClick={() => {
                  popoverSchliessen(popoverId);
                }}
              >
                <ForwardIcon />
&nbsp;&nbsp;
                <ListItemText primary={t('freigeben_pruefungen_fehlen')} />
              </ListItem>
              )
            }
            {
              (veranstaltung.nennvariante === 'nennphase' && !allePruefungenHabenMaxNennungen) && (
              <ListItem
                button
                component="a"
                href={`#/${veranstaltung.id}/edit/pruefungen`}
                onClick={() => {
                  popoverSchliessen(popoverId);
                }}
              >
                <ForwardIcon />
&nbsp;&nbsp;
                <ListItemText primary={t('freigeben_pruefungen_ohne_max_nennungen')} />
              </ListItem>
              )
            }
            {
              !veranstaltung.forms.weitere_informationen.software_id && (
              <ListItem
                button
                component="a"
                href={`#/${veranstaltung.id}/edit/konto_infos`}
                onClick={() => {
                  popoverSchliessen(popoverId);
                }}
              >
                <ForwardIcon />
&nbsp;&nbsp;
                <ListItemText primary={t('freigeben_software_fehlt')} />
              </ListItem>
              )
            }
            {
              !veranstaltung.forms.kontodaten.id && (
              <ListItem
                button
                component="a"
                href={`#/${veranstaltung.id}/edit/konto_infos`}
                onClick={() => {
                  popoverSchliessen(popoverId);
                }}
              >
                <ForwardIcon />
&nbsp;&nbsp;
                <ListItemText primary={t('freigeben_konto_fehlt')} />
              </ListItem>
              )
            }
          </List>
          <div>&nbsp;</div>
          <Button
            onClick={() => {
              popoverSchliessen(popoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      );
      return (
        <div className="prozess-buttons">
          <Button
            disabled={formulare.isDirty}
            onClick={(e) => {
              popoverAnzeigen(e, popoverId);
            }}
            variant="contained"
            color="primary"
          >
            {t('freigeben')}
          </Button>
          {popoverGenerieren(popoverId, popoverInhalt)}
        </div>
      );
    }
  }

  return null;
}

const mapStateToProps = state => ({
  formulare: state.formulare,
});

export default connect(mapStateToProps, null)(ProzessButtons);
