import React, { useMemo } from 'react';

import TitelFrage from './TitelFrage';
import BooleanFrage from './BooleanFrage';
import PersonFrage from './PersonFrage';
import NummerFrage from './NummerFrage';
import RatingFrage from './RatingFrage';
import NummerFrageSimple from './NummerFrageSimple';
import ImpfproblemFrage from './ImpfproblemFrage';
import TextFrage from './TextFrage';
import SammelFrage from './SammelFrage';
import UnbekannteFrage from './UnbekannteFrage';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { uploadAntwort, setAntwort, deletePferdAktionen, removeAllPferdeAktionFromStore } from '../redux/Juryrapport';
import './Frage.css';

function Frage({ frage, istTeilfrage }) {
  const { rapport, antworten } = useAppSelector(state => state.juryrapport);
  const dispatch = useAppDispatch();
  const handleUploadAntwort = (...args) => uploadAntwort(...args)(dispatch);
  const handleDeletePferdeAktionen = (...args) => deletePferdAktionen(...args)(dispatch);
  const handleRemoveAllPferdeAktionFromStore = (...args) => removeAllPferdeAktionFromStore(...args)(dispatch);

  const antwortDetails = useMemo(() => antworten.find(a => a.frage_id === frage.id) ?? {}, [antworten, frage.id]);

  const updateAntwort = (value, details) => {
    // Teilfrage-Anworten werden zuerst nur lokal im store gespeichert
    // erst beim speichern im SammelFrage-Teilfragen Dialog wird ge-uploaded
    if (istTeilfrage) {
      setAntwort(frage.id, value, details)(dispatch);
    } else {
      handleUploadAntwort(rapport.id, frage.id, value, details);
    }

    // Child-Fragen zurücksetzen, wenn Antwort "false" ist
    if (frage?.children?.length > 0 && value === false) {
      frage.children.forEach((teilfrage) => {
        handleUploadAntwort(rapport.id, teilfrage.id, null, null);
      });

      const impfproblemFrageIndex = frage.children.findIndex(f => f.typ === 'impfproblem');
      if (impfproblemFrageIndex !== -1) {
        // Alle PferdeAktionen für diesen Report löschen
        handleDeletePferdeAktionen(rapport.id, 'delete_all');
        handleRemoveAllPferdeAktionFromStore();
      }
    }
  };

  if (istTeilfrage) {
    switch (frage.typ) {
      case 'titel':
        return <TitelFrage frage={frage} />;
      case 'textfeld':
        return (
          <TextFrage frage={frage} antwortDetails={antwortDetails} updateAntwort={updateAntwort} />
        );
      case 'nummer':
        return (
          <NummerFrageSimple
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      case 'impfproblem':
        return (
          <ImpfproblemFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      default:
        return <UnbekannteFrage frage={frage} />;
    }
  } else {
    switch (frage.typ) {
      case 'titel':
        return <TitelFrage frage={frage} />;
      case 'boolean':
        return (
          <BooleanFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      case 'person':
        return (
          <PersonFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      case 'nummer':
        return (
          <NummerFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      case 'sammelfrage':
        return (
          <SammelFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
            uploadAntwort={handleUploadAntwort}
          />
        );
      // case "textfeld":
      //   return (
      //     <TextFrage
      //       frage={frage}
      //       antwortDetails={antwortDetails}
      //       updateAntwort={updateAntwort}
      //     />
      //   );
      case 'rating':
        return (
          <RatingFrage
            frage={frage}
            antwortDetails={antwortDetails}
            updateAntwort={updateAntwort}
          />
        );
      default:
        return <UnbekannteFrage frage={frage} />;
    }
  }
}

export default Frage;
