import { Controller } from "@hotwired/stimulus"
import { isMobileApp } from "../packs/global/utils"
require('tom-select')

// Connects to data-controller="tom-select"
export default class extends Controller {
  connect() {
    if (isMobileApp) {
      // In der mobile App soll kein TomSelect verwendet werden, da die nativen Selects besser aussehen
      // Standard Bootstrap Styling für Selects aktivieren
      this.element.classList.add('form-select')
    } else {
      // Standard Bootstrap Styling für Selects entfernen, da TomSelect das Styling selbst übernimmt
      this.element.classList.remove('form-select')

      this.select = new TomSelect(this.element)
    }
  }

  disconnect() {
    this.select.destroy()
  }
}
