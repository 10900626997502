import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-form"
export default class extends Controller {
  static targets = ["form"];

  connect() {
    $('.datepicker').change(function() {
      const event = new Event('change')
      this.dispatchEvent(event)
    })

    $('input[type=checkbox]').click(function() {
      const event = new Event('change')
      this.dispatchEvent(event)
    })
 }

  submit() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 300);
  }
}
