import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-button"
export default class extends Controller {
  static targets = [ "button" ]

  toggleButton() {
    this.buttonTargets.forEach((button) => {
      if (button.hasAttribute('disabled')) {
        this.enableButton();
      } else {
        this.disableButton();
      }
    })
  }

  enableButton() {
    this.buttonTargets.forEach((button) => {
      button.removeAttribute('disabled');
    });
  }

  disableButton() {
    this.buttonTargets.forEach((button) => {
      button.setAttribute('disabled', 'disabled');
    });
  }
}
