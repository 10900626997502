const PAGINATION_ITEMS_PER_PAGE = 100;
const MUI_PAPER_ELEVATION_LEVELS = {
  FIRST: 1,
  SECOND: 3,
  THIRD: 5,
  FOURTH: 7,
};

export {
  PAGINATION_ITEMS_PER_PAGE,
  MUI_PAPER_ELEVATION_LEVELS,
};
