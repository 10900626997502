import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Outlet } from 'react-router-dom';

import { Paper } from '@mui/material';
import DetailNav from './DetailNav';
import VeranstaltungSidebar from './VeranstaltungSidebar';
import { getLanguage } from '../../common/i18n/translate';
import Spinner from '../../common/Spinner';

import './index.scss';
import { downloadVeranstaltung } from '../../common/modules/api';
import { veranstaltungLaden } from '../store/modules/veranstaltungen';
import { objIsEmpty } from '../../common/modules/helpers';

function VeranstaltungBearbeiten(props) {
  const params = useParams();
  const { veranstaltungId } = params;

  useEffect(() => {
    downloadVeranstaltung(veranstaltungId, newData => props.veranstaltungLaden(newData));
  }, [veranstaltungId]);

  if (objIsEmpty(props.veranstaltungen.current) || objIsEmpty(props.stammdaten)) {
    return <Spinner />;
  }

  return (
    <Paper>
      <DetailNav veranstaltung={props.veranstaltung} />
      <div className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1"><Outlet /></div>
        <VeranstaltungSidebar />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VeranstaltungBearbeiten);
