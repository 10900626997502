import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Icon,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { t } from '../../common/i18n/translate';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants';
import {
  durchfuehrungenAbfragen,
  durchfuehrungGenehmigen,
  durchfuehrungAlleGenehmigen,
  durchfuehrungZurueckweisen,
  bearbeitbareVeranstaltungenAbfragen,
  durchfuehrungSpeichern,
  excelExport,
} from '../redux/modules/anlaesse';
import { setPage } from '../redux/modules/anlaesseFilter';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import AnlaesseFilter from './AnlaesseFilter';
import AnlassEditDialog from './AnlassEditDialog';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { formatDate } from '../../common/modules/helpers';

function Anlaesse() {
  const [anlassDurchfuehrungInBearbeitung, setAnlassDurchfuehrungInBearbeitung] = useState(null);
  const [sortModel, setSortModel] = useState({ field: 'name', order: 'ASC' });
  const [showConfirmGenehmigenAll, setShowConfirmGenehmigenAll] = useState(false);
  const { anlaesseFilter: filter, anlaesse } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const handleDurchfuehrungenAbfragen = (fuerPage, mitFilter, sortField, sortOrder) => durchfuehrungenAbfragen(fuerPage, mitFilter, sortField, sortOrder)(dispatch);
  const handleBearbeitbareVeranstaltungenAbfragen = () => bearbeitbareVeranstaltungenAbfragen()(dispatch);
  // TODO: inefficient to handleDurchfuehrungenAbfragen again just because status of one/some veranstaltung changed

  const handleDurchfuehrungGenehmigen = async (durchfuehrungId) => {
    await durchfuehrungGenehmigen(durchfuehrungId)(dispatch);
    handleDurchfuehrungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };

  const handleDurchfuehrungAlleGenehmigen = async () => {
    setShowConfirmGenehmigenAll(false);
    await durchfuehrungAlleGenehmigen()(dispatch);
    handleDurchfuehrungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };

  const handleDurchfuehrungZurueckweisen = async (durchfuehrungId) => {
    await durchfuehrungZurueckweisen(durchfuehrungId)(dispatch);
    handleDurchfuehrungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };

  const handleDurchfuehrungSpeichern = async (durchfuehrungData) => {
    await durchfuehrungSpeichern(durchfuehrungData)(dispatch);
    handleDurchfuehrungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };

  const handleSetPage = neuePage => dispatch(setPage(neuePage));

  useEffect(() => {
    handleDurchfuehrungenAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  }, [filter]);

  useEffect(() => {
    handleBearbeitbareVeranstaltungenAbfragen();
  }, []);

  return (
    <>
      <Paper className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1">
          <div className="flex-row">
            <div className="flex-1" />
            <Button
              onClick={() => setShowConfirmGenehmigenAll(true)}
              variant="contained"
              size="small"
            >
              {t('alle_genehmigen')}
            </Button>
            <Button
              onClick={e => setAnlassDurchfuehrungInBearbeitung({})}
              variant="contained"
              size="small"
              sx={{ marginLeft: '1em' }}
            >
              <Icon fontSize="small" color="action">add</Icon>
            </Button>
            {anlaesse.liste.length > 0 && (
              <Button
                sx={{ marginLeft: '1em' }}
                onClick={() => excelExport(filter.filter)}
                variant="contained"
                size="small"
              >
                {t('export_xlsx')}
              </Button>
            )}
          </div>
          <AnlaesseFilter />
          <DataGrid
            // density='compact'
            // STYLING
            sx={{ marginTop: 2 }}
            autoHeight
            getRowHeight={() => 'auto'}
            getCellClassName={p => 'autoheight-datagrid-cell'}
            disableColumnMenu
            disableSelectionOnClick
            // DATA CONFIG
            columns={[
              {
                field: 'datum',
                headerName: t('datum'),
                renderCell: p => (
                  <Box sx={{ whiteSpace: 'nowrap' }}>
                    <span>{`${formatDate(new Date(p.row.veranstaltung_von), 'dd.mm.YY')} -`}</span>
                    <br />
                    <span>{formatDate(new Date(p.row.veranstaltung_bis), 'dd.mm.YY')}</span>
                  </Box>
                ),
                flex: 2,
              },
              { field: 'veranstaltung', headerName: t('veranstaltung'), flex: 2, sortable: false },
              {
                field: 'tag',
                headerName: t('tag'),
                renderCell: p => (
                  <>
                    {p.row.tage.length > 0 && p.row.tage.map(durchfuehrungsTag => (
                      <Chip
                        key={durchfuehrungsTag}
                        sx={{ mr: 1 }}
                        label={formatDate(new Date(durchfuehrungsTag), 'dd.mm')}
                        variant="filled"
                        size="small"
                      />
                    ))}
                  </>
                ),
                flex: 2,
                sortable: false,
              },
              { field: 'anlass_bezeichnung', headerName: t('anlass'), flex: 4 },
              { field: 'veranstalter_name', headerName: t('veranstalter'), flex: 3, sortable: true },
              { field: 'sektion', headerName: t('sektion'), flex: 1, sortable: false },
              { field: 'regionalverband_code', headerName: t('regionalverband_gekuerzt'), flex: 1, sortable: false },
              {
                field: 'status',
                headerName: t('status'),
                renderCell: p => (
                  <Chip
                    label={p.row.status_label}
                    variant="filled"
                    size="small"
                    color={p.row.status === 'definitiv' ? 'success' : (p.row.status === 'geloescht' ? 'default' : 'secondary')}
                  />
                ),
                flex: 2,
              },
              {
                field: 'aktionen',
                headerName: t('aktionen'),
                renderCell: p => (
                  <div className="flex-row">
                    {p.row.status === 'provisorisch' && (
                      <Tooltip title={t('definitiv')}>
                        <IconButton
                          size="small"
                          onClick={(e) => handleDurchfuehrungGenehmigen(p.row.id)}
                        >
                          <Icon fontSize="small">done</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {p.row.kann_bearbeiten && (
                      <Tooltip title={t('edit')}>
                        <IconButton
                          size="small"
                          onClick={(e) => setAnlassDurchfuehrungInBearbeitung(p.row)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                    {p.row.status === 'provisorisch' && (
                      <Tooltip title={t('loeschen')}>
                        <IconButton
                          size="small"
                          onClick={(e) => handleDurchfuehrungZurueckweisen(p.row.id)}
                        >
                          <Icon fontSize="small">clear</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ),
                flex: 2,
                sortable: false,
              },
            ]}
            rows={anlaesse.liste}
            loading={anlaesse.isLoading}
            // SORTING
            sortingMode="server"
            onSortModelChange={(newSortModel) => {
              if (newSortModel.length > 0) {
                // resette auf erste page und loese neuen request mit sortierung aus
                handleSetPage(1);
                setSortModel({ field: newSortModel[0].field, order: newSortModel[0].sort.toLocaleUpperCase() });
              }
            }}
            // PAGINATION
            paginationMode="server"
            pageSize={PAGINATION_ITEMS_PER_PAGE}
            rowsPerPageOptions={[]}
            rowCount={anlaesse.total ?? 0}
            page={filter.page - 1}
            onPageChange={newPage => handleSetPage(newPage + 1)}
          />
        </div>
      </Paper>
      <AnlassEditDialog
        anlassDurchfuehrung={anlassDurchfuehrungInBearbeitung}
        onDialogClose={(data) => {
          if (data) {
            handleDurchfuehrungSpeichern(data);
          }
          setAnlassDurchfuehrungInBearbeitung(null);
        }}
        title={`${t('anlass')} ${(Object.keys(anlassDurchfuehrungInBearbeitung ?? {}).length > 0 ? t('edit') : t('add')).toLocaleLowerCase()}`}
      />
      <ConfirmationDialog
        key="confirm-all-dialog"
        open={showConfirmGenehmigenAll}
        options={{ title: t('alle_genehmigen'), description: t('alle_anlaesse_genehmigen_text') }}
        onClose={() => setShowConfirmGenehmigenAll(false)}
        onCancel={() => setShowConfirmGenehmigenAll(false)}
        onConfirm={handleDurchfuehrungAlleGenehmigen}
      />
    </>
  );
}

export default Anlaesse;
