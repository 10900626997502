import React from 'react';
import { LinearProgress } from '@mui/material';

function Spinner() {
  return (
    <center style={{ flex: 1 }}>
      <LinearProgress />
    </center>
  );
}

export default Spinner;
