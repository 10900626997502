document.addEventListener('turbo:load', function() {
  if ($('#auslandstart_bewilligung_variante').length > 0) {
    updateVariantePreis();
  }

  $('.auslandstart-beantragen a').on('click', function(event) {
    return $('#auslandstart-modal').modal('show');
  });
  $('#auslandstart_bewilligung_variante').on('change', function(event) {
    updateVariantePreis();
    if (['turnier_bewilligung', 'turnier_bewilligung_mit_resultaterfassung'].indexOf($('#auslandstart_bewilligung_variante').val()) > -1) {
      $('.row.veranstaltung').removeClass('hidden');
      $('.row.veranstaltung_von_bis').removeClass('hidden');
      return $('row.jahr').addClass('hidden');
    } else {
      $('.row.veranstaltung').addClass('hidden');
      $('.row.veranstaltung_von_bis').addClass('hidden');
      return $('.row.jahr').removeClass('hidden');
    }
  });
  $('.feld-zelle input, .feld-zelle select').on('change', function(event) {
    return $(event.target).closest('.row').find('.fehler-zelle').html('');
  });

  return $('#auslandstart_bewilligung_veranstaltung_von').on('change', function(event) {
    return $('#auslandstart_bewilligung_veranstaltung_bis').val($('#auslandstart_bewilligung_veranstaltung_von').val());
  });
});

function updateVariantePreis() {
  var variante = $('#auslandstart_bewilligung_variante').val()
  $('.variante-preis').hide();
  $('.variante-preis.' + variante).show();
}
