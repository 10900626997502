document.addEventListener('turbo:load', function() {
  let zugriffGespeichert = false;

  $('.dossier-container .header').click(function(){
    var container = $(this).parent();
    container.toggleClass('aktiv');
    if (zugriffGespeichert === false) {
      $.get(container.data('zugriffUrl'));
      zugriffGespeichert = true;
    }
  });

  $('.toggle-dossier-filter').click(function(e){
    e.preventDefault();
    $('.dossier-wrapper').toggleClass('filter-aktiv');
    $(this).toggleClass('btn-inverse');
  });

  $('.toggle-vergangene-termine').click(function(e){
    e.preventDefault();
    $('.dms-agenda').addClass('vergangene-sichtbar');
    $(this).hide();
  });
});
