import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableHead,
  IconButton,
  Paper,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';

import './index.scss';
import { t } from '../../../common/i18n/translate';
import { downloadDokumente, starterkarteDrucken } from '../../../common/modules/api';
import { formatDate, objIsEmpty } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';

function Dokumente(props) {
  const [dokumente, setDokumente] = useState({});

  useEffect(() => {
    downloadDokumente(props.veranstaltung.id, (data) => {
      setDokumente(data.dokumente);
    });
  }, []);

  const dokumentenTabelle = tabellenDokumente => (
    <Table size="small" className="table">
      <TableHead>
        <TableRow>
          <TableCell>{t('titel')}</TableCell>
          <TableCell>{t('erstellt_am')}</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          tabellenDokumente.map(dokument => (
            <TableRow key={dokument.url}>
              <TableCell>
                {dokument.titel}
              </TableCell>
              <TableCell classes={{ root: 'datum-zelle' }}>
                {dokument.created_at ? formatDate(new Date(dokument.created_at)) : '-'}
              </TableCell>
              <TableCell classes={{ root: 'download-zelle' }}>
                {dokument.url && (
                <IconButton href={dokument.url}>
                  <GetAppIcon />
                </IconButton>
                )}
                {dokument.create_url && (
                <IconButton onClick={() => starterkarteDrucken(dokument.create_url)}>
                  <PrintIcon />
                </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );

  if (objIsEmpty(dokumente)) {
    return <Spinner />;
  }

  return (
    <Paper className="veranstaltungs-dokumente padding-1">
      {dokumente?.veranstaltung?.length > 0 && (
        <>
          <Typography variant="h6">{t('veranstaltungs_dokumente')}</Typography>
          {dokumentenTabelle(dokumente.veranstaltung)}
          {dokumente?.software?.length > 0 && (
          <>
            <br />
            <br />
          </>
          )}
        </>
      )}
      {dokumente?.software?.length > 0 && (
        <>
          <Typography variant="h6">{t('software_dokumente')}</Typography>
          {dokumentenTabelle(dokumente.software)}
        </>
      )}
      {(!dokumente.veranstaltung || !dokumente.veranstaltung.length) && (!dokumente.software || !dokumente.software.length) && (
        <Typography variant="h6">{t('keine_dokumente')}</Typography>
      )}
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
});

export default connect(mapStateToProps, null)(Dokumente);
