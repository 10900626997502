import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
} from '@mui/material';

import { t, getLanguage } from '../../common/i18n/translate';
import { MultiSelectField, DateField } from '../../common/Formular';
import { objIsEmpty } from '../../common/modules/helpers';
import { setFilter } from '../redux/modules/veranstaltungenFilter';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../common/constants';

const MIN_DATE = new Date(-8640000000000000);
const MAX_DATE = new Date(8640000000000000);

function VeranstaltungenFilter({ filter, stammdaten, setFilter: handleSetFilter }) {
  const stammdatenReady = useMemo(() => !objIsEmpty(stammdaten), [stammdaten]);

  if (!stammdatenReady) {
    return null;
  }

  return (
    <Paper sx={{ mt: 2 }} className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.FIRST + 1}>
      <Typography sx={{ mb: 1 }} fontWeight="bold">{t('filter')}</Typography>
      <div id="veranstaltungen-filter-form" className="flex-row">
        <FormControlLabel
          // damit gleich aussieht wie die anderen OutlinedInput select felder
          sx={{ whiteSpace: 'nowrap', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', color: 'rgba(0, 0, 0, 0.6)', marginLeft: 0, marginRight: '16px', paddingLeft: '1em' }}
          labelPlacement="start"
          label={t('nur_eigene')}
          control={(
            <Checkbox
              size="small"
              checked={filter.filter.nur_eigene}
              onChange={(e, newValue) => handleSetFilter({
                ...filter.filter,
                nur_eigene: newValue,
                regionalverband: newValue ? [] : filter.filter.regionalverband,
                rv_anlaesse: filter.filter.rv_anlaesse.filter(rvId => (newValue ? stammdaten.current_user_person.regionalverbaende_zur_auswahl : filter.filter.regionalverband).includes((stammdaten.rv_anlaesse || []).find(rva => rva.id === rvId)?.regionalverband_id)),
              })}
            />
          )}
        />
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.sportjahr}
          options={filter.filterWerte.sportjahr}
          optionLabel="title"
          optionValue="value"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, sportjahr: newValue })}
          label={t('sportjahr')}
          labelVariant="outlined"
          variant="outlined"
        />
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.disziplin}
          options={(stammdaten.disziplinen || []).map(d => ({ ...d, title: d.code }))}
          optionLabel="label"
          optionValue="code"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, disziplin: newValue })}
          label={t('disziplin')}
          labelVariant="outlined"
          variant="outlined"
        />
        {!filter.filter.nur_eigene && (
          <MultiSelectField
            sx={{ mr: 2 }}
            value={filter.filter.regionalverband}
            options={(stammdaten.regionalverbaende || []).map(r => (
              {
                ...r,
                title: r.code,
              }
            )).sort((a, b) => (a.title < b.title ? -1 : a.title > b.title))}
            optionLabel="label"
            optionValue="id"
            handleChange={(_, newValue) => handleSetFilter({
              ...filter.filter,
              regionalverband: newValue,
              rv_anlaesse: filter.filter.rv_anlaesse.filter(rvId => newValue.includes((stammdaten.rv_anlaesse || []).find(rva => rva.id === rvId)?.regionalverband_id)),
            })}
            label={t('regionalverband')}
            labelVariant="outlined"
            variant="outlined"
          />
        )}
        <FormControl fullWidth size="small">
          <TextField
              sx={{ mr: 2 }}
              // className="mt-1"
              value={filter.filter.ortSucheText}
              onChange={e => handleSetFilter({ ...filter.filter, ortSucheText: e.target.value })}
              label={t('ort')}
              // fullWidth
              size="small"
              variant="outlined"
              InputLabelProps={{
                variant: 'outlined',
              }}
          />
        </FormControl>
        {(filter.filter.regionalverband.length > 0 || filter.filter.nur_eigene) && (
          <MultiSelectField
            sx={{ mr: 2 }}
            value={filter.filter.rv_anlaesse}
            options={(stammdaten.rv_anlaesse || []).filter(rva => (filter.filter.nur_eigene ? stammdaten.current_user_person.regionalverbaende_zur_auswahl : filter.filter.regionalverband).includes(rva.regionalverband_id))}
            optionLabel="label"
            optionValue="id"
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, rv_anlaesse: newValue })}
            label={t('anlass')}
            labelVariant="outlined"
            variant="outlined"
          />
        )}
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.status}
          options={(stammdaten.veranstaltung_status || [])}
          optionLabel="label"
          optionValue="code"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, status: newValue })}
          label={t('status')}
          labelVariant="outlined"
          variant="outlined"
        />
        <FormControl fullWidth size="small">
          <TextField
            sx={{ mr: 2, flex: 2 }}
            // className="mt-1"
            value={filter.filter.sektionRayon}
            onChange={e => handleSetFilter({ ...filter.filter, sektionRayon: e.target.value })}
            label={t('sektion_rayon')}
            // fullWidth
            size="small"
            variant="outlined"
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <DateField
            size="small"
            sx={{ mr: 2 }}
            value={filter.filter.von}
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, von: new Date(newValue) })}
            maxDate={filter.filter.bis ?? MAX_DATE}
            label={t('von')}
            inputVariant="outlined"
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <DateField
            size="small"
            value={filter.filter.bis}
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, bis: new Date(newValue) })}
            minDate={filter.filter.von ?? MIN_DATE}
            label={t('bis')}
            inputVariant="outlined"
          />
        </FormControl>
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  filter: state.veranstaltungenFilter,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  setFilter: (filter) => {
    dispatch(setFilter(filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VeranstaltungenFilter);
