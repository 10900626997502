import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Typography,
  FormControl,
  Paper,
} from '@mui/material';

import { t, getLanguage } from '../../common/i18n/translate';
import { MultiSelectField, DateField } from '../../common/Formular';
import { objIsEmpty } from '../../common/modules/helpers';
import { setFilter } from '../redux/modules/anlaesseFilter';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../common/constants';

const MIN_DATE = new Date(-8640000000000000);
const MAX_DATE = new Date(8640000000000000);

function AnlaesseFilter({ filter, stammdaten, setFilter: handleSetFilter }) {
  const stammdatenReady = useMemo(() => !objIsEmpty(stammdaten), [stammdaten]);

  if (!stammdatenReady) {
    return null;
  }

  return (
    <Paper sx={{ mt: 2 }} className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.FIRST + 1}>
      <Typography sx={{ mb: 1 }} fontWeight="bold">{t('filter')}</Typography>
      <div id="anlaesse-filter-form" className="flex-row">
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.sportjahr}
          options={filter.filterWerte.sportjahr}
          optionLabel="title"
          optionValue="value"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, sportjahr: newValue })}
          label={t('sportjahr')}
          labelVariant="outlined"
          variant="outlined"
        />
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.rv_anlaesse}
          options={(stammdaten.rv_anlaesse || []).filter(rva => stammdaten.current_user_person.regionalverbaende_zur_auswahl.includes(rva.regionalverband_id))}
          optionLabel="label"
          optionValue="id"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, rv_anlaesse: newValue })}
          label={t('anlass')}
          labelVariant="outlined"
          variant="outlined"
        />
        <FormControl fullWidth size="small">
          <TextField
            sx={{ mr: 2 }}
            // className="mt-1"
            value={filter.filter.ortSucheText}
            onChange={e => handleSetFilter({ ...filter.filter, ortSucheText: e.target.value })}
            label={t('ort')}
            // fullWidth
            size="small"
            variant="outlined"
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
        </FormControl>
        <MultiSelectField
          sx={{ mr: 2 }}
          value={filter.filter.status}
          options={(stammdaten.anlass_durchfuehrung_status || [])}
          optionLabel="label"
          optionValue="code"
          handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, status: newValue })}
          label={t('status')}
          labelVariant="outlined"
          variant="outlined"
        />
        <FormControl fullWidth size="small">
          <TextField
            sx={{ mr: 2, flex: 2 }}
            // className="mt-1"
            value={filter.filter.sektionRayon}
            onChange={e => handleSetFilter({ ...filter.filter, sektionRayon: e.target.value })}
            label={t('sektion_rayon')}
            // fullWidth
            size="small"
            variant="outlined"
            InputLabelProps={{
              variant: 'outlined',
            }}
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <DateField
            size="small"
            sx={{ mr: 2 }}
            value={filter.filter.von}
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, von: new Date(newValue) })}
            maxDate={filter.filter.bis ?? MAX_DATE}
            label={t('von')}
            inputVariant="outlined"
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <DateField
            size="small"
            value={filter.filter.bis}
            handleChange={(_, newValue) => handleSetFilter({ ...filter.filter, bis: new Date(newValue) })}
            minDate={filter.filter.von ?? MIN_DATE}
            label={t('bis')}
            inputVariant="outlined"
          />
        </FormControl>
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  filter: state.anlaesseFilter,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  setFilter: (filter) => {
    dispatch(setFilter(filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnlaesseFilter);
