const SET_DIRTY = 'SET_DIRTY';

export const setDirty = isDirty => ({ type: SET_DIRTY, isDirty });

export const formulareReducer = (state, action) => {
  switch (action.type) {
    case SET_DIRTY:
      if (state.isDirty === action.isDirty) {
        return state;
      }
      return {
        ...state,
        isDirty: action.isDirty,
      };

    default:
      return state || {};
  }
};
