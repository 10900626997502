import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="partials--admin-einstellungen"
export default class extends Controller {
  impersonieren({ detail: { content } }) {
    let { data, cornerPoints } = content;
    data = this.parseData(data);

    if (data) {
      const { auth_token, env } = data;

      if (env !== this.currentEnvironment) {
        this.stopScanning();
        alert(`Ungültig! Gescannter QR-Code stammt von ${env} und nicht von ${this.currentEnvironment}`);
      } else if (auth_token) {
        Turbo.visit(`/?auth_token=${auth_token}`);
        this.stopScanning();
      }
    }
  }

  parseData(data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Invalid JSON:', data);
      return null;
    }
  }

  stopScanning() {
    window.dispatchEvent(new Event('stop-scanning'));
  }

  get currentEnvironment() {
    return document.getElementById("environment").value;
  }
}
