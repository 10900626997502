document.addEventListener('turbo:load', function() {
  $('.update-anforderungen:not(.stay-disabled), .anforderungen-to-update').removeAttr('disabled');
  anforderungenOptionsAktualisieren($('.update-anforderungen').val());

  $('.update-anforderungen').change(function(){
    var selektion_id = $(this).val();
    anforderungenOptionsAktualisieren(selektion_id);
  });

  function anforderungenOptionsAktualisieren(id) {
    $('.anforderungen-to-update').each(function(i, element) {
      var radio = $(element);
      var parent = radio.parents('.radio:first');
      if (parseInt(radio.data('sichtungstag')) === parseInt(id)) {
        parent.removeClass('radio-hidden');
      } else {
        parent.addClass('radio-hidden');
      }
    });
  }
});
