export const it = {
  datum: 'Data',
  nennschluss: 'Termine d‘iscrizione',
  nennphase_start: 'Fase di iscrizione a partire dal',
  nennphase_ab_21_uhr: '21:00',
  veranstalter: 'Organizzatore',
  nummer_nr: 'No.',
  kategorie: 'Categoria',
  wertung_text: 'Tabella',
  nenngeld_ng: 'Tassa',
  preise: 'Premi',
  bemerkungen: 'Osservazioni',
  details: 'Dettagli',
  lizenzierung_gruppe_text: 'Licenze',
  dressur_teilnehmer: 'Partecipanti',
  dressur_rechteck: 'Rettangolo',
  pony: 'Pony',
  fuer_pferde: 'Per i cavalli',
  fuer_ponies: 'Per Pony',

  concours_complet_dressur: 'Dressage',
  concours_complet_springen: 'Salta',
  concours_complet_cross: 'Cross',

  endurance_distanz: 'Distanza',
  endurance_teilnehmer: 'Partecipanti',
  endurance_schlaufen: 'Anelli/km',

  pferde_angeben: 'Tutti i cavalli la cui vaccinazione non era in regola devono essere indicati.',
  pferde_doppelt: 'Lo stesso cavallo è stato indicato due volte, il che non è consentito.',

  ons_status_nachnennphase_geplant: 'Fase ulteriore d’iscrizione pianificata',
  nachnennphase_start: 'Inizio della fase ulteriore d’iscrizione',
};
