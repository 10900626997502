import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Table, TableRow, TableCell, TableHead, TableBody, FormControlLabel, Switch, Paper } from '@mui/material';

import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { objIsEmpty } from '../../../common/modules/helpers';
import { updateBeteiligung, deleteBeteiligung, updateVeranstaltung } from '../../../common/modules/api';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';

import './index.scss';

function Beteiligungen(props) {
  const [selected, setSelected] = useState({});
  const [rvSichtbar, setRvSichtbar] = useState(props.veranstaltung.forms.veranstaltungsdaten.veranstalter_publizieren);

  const formularDisabled = props.veranstaltung.id && !(
    props.veranstaltung.status === 'in_erfassung'
    || props.veranstaltung.status === 'zur_voranzeige'
    || props.veranstaltung.status === 'in_ausschreibung'
  );

  const handleSubmit = (data) => {
    data.person_id = (data.person || {}).id;
    updateBeteiligung(props.veranstaltung.id, data);
  };

  const cancelOrBack = selected.id
    ? () => setSelected({})
    : () => (window.location.hash = '#/');

  const handleDelete = selected.id ? () => deleteBeteiligung(props.veranstaltung.id, selected.id) : null;

  const formFields = [
    { feldTyp: 'hidden', name: 'id' },
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        { feldTyp: 'autocomplete_person', name: 'person', required: true },
        {
          feldTyp: 'autocomplete',
          name: 'funktion_id',
          label: t('funktion'),
          required: true,
          options: props.stammdaten.veranstaltungsfunktionen,
          optionValue: 'id',
          optionLabel: 'label',
        },
        { feldTyp: 'text', name: 'nummer', label: t('telefonnummer') },
      ],
    },
  ];

  const binIchOASVerantwortlicher = (beteiligung) => {
    const currentPerson = props.stammdaten.current_user_person;
    if (currentPerson) {
      return beteiligung.funktion_id === 21 && beteiligung.person_id === currentPerson.id;
    }
  };

  const handleSelect = (beteiligung) => {
    if (!binIchOASVerantwortlicher(beteiligung)) {
      setSelected({
        id: beteiligung.id,
        person: {
          id: beteiligung.person_id,
          name: beteiligung.person_name,
        },
        funktion_id: beteiligung.funktion_id,
        nummer: beteiligung.nummer,
      });
    }
  };

  return (
    <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
      <Table size="small" className="table beteiligungen-tabelle">
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell>{t('funktion')}</TableCell>
            <TableCell>{t('telefonnummer')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{props.veranstaltung.forms.veranstaltungsdaten.veranstalter_name}</TableCell>
            <TableCell>{t('veranstalter')}</TableCell>
            <TableCell />
            <TableCell classes={{ root: 'sichtbar-toggle' }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={rvSichtbar}
                    disabled={formularDisabled}
                    onChange={(e, newValue) => {
                      setRvSichtbar(newValue);
                      updateVeranstaltung({ veranstalter_publizieren: newValue }, props.veranstaltung.id);
                    }}
                    color="primary"
                  />
                )}
                label={t('veranstalter_anzeigen')}
              />
            </TableCell>
          </TableRow>
          {props.beteiligungen.map(beteiligung => (
            <TableRow
              key={beteiligung.id}
              onClick={() => handleSelect(beteiligung)}
              hover
              className={`link ${binIchOASVerantwortlicher(beteiligung) ? 'disabled' : ''}`}
            >
              <TableCell>
                {beteiligung.person_name}
                ,
                {beteiligung.person_ort}
                {' '}
                {beteiligung.person_kanton}
              </TableCell>
              <TableCell>{beteiligung.funktion_bezeichnung}</TableCell>
              <TableCell>{beteiligung.nummer}</TableCell>
              <TableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="mt-2">
        <Typography variant="h6">
          {objIsEmpty(selected) ? t('neue_beteiligung') : t('edit')}
        </Typography>
        <Formular
          disabled={formularDisabled}
          formId="beteiligungen"
          fields={formFields}
          defaultValues={selected}
          handleSubmit={handleSubmit}
          handleCancel={cancelOrBack}
          handleDelete={handleDelete}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
  beteiligungen: state.veranstaltungen.current.forms.beteiligungen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Beteiligungen);
