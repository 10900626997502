import React from 'react';
import { ListItem, ListItemText, TextField } from '@mui/material';

import { fromHtml, toHtml } from '../../common/modules/helpers';
import { t_var } from '../../common/i18n/translate';
import { useAppSelector } from '../redux/hooks';

function TextFrage({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);
  const config = frage.config || {};
  const text = antwortDetails.details || '';

  return (
    <ListItem divider style={{ alignItems: 'start' }} className="textFrage">
      <ListItemText primary={t_var(frage, 'titel')} secondary={t_var(frage, 'untertitel')} />
      <div style={{ flex: 1 }}>
        <TextField
          disabled={rapport.status !== 'offen'}
          value={fromHtml(text)}
          onChange={event => updateAntwort(null, event.target.value ? toHtml(event.target.value) : null)}
          style={{ width: '100%' }}
          {...config.inputAttributes}
        />
      </div>
    </ListItem>
  );
}

export default TextFrage;
