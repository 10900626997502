const NENNUNGEN_LADEN = 'NENNUNGEN_LADEN';

export const nennungenLaden = nennungen => ({ type: NENNUNGEN_LADEN, nennungen });

export const nennungenReducer = (state, action) => {
  switch (action.type) {
    case NENNUNGEN_LADEN:
      return {
        ...state,
        ...action.nennungen,
      };
    default:
      return state || {};
  }
};
