import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableFooter,
  Typography,
  Button,
  Icon,
  Popover,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import { formatDate, currency } from '../../../common/modules/helpers';
import { t } from '../../../common/i18n/translate';
import { nennungZurueckweisen } from '../../../common/modules/api';

function NennungenTabelle(props) {
  const [popoverAnker, setPopoverAnker] = useState({});
  const [zurueckweisungsDaten, setZurueckweisungsDaten] = useState({});

  const popoverAnzeigen = (event, popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = event.target;
    setPopoverAnker(ankerListe);
  };

  const popoverSchliessen = (popoverId) => {
    const ankerListe = {
      ...popoverAnker,
    };
    ankerListe[popoverId] = null;
    setPopoverAnker(ankerListe);
  };

  const zurueckweisenDialog = (nennung) => {
    let zurueckweisenOptionen = null;
    const popoverId = `nennung-${nennung.id}-zurueckweisen`;

    if (nennung.falschmeldung_moeglich && nennung.zurueckweisen_moeglich) {
      zurueckweisenOptionen = (
        <RadioGroup
          name="zurueckweisung_option"
          value={(zurueckweisungsDaten[nennung.id] || {}).zurueckweisung || 'falschnennung'}
          onChange={(e) => {
            const daten = {
              ...zurueckweisungsDaten,
            };
            daten[nennung.id.toString()] = {
              ...(daten[nennung.id.toString()] || {}),
              zurueckweisung: e.target.value,
            };
            setZurueckweisungsDaten(daten);
          }}
        >
          <FormControlLabel control={<Radio />} label={t('zurueckweisen_falschnennung')} value="falschnennung" classes={{ root: 'zurueckweisen-option' }} />
          <FormControlLabel control={<Radio />} label={t('zurueckweisen_zurueckgewiesen')} value="zurueckgewiesen" classes={{ root: 'zurueckweisen-option' }} />
        </RadioGroup>
      );
    } else if (nennung.falschmeldung_moeglich) {
      zurueckweisenOptionen = (
        <Typography>
          {t('zurueckweisen_falschnennung')}
        </Typography>
      );
    } else if (nennung.zurueckweisen_moeglich) {
      zurueckweisenOptionen = (
        <Typography>
          {t('zurueckweisen_zurueckgewiesen')}
        </Typography>
      );
    } else {
      // dürfte nicht passieren, wir kommen so gar nicht in diese Methode
    }
    const popoverInhalt = (
      <div className="zurueckweisen-popup">
        <Typography>
          <strong>{t('nennung_zurueckweisen')}</strong>
        </Typography>
        <div>&nbsp;</div>
        {zurueckweisenOptionen}
        <div>&nbsp;</div>
        <TextField
          label={t('zurueckweisen_grund')}
          value={(zurueckweisungsDaten[nennung.id] || {}).grund || ''}
          variant="outlined"
          onChange={(e) => {
            const daten = {
              ...zurueckweisungsDaten,
            };
            daten[nennung.id.toString()] = {
              ...(daten[nennung.id.toString()] || {}),
              grund: e.target.value,
            };
            setZurueckweisungsDaten(daten);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          fullWidth
          InputLabelProps={{
            variant: 'outlined',
          }}
        />
        <div>&nbsp;</div>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={(e) => {
              const neuerStatus = (zurueckweisungsDaten[nennung.id.toString()] || {});
              nennungZurueckweisen(
                props.veranstaltung.id,
                nennung.pruefung_id,
                nennung.id,
                (zurueckweisungsDaten[nennung.id] || {}).zurueckweisung || 'falschnennung',
                (zurueckweisungsDaten[nennung.id] || {}).grund,
              );
            }}
            variant="contained"
            color="primary"
          >
            {t('zurueckweisen')}
          </Button>
        &nbsp;
          <Button
            onClick={(e) => {
              popoverSchliessen(popoverId);
            }}
            variant="contained"
          >
            {t('cancel')}
          </Button>
        </div>
      </div>
    );

    return (
      <>
        <Button
          variant="contained"
          size="small"
          classes={{ root: 'nennung-zurueckweisen-button' }}
          onClick={(event) => {
            popoverAnzeigen(event, popoverId);
          }}
        >
          {t('zurueckweisen')}
        </Button>
        <Popover
          id={popoverId}
          open={popoverAnker[popoverId] !== null && popoverAnker[popoverId] !== undefined}
          anchorEl={popoverAnker[popoverId]}
          onClose={() => popoverSchliessen(popoverId)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {popoverInhalt}
        </Popover>
      </>
    );
  };

  return (
    <Table size="small" className="table nennungen-tabelle">
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: 'wrappable' }}>{t('lizenz_nr')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('reiter_fahrer')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('pferd_e')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('telefon')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('status')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('bemerkungen')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }}>{t('erstellt_modifiziert')}</TableCell>
          <TableCell classes={{ root: 'wrappable' }} align="right">{t('ng')}</TableCell>
          <TableCell classes={{ root: 'mit-buttons' }} />
          <TableCell classes={{ root: 'mit-buttons' }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.nennungen.map((nennung) => {
            let changelogDisplay = '';
            if (nennung.changelog.length > 0) {
              // zeitstempel, aktion, parameter
              const replacePlaceholders = (string, parameter) => {
                const regex = /%{[^}]*}/g;
                const matches = string.match(regex);
                let newString = string;
                if (matches && matches.length) {
                  matches.forEach((replaceKey) => {
                    newString = newString.replace(replaceKey, parameter[replaceKey.substr(2, replaceKey.length - 3)]);
                  });
                }
                return newString;
              };
              const popoverInhalt = (
                <div className="changelog-popup">
                  <Typography>
                    <strong>{t('changelog')}</strong>
                  </Typography>
                  <Typography>
                    {nennung.reiter_name}
                    {' '}
                    /
                    {nennung.pferde.map(p => (
                      p.name
                    )).join(' / ')}
                  </Typography>
                  <br />
                  <Table size="small" className="table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {formatDate(new Date(nennung.created_at), 'dd.mm.YY hh:mm')}
                        </TableCell>
                        <TableCell>
                          <strong>{t('changelog_created')}</strong>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      {nennung.changelog.map(change => (
                        <TableRow key={change.zeitstempel}>
                          <TableCell>
                            {formatDate(new Date(change.zeitstempel), 'dd.mm.YY hh:mm')}
                          </TableCell>
                          <TableCell>
                            <strong>{t(`changelog_${change.aktion}`)}</strong>
                          </TableCell>
                          <TableCell>
                            {replacePlaceholders(t(`changelog_${change.aktion}_2`), change.parameter)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              );
              const popoverId = `nennung-${nennung.id}-changelog`;
              changelogDisplay = (
                <>
                  <Button onClick={(event) => {
                    popoverAnzeigen(event, popoverId);
                  }}
                  >
                    <Icon>info</Icon>
                  </Button>
                  <Popover
                    id={popoverId}
                    open={popoverAnker[popoverId] !== null && popoverAnker[popoverId] !== undefined}
                    anchorEl={popoverAnker[popoverId]}
                    onClose={() => popoverSchliessen(popoverId)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {popoverInhalt}
                  </Popover>
                </>
              );
            }
            return (
              <TableRow key={nennung.id} classes={{ root: `nennung-${nennung.id}${nennung.zurueckgewiesen ? ' zurueckgewiesen' : ''}` }}>
                <TableCell>{nennung.reiter_id}</TableCell>
                <TableCell>{nennung.reiter_name}</TableCell>
                <TableCell>
                  <table width="100%" className={nennung.pferde.length > 1 ? 'kleine-pferde' : ''}>
                    <tbody>
                      {nennung.pferde.map(p => (
                        <tr key={p.id}>
                          <td style={{ minWidth: '50px', width: '50px' }}>{p.id}</td>
                          <td>{p.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableCell>
                <TableCell>{nennung.telefon}</TableCell>
                <TableCell>{nennung.status}</TableCell>
                <TableCell classes={{ root: 'wrappable bemerkungen' }}>{nennung.bemerkungen}</TableCell>
                <TableCell>
                  {formatDate(new Date(nennung.created_at), 'dd.mm.YY hh:mm')}
                </TableCell>
                <TableCell align="right">
                  {currency(nennung.bezahltes_nenngeld)}
                  {' '}
                </TableCell>
                <TableCell classes={{ root: 'mit-buttons' }}>
                  {changelogDisplay}
                </TableCell>
                <TableCell classes={{ root: 'mit-buttons' }}>
                  {(nennung.falschmeldung_moeglich || nennung.zurueckweisen_moeglich) ? zurueckweisenDialog(nennung) : null}
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan="7">
            {props.totalLabel}
            :
            {' '}
            {props.nennungen.length}
          </TableCell>
          <TableCell align="right">
            {currency(
              props.nennungen.map(n => n.bezahltes_nenngeld).reduce((a, b) => a + b, 0),
            )}
          </TableCell>
          <TableCell colSpan="2" />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
});

export default connect(mapStateToProps, null)(NennungenTabelle);
