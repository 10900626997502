import React from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Chip } from '@mui/material';
import { Box } from '@mui/system';

import { dateRange, formatDate } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';
import { KontaktOkPraesident, KontaktOkSekretaer, KontaktOasVerantwortlicher } from '../../../common/PersonKontakt';
import { t, getLanguage } from '../../../common/i18n/translate';

import '../../../common/styles/VeranstaltungsdatenTabelle.scss';

function VeranstaltungsdatenTabelle(props) {
  if (!props.stammdaten) {
    return <Spinner />;
  }

  const regionalverband = props.stammdaten.regionalverbaende.find(r => r.id === props.veranstaltung.forms.veranstaltungsdaten.regionalverband_id) || {};
  const typ = props.stammdaten.veranstaltung_typen.find(veranstaltung_typ => veranstaltung_typ.id === props.veranstaltung.forms.veranstaltungsdaten.typ_id) || {};
  const modus = props.stammdaten.modus.find(m => m.id === props.veranstaltung.forms.veranstaltungsdaten.modus_id) || {};
  const software = props.stammdaten.software.find(s => s.id === props.veranstaltung.forms.weitere_informationen.software_id) || {};

  return (
    <Paper className="veranstaltungsdaten padding-1">
      <table className="veranstaltungsdaten-tabelle">
        <tbody>
          <tr>
            <th><Typography>{t('nummer')}</Typography></th>
            <td><Typography>{props.veranstaltung.sequenz_nummer}</Typography></td>
          </tr>
          {props.veranstaltung.ist_eigene && (
            <tr>
              <th><Typography>{t('access_key')}</Typography></th>
              <td><Typography>{props.veranstaltung.access_key}</Typography></td>
            </tr>
          )}
          <tr>
            <th><Typography>{t('status')}</Typography></th>
            <td><Typography>{props.veranstaltung.status_label}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('datum')}</Typography></th>
            <td>
              <Typography>
                {dateRange(new Date(props.veranstaltung.forms.veranstaltungsdaten.von), new Date(props.veranstaltung.forms.veranstaltungsdaten.bis))}
              </Typography>
            </td>
          </tr>
          <tr>
            <th><Typography>{t('ort')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.ort}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('regionalverband')}</Typography></th>
            <td><Typography>{regionalverband.name}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('veranstalter')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.veranstalter_name}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('mitglied')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.veranstalter_ist_verbandsmitglied ? t('ja') : t('nein')}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('sprache')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.sprache}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('typ')}</Typography></th>
            <td><Typography>{typ[`bezeichnung_${getLanguage()}`]}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('modus')}</Typography></th>
            <td><Typography>{modus[`bezeichnung_${getLanguage()}`]}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('vorgesehene_pruefungen')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.vorgesehene_pruefungen}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('stilpruefungen')}</Typography></th>
            <td>
              {props.veranstaltung.forms.veranstaltungsdaten.hat_stilpruefungen ? (
                <Typography>{`B100: ${props.veranstaltung.forms.veranstaltungsdaten.anzahl_b100_stilpruefungen} / P95: ${props.veranstaltung.forms.veranstaltungsdaten.anzahl_p95_stilpruefungen}`}</Typography>
              ) : '-'}
            </td>
          </tr>
          <tr>
            <th><Typography>{t('anlaesse')}</Typography></th>
            <td>
              {props.veranstaltung.forms.veranstaltungsdaten.anlass_durchfuehrungen.length > 0
                ? props.veranstaltung.forms.veranstaltungsdaten.anlass_durchfuehrungen.map((rvad, i) => (
                  <Box key={rvad.id} sx={{ mt: i !== 0 ? 1 : 0 }} className="flex-row">
                    {rvad.tage.length > 0 && rvad.tage.map(durchfuehrungsTag => (
                      <Chip
                        key={durchfuehrungsTag}
                        sx={{ mr: 1 }}
                        label={formatDate(new Date(durchfuehrungsTag), 'dd.mm')}
                        variant="filled"
                        size="small"
                      />
                    ))}
                    <Typography>{rvad.bezeichnung}</Typography>
                  </Box>
                ))
                : '-'}
            </td>
          </tr>
          {props.veranstaltung.nennvariante === 'nennschluss' && (
            <>
              <tr>
                <th><Typography>{t('nennschluss')}</Typography></th>
                <td><Typography>{props.veranstaltung.nennschluss_definitiv ? formatDate(new Date(props.veranstaltung.nennschluss_definitiv)) : '-'}</Typography></td>
              </tr>
              <tr>
                <th><Typography>{t('nachnennphase_start')}</Typography></th>
                <td><Typography>{props.veranstaltung.nachnennphase_start ? formatDate(new Date(props.veranstaltung.nachnennphase_start)) : '-'}</Typography></td>
              </tr>
              <tr>
                <th><Typography>{t('nachnennphase_nenngeld_erhoehung')}</Typography></th>
                <td><Typography>{props.veranstaltung.nachnennphase_nenngeld_erhoehung}</Typography></td>
              </tr>
            </>
          )}
          {props.veranstaltung.nennvariante === 'nennphase' && (
            <tr>
              <th><Typography>{t('nennphase_start')}</Typography></th>
              <td><Typography>{props.veranstaltung.nennphase_start ? formatDate(new Date(props.veranstaltung.nennphase_start)) : '-'}</Typography></td>
            </tr>
          )}
          <tr>
            <th><Typography>{t('im_nennsystem_aktiviert')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.veranstaltungsdaten.im_nennsystem ? t('ja') : t('nein')}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('bietet_stallungen_an')}</Typography></th>
            <td><Typography>{props.veranstaltung.forms.stallungen.stallungsangebot_aktiv ? t('ja') : t('nein')}</Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('ok_praesident')}</Typography></th>
            <td><KontaktOkPraesident beteiligungen={props.veranstaltung.forms.beteiligungen} /></td>
          </tr>
          <tr>
            <th><Typography>{t('ok_sekretaer')}</Typography></th>
            <td><KontaktOkSekretaer beteiligungen={props.veranstaltung.forms.beteiligungen} /></td>
          </tr>
          <tr>
            <th><Typography>{t('oas_verantwortlicher')}</Typography></th>
            <td><KontaktOasVerantwortlicher beteiligungen={props.veranstaltung.forms.beteiligungen} /></td>
          </tr>
          <tr>
            <th><Typography>{t('website')}</Typography></th>
            <td><Typography><a href={props.veranstaltung.website}>{props.veranstaltung.website}</a></Typography></td>
          </tr>
          <tr>
            <th><Typography>{t('software')}</Typography></th>
            <td><Typography>{software.bezeichnung}</Typography></td>
          </tr>
        </tbody>
      </table>
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current || {},
  stammdaten: state.stammdaten[getLanguage()],
});

export default connect(mapStateToProps, null)(VeranstaltungsdatenTabelle);
