import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import type { Store } from 'redux';

import middleware from '../../common/redux/middleware';
import Session, { INITIAL_STATE as INITIAL_SESSION } from '../../common/redux/modules/Session';
import { veranstaltungenReducer } from './modules/veranstaltungen';
import { stammdatenReducer } from './modules/stammdaten';
import { filterReducer } from './modules/filter';
import { nennungenReducer } from './modules/nennungen';
import { formulareReducer } from './modules/formulare';

const reducers = combineReducers({
  session: Session,
  veranstaltungen: veranstaltungenReducer,
  stammdaten: stammdatenReducer,
  filter: filterReducer,
  nennungen: nennungenReducer,
  formulare: formulareReducer,
});

let store = configureStore({
  reducer: reducers,
  // preloadedState: initialStoreState,
  middleware,
});

export const initializeStore = (locale: string): Store => {
  const initialStoreState = {
    session: {
      ...INITIAL_SESSION,
      locale,
    },
  };

  store = configureStore({
    reducer: reducers,
    preloadedState: initialStoreState,
    middleware,
  });

  return store;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
