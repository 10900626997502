  import { Controller } from "@hotwired/stimulus"

  // Connects to data-controller="bild"
  export default class extends Controller {
    static targets = ["bildSelect", "bildPreview", "bildHidden", "loeschenButton"]
    
    connect() {
      if (this.hasLoeschenButtonTarget) {
        if (!this.bildPreviewTarget.src.includes("s3")) {
          this.loeschenButtonTarget.classList.add("hidden");
        }
      } 
    }

    setBild() {
      this.bildSelectTarget.click();
    }

    setBildPreview() {
      let bild = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.bildHiddenTarget.value = e.target.result;
        this.bildPreviewTarget.src = e.target.result;
      };
      reader.readAsDataURL(bild);
      if (this.hasLoeschenButtonTarget) {
        this.loeschenButtonTarget.classList.remove("hidden");
      }
    }
    
    entferneBild() {
      this.bildHiddenTarget.value = "";
      this.bildPreviewTarget.src = "";
      this.bildSelectTarget.value = ""
      this.loeschenButtonTarget.classList.add("hidden");
    }
  }
