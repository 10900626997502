import { apiService } from '../../../common/services';

const BENUTZER_ABFRAGEN = 'equis/selfservice/events/benutzer_abfragen';
const BENUTZER_ABFRAGEN_SUCCESS = 'equis/selfservice/events/benutzer_abfragen_success';
const BENUTZER_ABFRAGEN_FAIL = 'equis/selfservice/events/benutzer_abfragen_fail';

const BENUTZER_SPEICHERN = 'equis/selfservice/events/benutzer_speichern';
const BENUTZER_SPEICHERN_SUCCESS = 'equis/selfservice/events/benutzer_speichern_success';
const BENUTZER_SPEICHERN_FAIL = 'equis/selfservice/events/benutzer_speichern_fail';

const BENUTZER_ENTFERNEN = 'equis/selfservice/events/benutzer_entfernen';
const BENUTZER_ENTFERNEN_SUCCESS = 'equis/selfservice/events/benutzer_entfernen_success';
const BENUTZER_ENTFERNEN_FAIL = 'equis/selfservice/events/benutzer_entfernen_fail';

export const benutzerReducer = (state, action) => {
  switch (action.type) {
    case BENUTZER_ABFRAGEN:
    case BENUTZER_SPEICHERN:
    case BENUTZER_ENTFERNEN:
      return { ...state, isLoading: true };
    case BENUTZER_ABFRAGEN_SUCCESS:
      return {
        ...state,
        liste: action.loadedBenutzer,
        total: action.total,
        isLoading: false,
      };
    case BENUTZER_SPEICHERN_SUCCESS:
      return { ...state, isLoading: false };
    case BENUTZER_ENTFERNEN_SUCCESS:
      return { ...state, isLoading: false };
    case BENUTZER_ABFRAGEN_FAIL:
    case BENUTZER_SPEICHERN_FAIL:
    case BENUTZER_ENTFERNEN_FAIL:
      return { ...state, isLoading: false };
    default:
      return state || { liste: [], current: {}, total: 0, isLoading: true };
  }
};

export const benutzerAbfragen = (page, mitFilter) => async (dispatch) => {
  dispatch({ type: BENUTZER_ABFRAGEN });

  const response = await apiService.get('backend/events/benutzer');

  if (response?.data) {
    dispatch({
      type: BENUTZER_ABFRAGEN_SUCCESS,
      loadedBenutzer: response.data.benutzer,
      total: response.data.total,
    });
  } else {
    dispatch({ type: BENUTZER_ABFRAGEN_FAIL, errorMsg: response.data.messages });
  }
};

export const benutzerSpeichern = benutzer => async (dispatch) => {
  dispatch({ type: BENUTZER_SPEICHERN });
  let response;

  const payload = {
    benutzer: {
      id: benutzer?.id,
      person_id: benutzer.person_id,
    },
  };

  if (benutzer?.id) {
    response = await apiService.put(`backend/events/benutzer/${benutzer.id}`, payload);
  } else {
    response = await apiService.post('backend/events/benutzer', payload);
  }

  if (response?.data) {
    dispatch({
      type: BENUTZER_SPEICHERN_SUCCESS,
    });
  } else {
    dispatch({ type: BENUTZER_SPEICHERN_FAIL, errorMsg: response.data.messages });
  }
};

export const benutzerEntfernen = personId => async (dispatch) => {
  dispatch({ type: BENUTZER_ENTFERNEN });

  const response = await apiService.delete(`backend/events/benutzer/${personId}`);

  if (response?.data) {
    dispatch({
      type: BENUTZER_ENTFERNEN_SUCCESS,
    });
  } else {
    dispatch({ type: BENUTZER_ENTFERNEN_FAIL, errorMsg: response.data.messages });
  }
};
