document.addEventListener("turbo:load", () => {
  // Mit dem folgenden JS Code lassen sich Anchor Links wie folgt faken: /posts?fake_anchor=selfservice-post-8
  // Dies musste eingebaut werden, da Equis-Android und Equis-iOS bei den PushNotification Links keine Anchor Links unterstützen.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  if (params.has("fake_anchor")) {
    const anchor = params.get("fake_anchor");
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView();
    }
  }
});
