import React, { useState, useEffect, useCallback } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
} from '@mui/material';

import Teilfragen from '../Dialoge/Teilfragen';
import Frage from './Frage';
import { toHtml } from '../../common/modules/helpers';
import { getLanguage, t, t_var } from '../../common/i18n/translate';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { setPferdeAktionFuerJueryrapport, removePferdeAktionFromStore, uploadPferdeAktion } from '../redux/Juryrapport';

// SammelFrage ist eine Frage, die auf "JA" Teilfragen in einem Modal präsentiert
function SammelFrage({ frage, antwortDetails, updateAntwort, uploadAntwort }) {
  const { rapport, antworten, pferdAktionen } = useAppSelector(state => state.juryrapport);
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [teilantworten, setTeilantworten] = useState([]);
  const { antwort } = antwortDetails;

  const handleSetPferdeAktion = (...args) => setPferdeAktionFuerJueryrapport(...args)(dispatch);
  const handleRemovePferdeAktionFromStore = (...args) => removePferdeAktionFromStore(...args)(dispatch);
  const handleUploadPferdeAktion = (...args) => uploadPferdeAktion(...args)(dispatch);

  // Visualisiert die Antworten auf der Sammelfrage als Zusatzinfos in weiteren Zeilen
  const updateTeilantworten = useCallback((teilfragen) => {
    const antwortenVonTeilfragen = (teilfragen || []).map((teilfrage) => {
      const teilantwort = antworten.find(a => a.frage_id === teilfrage.id) ?? {};
      let { details } = teilantwort;
      const htmlFeld = teilfrage.typ === 'textfeld';
      if (details) {
        const classes = 'antwort';
        if (htmlFeld) details = toHtml(details || '');
        return `<div>${teilfrage[`titel_${ getLanguage()}`]}: <span className="${classes}">${details}</span></div>`;
      }
      return null;
    }).filter(teilfrage => teilfrage);
    setTeilantworten(antwortenVonTeilfragen);

    return antwortenVonTeilfragen.length > 0;
  }, [antworten]);

  useEffect(() => {
    updateTeilantworten(frage.children);
  }, [frage, antworten]);

  // Alle PferdeAktionen die im Store gespeichert sind an Server senden
  // Und die möglichen timestamp IDs mit richtigen ersetzen
  const allePferdeAktionenSpeichern = async () => {
    pferdAktionen.forEach(async (aktion) => {
      const savedPferdeAktion = await handleUploadPferdeAktion(rapport.id, aktion.pferd_aktion_id, 'impfproblem', aktion.pferd_id);
      handleRemovePferdeAktionFromStore(aktion.pferd_aktion_id);
      handleSetPferdeAktion(savedPferdeAktion.pferd_aktion_id, { pferd_id: aktion.pferd_id, pferd_name: aktion.pferd_name, pferd_label: aktion.pferd_label }, rapport.id);
    });
  };

  const impfproblemFrageIstGueltig = (impfproblemFrageIndex) => {
    const anzahlImpfprobleme = antworten.find(a => a.frage_id === frage.children[impfproblemFrageIndex].id)?.details ?? 0;
    const anzahlPferdeAktionen = pferdAktionen.length;
    if (anzahlImpfprobleme !== anzahlPferdeAktionen) {
      return t('pferde_angeben');
    }

    let doppelteGefunden = false;
    pferdAktionen.forEach((aktion, i) => {
      const doppelterIndex = pferdAktionen.findIndex(a => a.pferd_id === aktion.pferd_id && a.pferd_aktion_id !== aktion.pferd_aktion_id);
      if (doppelterIndex !== -1) {
        doppelteGefunden = true;
      }
    });

    if (doppelteGefunden) {
      return t('pferde_doppelt');
    }

    return true;
  };

  const saveTeilfragen = useCallback(async () => {
    if (document.getElementById('dialog-teilfragen-form').reportValidity()) {
      // Wenn die "Impfproblem-Frage" ausgefüllt wurde
      // Auf Gültigkeit prüfen und PferdeAktionen speichern
      const impfproblemFrageIndex = frage.children.findIndex(f => f.typ === 'impfproblem');
      if (impfproblemFrageIndex !== -1) {
        const gueltigOderFehlerMeldung = impfproblemFrageIstGueltig(impfproblemFrageIndex);
        if (gueltigOderFehlerMeldung === true) {
          await allePferdeAktionenSpeichern();
        } else {
          alert(gueltigOderFehlerMeldung);
          return;
        }
      }

      setShowDialog(false);
      const neueAntwort = updateTeilantworten(frage.children);
      updateAntwort(neueAntwort);
      // Uploade die Teilantworten
      frage.children.forEach((teilfrage) => {
        const teilantwort = antworten.find(a => a.frage_id === teilfrage.id) ?? {};
        uploadAntwort(rapport.id, teilfrage.id, teilantwort.antwort, teilantwort.details);
      });
    }
  }, [frage, antworten, rapport, pferdAktionen]);

  return (
    <ListItem divider className="sammelFrage">
      <Teilfragen
        title={t_var(frage, 'titel')}
        showDialog={showDialog}
        onClose={() => setShowDialog(false)}
        onSave={saveTeilfragen}
        inputsJsx={(
          <>{frage.children.map(teilfrage => <Frage key={teilfrage.id} frage={teilfrage} istTeilfrage />)}</>
        )}
      />

      <ListItemText
        primary={t_var(frage, 'titel')}
        secondary={(
          <>
            <Typography component="span" variant="body2">
              {t_var(frage, 'untertitel')}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              dangerouslySetInnerHTML={{ __html: teilantworten.join(' ') }}
            />
          </>
        )}
      />
      <ListItemSecondaryAction>
        <Button
          variant={antwort === true ? 'contained' : null}
          color={antwort === true ? 'primary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => setShowDialog(true)}
        >
          {t('ja')}
        </Button>
        <Button
          variant={antwort === false ? 'contained' : null}
          color={antwort === false ? 'secondary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => updateAntwort(false)}
        >
          {t('nein')}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default SammelFrage;
