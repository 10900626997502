
document.addEventListener("turbo:load", function() {
  updateOtherField($('input#spenden'));

  $('input#auszahlen').change(function(){
    updateOtherField($(this));
  });

  $('input#spenden').change(function(){
    updateOtherField($(this));
  });

  $('select#kontoart').change(function(){
    checkKontoDaten();
  });

  $('.show-about-author').click(function(e){
    e.preventDefault();
    $('.about-author').slideToggle('slow');
  });
  $('.hide-about-author').click(function(e){
    e.preventDefault();
    $('.about-author').slideUp();
  });

  $('#ons_konto_form').on('submit', function(){
    return validateKontoForm();
  });

  var c=0;a=$('.bubble');a.click(function(){c++;if(c>5){c=0;a.toggleClass('x');}});
});


function checkKontoDaten() {
  if ($('input#auszahlen').val() > 0) {
    $('fieldset.kontodaten').show();
  } else {
    $('fieldset.kontodaten').hide();
  }

  if ($('select#kontoart').val() == 'post') {
    $('.bank-field').hide();
  } else {
    $('.bank-field').show();
  }
}

function validateKontoForm() {
  $('.field.error').removeClass('error');
  var errors = [];
  var required = ['spenden', 'auszahlen', 'vorname', 'name',
  'adresse', 'plz', 'ort', 'email'];

  if ($('#auszahlen').val() > 0) {
    required.push('kontoart');
    required.push('iban');
  }
  if ($('#spenden').val() > 0) {
    required.push('keine_erwaehnung');
  }



  if ($('#kontoart').val() == 'bank') {
    required.push('bank_name');
    required.push('bank_plz');
    required.push('bank_ort');
  }

  required.forEach(function(fieldId) {
    var field = $('#' + fieldId)
    var parent = field.parent();
    if (field.val().length == 0) {
      parent.addClass('error');
      parent.find('.error-msg').html(get_translation('blank'));
      errors.push(fieldId);
    } else {
      parent.removeClass('error');
    }
  });

  var telefonLeer = $('#telefon').val().length == 0;
  var mobileLeer = $('#mobile').val().length == 0;
  if (telefonLeer && mobileLeer) {
    var parentTelefon = $('#telefon').parent();
    var parentMobile = $('#mobile').parent();
    parentTelefon.addClass('error');
    parentMobile.addClass('error');
    parentTelefon.find('.error-msg').html(get_translation('blank-phone'));
    parentMobile.find('.error-msg').html(get_translation('blank-phone'));
    errors.push('telefon');
  }

  if (errors.indexOf('auszahlen') == -1) {
    // Auszahlen mind. 20 oder 0
    var auszahlen = $('#auszahlen');
    var parent = auszahlen.parent();
    if (auszahlen.val() > 0 && auszahlen.val() < 20) {
      errors.push('auszahlen');
      parent.addClass('error');
      parent.find('.error-msg').html(get_translation('gt-20'));
    } else {
      parent.removeClass('error');
    }
  }

  return errors.length === 0;
}


function updateOtherField(field) {
  var guthaben = parseFloat($('body').data('guthaben') || '0');

  var value = parseFloat(field.val() || 0) || 0;
  if (value > guthaben) {
    value = guthaben;
  }
  field.val(roundFixed(value));
  var otherField = $('input#spenden')
  if (field.attr('id') == 'spenden') {
    otherField = $('input#auszahlen');
  }
  otherField.val(roundFixed(guthaben - value));
  checkKontoDaten();
  spendenFelder();
}

function spendenFelder() {
  if ($('#spenden').val() > 0) {
    $('.benachrichtigung-wrapper').show();
  } else {
    $('.benachrichtigung-wrapper').hide();
  }
}
function roundFixed(number) {
  return parseFloat(number).toFixed(2);
}

function get_translation(key) {
  return $('#ons_konto_form').data('translate-' + key) || key;
}
