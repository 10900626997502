import { useDebugValue, useState } from 'react';
import { getLanguage } from '../i18n/translate';

export function getCookieValue(key) {
  const value = document.cookie.match(`(^|[^;]+)\\s*${ key }\\s*=\\s*([^;]+)`);
  return value ? value.pop() : '';
}

export function fromHtml(value) {
  return value.replace(/<br \/>/g, '\n');
}

export function toHtml(value) {
  return value.replace(/\n/g, '<br />');
}

export const objIsEmpty = obj => !obj || Object.keys(obj).length <= 0;

export const formatDate = (date, how) => {
  if (!date) return '';
  const day = `0${date.getDate()}`.substr(-2);
  const month = `0${date.getMonth() + 1}`.substr(-2);
  const year = date.getFullYear();
  const hours = `0${date.getHours()}`.substr(-2);
  const minutes = `0${date.getMinutes()}`.substr(-2);
  const weekday = date.toLocaleDateString(`${getLanguage()}-CH`, { weekday: 'short' });

  if (how === 'dd') return `${day}.`;
  if (how === 'dd.mm') return `${day}.${month}`;
  if (how === 'dd.mm.YY') return `${day}.${month}.${year.toString().substr(-2)}`;
  if (how === 'dd.mm.YYYY') return `${day}.${month}.${year}`;
  if (how === 'weekday dd.mm.YYYY') return `${weekday} ${day}.${month}.${year}`;
  if (how === 'dd.mm.YY hh:mm') return `${day}.${month}.${year.toString().substr(-2)} ${hours}:${minutes} `;
  if (how === 'YYYY-mm-dd') return `${year}-${month}-${day}`;

  return `${day}.${month}.${year}`;
};

export const dateRange = (von, bis) => {
  if (formatDate(von) === formatDate(bis)) {
    return formatDate(von);
  }

  if (von.getFullYear() === bis.getFullYear()) {
    if (von.getMonth() === bis.getMonth()) {
      return `${formatDate(von, 'dd')} - ${formatDate(bis)}`;
    }
    return `${formatDate(von, 'dd.mm')} - ${formatDate(bis)}`;
  }

  return `${formatDate(von)} - ${formatDate(bis)}`;
};

export const getDatesInRange = (startDate, endDate) => {
  let date = new Date(+startDate);
  const dates = [];
  const msInADay = 24 * 60 * 60 * 1000;

  while (date <= endDate) {
    dates.push(new Date(date));
    date = new Date(+date + msInADay);
  }

  return dates;
};

export const currency = (value) => {
  if (value === null || value === undefined) return '-';
  let retValue = parseFloat(value) || 0;
  if (retValue > 999999999) retValue = 999999999;
  return `${retValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'") } CHF`;
};

export function useDebugState(initialValue, name) {
  const [value, setValue] = useState(initialValue);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
}

export function benutzerWurdeImpersoniert() {
  return document.getElementById('benutzer_wurde_impersoniert').value === "true"
}
