document.addEventListener('turbo:load', function() {
  if (window.self === window.top) {
    // Selfservice nicht in Iframe => Cookie löschen
    if (document.cookie.includes("iframe_request")) {
      document.cookie = "iframe_request=;expires=Thu, 01 Jan 1970 00:00:00 GMT;  path=/"
      document.body.classList.remove("hidden-sidedrawer", "hidden-top-bar", "hidden-breadcrumps", "hidden-cookie-banner");
    }
  } else {
    // Selfservice in Iframe  => cookie setzen
    if (!document.cookie.includes("iframe_request")) {
      document.cookie = "iframe_request=true;  path=/"
      Turbo.visit(window.location)
      document.body.classList.add("hidden-sidedrawer", "hidden-top-bar", "hidden-breadcrumps", "hidden-cookie-banner");
    } 
  }
});
