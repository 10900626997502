const Cookies = require('js-cookie');

document.addEventListener("turbo:load", () => {
  const banner = $('#cookie-banner');
  const cookieStatus = Cookies.get('cookieconsent_status') ;

  if (cookieStatus != 'allow') {
    banner.show();
  }

  function bannerAccepted () {
    Cookies.set('cookieconsent_status', 'allow', { expires: 365 })
    banner.hide("fade", {}, 500);
  }
  window.bannerAccepted = bannerAccepted;
});
