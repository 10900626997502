document.addEventListener("turbo:load", () => {
  monitorTurboFrames()
});

document.addEventListener("equis:init:monitorTurboFrames", () => {
  monitorTurboFrames()
});

function monitorTurboFrames() {
  // Durch alle Turbo-Frames gehen und die gewünschte Ladeanimation hinzufügen
  // (wenn das Frame gerade am laden ist) https://turbo.hotwired.dev/reference/attributes#automatically-added-attributes
  document.querySelectorAll("turbo-frame[data-loading-style]").forEach(frame => monitorTurboFrameAriaBusyAttribute(frame));
}

function monitorTurboFrameAriaBusyAttribute(frame) {
  const addLoadingAnimationIfNeeded = () => {
    if (frame.getAttribute("aria-busy") === "true") {
      addLoadingAnimationToTurboFrame(frame);
    } else {
      removeLoadingAnimationFromTurboFrame(frame);
    }
  };

  // Initialzustand überprüfen
  // Wenn das Frame gerade am laden ist (durch z.B. ein asynchrones src-Attribute) wird von Anfang an eine Ladeanimation hinzugefügt
  addLoadingAnimationIfNeeded();

  // Wenn das frame zu einem späteren Zeitpunkt das Attribute aria-busy bekommt:
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "aria-busy") {
        addLoadingAnimationIfNeeded();
      }
    });
  });

  observer.observe(frame, { attributes: true });
}

function addLoadingAnimationToTurboFrame(frame) {
  switch (frame.dataset.loadingStyle) {
    case "spinner":
      addSpinnerToElement(frame);
      break;
    case "opacity":
      addOpacityToElement(frame);
      break;
    default:
      console.warn("No loading style defined");
  }
}

function removeLoadingAnimationFromTurboFrame(frame) {
  switch (frame.dataset.loadingStyle) {
    case "opacity":
      addOpacityToElement(frame, 1);
      break;
  }
}

function addSpinnerToElement(element) {
  element.innerHTML = `
    <div class='d-flex justify-content-center align-content-center p-3'>
      <div class='spinner-border text-primary' role='status'><span class='visually-hidden'>Loading...</span></div>
    </div>
  `;
}

function addOpacityToElement(element, opacity = 0.5) {
  element.style.opacity = opacity;
}

export { addSpinnerToElement };
