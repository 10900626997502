import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Icon, IconButton } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';

import SelectPerson from '../../common/SelectPerson';
import { t } from '../../common/i18n/translate';

function PersonAuswaehlen({ showDialog, value, onDialogClose }) {
  const [person, setPerson] = useState({});

  const handleUpdate = (value) => {
    setPerson(value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDialog || false}
      TransitionProps={{
        onEnter: () => setPerson(value),
        onClose: () => onDialogClose(null),
      }}
    >
      <MuiDialogTitle>
        Person auswählen
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon>close</Icon>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <SelectPerson name="person" label="Person" handleUpdate={handleUpdate} initValue={person} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(person)}>
          {t('speichern')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PersonAuswaehlen;
