class BSToast {
  constructor(message, options = {}) {
    this.message = message
    this.options = options
  }

  toast() {
    document.getElementById("flash-messages").insertAdjacentHTML("beforeend", this.toastHTML)
  }

  get toastHTML() {
    return `
      <div id="toast-${new Date().getTime()}" class="toast align-items-center text-bg-${
      this.classForFlash
    } border-0" role="alert" aria-live="assertive" aria-atomic="true" data-controller="toast-handler" data-toast-handler-duration-value="${this.duration}" data-toast-handler-autohide-value="${this.autohide}" data-toast-handler-animation-value="${this.animation}" data-turbo-permanent>
        <div class="d-flex">
          <div class="toast-body w-100">
            <div class="d-flex align-items-center">
              ${this.type === "success" || this.type === "notice" ? '<i class="fa-solid fa-square-check me-2"></i>' : ""}
              <div class="d-flex justify-content-center w-100">
                ${this.message}
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  get classForFlash() {
    return (
      {
        success: "success",
        notice: "success",
        error: "danger",
        alert: "danger",
      }[this.type] || this.type
    )
  }

  get type() {
    return this.options.type
  }

  get duration() {
    return this.options.duration
  }

  get autohide() {
    return this.options.autohide
  }

  get animation() {
    return this.options.animation
  }
}

export function bootstrapToast(message, options = {}) {
  const optionsWithDefaults = {
    type: options.type || "success",
    animation: options.animation || false,
    autohide: options.autohide || true,
    duration: options.duration || 2000
  }

  switch (optionsWithDefaults.type) {
    case "flash_mit_action": // Flash Messages mit Aktionen (z.B. mit einem Link drin) werden 10 Sekunden dargestellt
      optionsWithDefaults.duration = 10000
      optionsWithDefaults.type = "success"
      break
  }

  new BSToast(message, optionsWithDefaults).toast()
}
