import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Icon,
  Typography,
} from '@mui/material';

import {t} from './i18n/translate';
import {DateField} from "./Formular";

function TextEingebenDialog({
  showDialog = false,
  value,
  valueDateVon,
  valueDateBis,
  title,
  inputLabel,
  description,
  onDialogClose,
  config,
  maxLength = 500,
  showVonBis
}) {
  const [text, setText] = useState(value);
  const [dateVon, setVon] = useState(valueDateVon);
  const [dateBis, setBis] = useState(valueDateBis);

  const MIN_DATE = new Date(-8640000000000000);
  const MAX_DATE = new Date(8640000000000000);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDialog}
      TransitionProps={{
        onEnter: () => {
          setText(value);
          setVon(valueDateVon);
          setBis(valueDateBis)
        },
        onClose: () => onDialogClose(null),
      }}
    >
      <DialogTitle>
        {title}
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showVonBis === true && (
          <DialogContent sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: 0,
            paddingLeft: 0,
            overflow: 'hidden'
          }}>
            <DateField
              sx={{width: "49%"}}
              value={dateVon}
              onChange={event => setVon(new Date(event))}
              label={t('von')}
              maxDate={dateBis ?? MAX_DATE}
              inputProps={{readOnly: true}}
            />
            <DateField
              sx={{width: "49%", paddingRight: '1px'}}
              value={dateBis}
              onChange={event => setBis(new Date(event))}
              label={t('bis')}
              minDate={dateVon ?? MIN_DATE}
              inputProps={{readOnly: true}}
            />
          </DialogContent>
        )}
        <Typography>{description}</Typography>
        <br />
        <TextField
          value={text}
          label={inputLabel}
          onChange={event => setText(event.target.value)}
          style={{ width: '100%' }}
          autoFocus
          type="text"
          inputProps={{ maxLength }}
          {...config.inputAttributes}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(text, new Date(dateVon), new Date(dateBis))}>
          {t('speichern')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TextEingebenDialog;
