import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Paper } from '@mui/material';

import { t } from '../../../common/i18n/translate';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';
import { pruefungenTabelle } from '../AusschreibungVorschau/pruefungsDetails';
import { setDirty } from '../../store/modules/formulare';
import './index.scss';

function Pruefungen({ veranstaltung, pruefungen, formulare }) {
  useEffect(() => {
    if (formulare.isDirty) {
      setDirty(false);
    }
  }, []);

  const zurPruefung = (id) => {
    window.location.href = `#/${veranstaltung.id}/edit/pruefungen/${id}`;
  };

  let erstellButtons = null;

  if (veranstaltung.id && (
    veranstaltung.status === 'in_erfassung'
    || veranstaltung.status === 'zur_voranzeige'
    || veranstaltung.status === 'in_ausschreibung'
  )) {
    erstellButtons = (
      <div>
        <Button
          onClick={() => {
            window.location.href = `#/${veranstaltung.id}/edit/pruefungen/neue_titelzeile`;
          }}
          variant="contained"
          color="primary"
        >
          {t('neue_titelzeile')}
        </Button>
      &nbsp;
        <Button
          onClick={() => {
            window.location.href = `#/${veranstaltung.id}/edit/pruefungen/neue_pruefung`;
          }}
          variant="contained"
          color="primary"
        >
          {t('neue_pruefung')}
        </Button>
      </div>
    );
  }

  return (
    <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
      <div className="ausschreibung-vorschau">
        {pruefungenTabelle(pruefungen, veranstaltung.locale, zurPruefung)}
      </div>
      <div className="flex-row form-actions">
        <div className="flex-1">&nbsp;</div>
        {erstellButtons}
      </div>
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  pruefungen: state.veranstaltungen.current.forms.pruefungen,
  formulare: state.formulare,
});

const mapDispatchToProps = dispatch => ({
  setDirty: (isDirty) => {
    dispatch(setDirty(isDirty));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pruefungen);
