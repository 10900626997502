import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { downloadVeranstaltung } from '../../../common/modules/api';
import Spinner from '../../../common/Spinner';
import { veranstaltungLaden } from '../../store/modules/veranstaltungen';
import AusschreibungVorschau from './index';
import { objIsEmpty } from '../../../common/modules/helpers';

function StandaloneAusschreibung(props) {
  const params = useParams();
  const veranstaltungId = parseInt(params.veranstaltungId);

  useEffect(() => {
    downloadVeranstaltung(veranstaltungId, newData => props.veranstaltungLaden(newData));
  }, [veranstaltungId]);

  if (objIsEmpty(props.veranstaltung) || objIsEmpty(props.stammdaten)) {
    return <Spinner />;
  }
  window.addEventListener('message', (event) => {
    if (event.data === 'print') {
      window.print();
    }
  });
  if (window.location.hash.includes('drucken')) {
    setTimeout(() => {
      window.print();
    }, 100);
  }
  return <AusschreibungVorschau />;
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten,
});

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneAusschreibung);
