import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = [ "flatpickr" ];
  static values = {
    mode: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    dateFormat: { type: String, default: "d.m.Y" },
    altFormat: { type: String, default: "d.m.Y" },
    wrap: { type: Boolean, default: false },
  };

  connect() {
    this.flatpickrElement = this.hasFlatpickrTarget ? this.flatpickrTarget : this.element;
    flatpickr(this.flatpickrElement, this.flatpickrOptions);
  }

  disconnect() {
    this.flatpickrElement._flatpickr.destroy();
  }

  get flatpickrOptions() {
    let options = {
      locale: German,
      altInput: true,
      altFormat: this.altFormatValue,
      dateFormat: this.dateFormatValue,
      wrap: this.wrapValue,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
    }

    if (this.modeValue !== null) {
      options["mode"] = this.modeValue;
    }

    return options
  }
}
