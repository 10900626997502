import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { suchePLZ } from './requests';

// value ist die ort_id
function PLZAutocompleteField({ label, value, handleChange, required, disabled, plzOrtKantonDefault }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const loading = open && options.length === 0 && query.length >= 3;

  useEffect(() => {
    let active = true;

    if (query.length < 2) return;
    if (query.length === 0) {
      setOptions([]);
      return;
    }

    (async () => {
      const gefundenePLZs = await suchePLZ(query);

      if (active) {
        setOptions(gefundenePLZs);
      }
    })();

    return () => {
      active = false;
    };
  }, [query]);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  // is undefined on first render
  if (value == null) {
    return null;
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => {
        const chosenOption = options.find(o => o.id === option?.id);
        if (chosenOption) {
          return `${chosenOption.value} ${chosenOption.ort}, ${chosenOption.kanton}`;
        }

        return plzOrtKantonDefault ?? '';
      }}
      onChange={handleChange}
      options={options}
      value={value}
      disabled={disabled}
      loading={loading}
      renderOption={(o_props, option) => <div {...o_props} key={option.id}>{option.label}</div>}
      renderInput={params => (
        <TextField
          {...params}
          value={value}
          label={label}
          required={required}
          fullWidth
          onChange={e => setQuery((e.target || {}).value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default PLZAutocompleteField;
