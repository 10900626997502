/* eslint-disable no-nested-ternary */
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { deDE as coreDeDE, frFR as coreFrFR, itIT as coreItIT } from '@mui/material/locale';
import { deDE as dataGridDeDE, frFR as dataGridFrFR, itIT as dataGridItIT } from '@mui/x-data-grid';

import { getLanguage } from './i18n/translate';

const coreLangPack = getLanguage() === 'fr' ? coreFrFR : (getLanguage() === 'it' ? coreItIT : coreDeDE);
const dataGridLangPack = getLanguage() === 'fr' ? dataGridFrFR : (getLanguage() === 'it' ? dataGridItIT : dataGridDeDE);

const themeMUI5 = createTheme({
  typography: {
    useNextVariants: true,
    button: {
      textTransform: 'none', // mit dieser Option wird der text auf einem button nicht automatisch gross geschrieben
    },
  },
  palette: {
    // das neue "Equis-Marine"
    primary: { main: '#4183A3', contrastText: '#ffffff' },
    active: { main: '#165270', contrastText: '#ffffff' },
    secondary: grey,
    success: { main: '#5bb75b', contrastText: '#ffffff' },

    // fei Farben für Int-Nennungen
    fei: { main: '#750F7D', contrastText: '#ffffff' },
    inverse: { main: '#000', contrastText: '#ffffff' },
    'fei-fei': { main: '#750F7D', contrastText: '#ffffff' },
    'fei-success': { main: '#468847', contrastText: '#ffffff' },
    'fei-warning': { main: '#f89406', contrastText: '#ffffff' },
    'fei-inverse': { main: '#000', contrastText: '#ffffff' },
    'fei-secondary': { main: '#999', contrastText: '#ffffff' },
    'fei-error': { main: '#999', contrastText: '#ffffff' },
    'fei-rejected': { main: '#999', contrastText: '#ffffff' },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        // behalte default aus MUI 4 bei, waere sonst outlined
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiSelect: {
      defaultProps: {
        // behalte default aus MUI 4 bei, waere sonst outlined
        variant: 'standard',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // behalte default aus MUI 4 bei, waere sonst outlined
        variant: 'standard',
      },
    },
    MuiButton: {
      defaultProps: {
        // behalte default aus MUI 4 bei, waere sonst primary
        color: 'inherit',
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          // wie color default aus MUI
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:focus': {
            outline: 0,
          },
          '&:focus-within': {
            outline: 0,
          },
        },
        columnHeaderTitle: {
          fontWeight: 'bold',
        },
        cell: {
          '&:focus': {
            outline: 0,
          },
          '&:focus-within': {
            outline: 0,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid #eee',
          '&.Mui-selected': {
            borderBottom: '2px solid #00283c',
          },
        },
      },
    },
  },
}, coreLangPack, dataGridLangPack);

export default themeMUI5;
