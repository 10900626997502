import React from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  Paper,
} from '@mui/material';

import { formatDate } from '../common/modules/helpers';
import { t } from '../common/i18n/translate';

function JuryRapportList({ veranstaltungen }) {
  return (
    <Paper>
      <Table sx={{ mt: 2, marginTop: '0px' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('datum')}</TableCell>
            <TableCell>{t('ort')}</TableCell>
            <TableCell>{t('typ')}</TableCell>
            <TableCell>{t('modus')}</TableCell>
            <TableCell>{t('vorgesehene_pruefungen')}</TableCell>
            <TableCell>{t('juryrapporte')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {veranstaltungen.map(veranstaltung => (
            <TableRow
              key={veranstaltung.id}
              hover
            >
              <TableCell>{formatDate(new Date(veranstaltung.datum), 'dd.mm.YY')}</TableCell>
              <TableCell>{veranstaltung.ort}</TableCell>
              <TableCell>{veranstaltung.typ_code}</TableCell>
              <TableCell>{veranstaltung.modus_code}</TableCell>
              <TableCell>{veranstaltung.vorgesehene_pruefungen}</TableCell>
              <TableCell>
                {veranstaltung.juryrapporte.map(juryrapport => (
                  <Button href={juryrapport.link} key={juryrapport.id} variant="contained" size="small" sx={{ m: 0.5 }}>{juryrapport.label}</Button>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default JuryRapportList;
