import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControlLabel,
  Icon,
  Switch,
  TextField,
} from '@mui/material';

import { t } from '../../common/i18n/translate';

function VeranstalterEditDialog({
  veranstalter,
  onDialogClose,
  title,
}) {
  const [veranstalterLocal, setVeranstalterLocal] = useState(null);

  useEffect(() => {
    setVeranstalterLocal(veranstalter);
  }, [veranstalter]);

  if (veranstalterLocal == null) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!veranstalter}
      TransitionProps={{
        onClose: () => onDialogClose(null),
      }}
    >
      <DialogTitle sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', fontWeight: 600 }}>
        {title}
        <IconButton onClick={() => onDialogClose(null)} className="close-btn">
          <Icon style={{ color: 'white' }}>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <TextField
          // className="mt-1"
          value={veranstalterLocal?.name ?? ''}
          disabled={veranstalter?.id != null}
          onChange={e => setVeranstalterLocal({ ...veranstalterLocal, name: e.target.value })}
          label={t('name')}
          fullWidth
        />
        <TextField
          className="mt-1"
          value={veranstalterLocal?.rayon ?? ''}
          onChange={e => setVeranstalterLocal({ ...veranstalterLocal, rayon: e.target.value })}
          label={t('sektion_rayon')}
          fullWidth
        />
        <TextField
          className="mt-1"
          value={veranstalterLocal?.partner_id ?? ''}
          onChange={e => setVeranstalterLocal({ ...veranstalterLocal, partner_id: e.target.value })}
          label={t('partner_id')}
          fullWidth
        />
        <div className="mt-1 flex-row">
          <FormControlLabel
            sx={{ marginLeft: 0, justifyContent: 'start' }}
            control={(
              <Switch
                checked={veranstalterLocal?.regionalverband_mitglied ?? false}
                onChange={(e, newValue) => setVeranstalterLocal({ ...veranstalterLocal, regionalverband_mitglied: newValue })}
                color="primary"
              />
            )}
            label={t('mitglied')}
            labelPlacement="start"
          />
        </div>
        <div className="mt-1 flex-row">
          <FormControlLabel
            sx={{ marginLeft: 0, justifyContent: 'start' }}
            control={(
              <Switch
                checked={veranstalterLocal?.aktiv ?? false}
                onChange={(e, newValue) => setVeranstalterLocal({ ...veranstalterLocal, aktiv: newValue })}
                color="primary"
              />
            )}
            label={t('aktiv')}
            labelPlacement="start"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onDialogClose(veranstalterLocal)}>
          {veranstalter?.id != null ? t('speichern') : t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VeranstalterEditDialog;
