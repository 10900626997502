import React from 'react';

import usePageTracking from './usePageTracking';

export default function withPageTracking(RouteElement: React.ComponentType, routerBasename = '') {
  // @ts-expect-error we don care
  function HOC({ WrappedComponent }) {
    usePageTracking(routerBasename);
    return <WrappedComponent />;
  }

  return <HOC WrappedComponent={RouteElement} />;
}
