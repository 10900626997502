import React, { useState, useEffect } from 'react';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { suchePersonen } from './requests';

function AutocompletePerson({ label, value, onChange, required, disabled }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const loading = open && options.length === 0 && query.length >= 3;

  useEffect(() => {
    let active = true;

    if (query.length < 3) return;
    if (query.length === 0) {
      setOptions([]);
      return;
    }

    (async () => {
      const personen = await suchePersonen(query);

      if (active) {
        setOptions(personen);
      }
    })();

    return () => {
      active = false;
    };
  }, [query]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={option => option.name || ''}
      onChange={onChange}
      options={options}
      value={value}
      disabled={disabled}
      loading={loading}
      filterOptions={createFilterOptions({
        stringify: (option) => {
          const nachnameVorname = option.name;
          const vornameNachname = `${option.name.split(' ')[1]} ${option.name.split(' ')[0]}`;
          // = ermoeglicht entweder zuerst vor- oder nachname zu tippen
          return `${nachnameVorname} ${vornameNachname}`;
        },
        limit: 20,
      })}
      renderOption={(o_props, option) => (
        <div {...o_props} key={option.id}>
          {`${option.name}, ${option.ort} ${option.kanton}`}
        </div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          value={value}
          label={label}
          required={required}
          fullWidth
          onChange={e => setQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompletePerson;
