document.addEventListener("turbo:before-stream-render", function(event) {
  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  if (event.target.firstElementChild instanceof HTMLTemplateElement) {
    if (event.target.templateContent.firstElementChild) {
      var enterAnimationClass = event.target.templateContent.firstElementChild.dataset.streamEnterClass
    }
    if (enterAnimationClass) {
      event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass)
    }
  }
});
