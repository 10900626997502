export const getMetaValue = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
};

export const createMetaTag = (name, content) => {
  const metaTag = document.createElement('meta');
  metaTag.name = name;
  metaTag.content = content;
  document.head.appendChild(metaTag);
};

export const setFullPageReloadOnNextNavigation = () => {
  createMetaTag('turbo-visit-control', 'reload');
}

export const isMobile = () => {
  if (window.matchMedia("(max-width: 599px)").matches) {
    return true;
  }
  return false;
}

const { userAgent } = window.navigator
export const isIosApp = /Turbo Native iOS/.test(userAgent)
export const isAndroidApp = /Turbo Native Android/.test(userAgent)
export const isMobileApp = isIosApp || isAndroidApp
export const mobileAppPlatform = isMobileApp ? (isIosApp ? 'ios' : 'android') : null
export const mobileAppVersion = isMobileApp ? (userAgent.match(/app_version: ([^;]+)/) || [])[1] : null; // ([^;]+) => alle Zeichen ausser Semikolon
export const mobileAppUniqueDeviceId = isMobileApp ? (userAgent.match(/unique_device_id: ([^;]+)/) || [])[1] : null; // ([^;]+) => alle Zeichen ausser Semikolon

export default {
  createMetaTag,
  setFullPageReloadOnNextNavigation,
  getMetaValue,
  isMobile,
  isIosApp,
  isAndroidApp,
  isMobileApp,
  mobileAppPlatform,
  mobileAppVersion,
  mobileAppUniqueDeviceId
};
