import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

// geteilt ueber alle instanzen der hook - verhindere mehrfache ausloesung,
// auch wenn evtl. mehrere hooks gleichzeitig registriert sind,
// bspw. wenn eine route noch children routes hat und beide withPageTracking brauchen
let currentPage: string = null;

const usePageTracking = (routerBasename = '') => {
  const location = useLocation();
  const environment = document.getElementById('environment')?.getAttribute('value');

  // send page name to GA when changes
  useEffect(() => {
    const newPage = `${routerBasename}${location.pathname === '/' ? '' : location.pathname}`;
    if (environment === 'production' && currentPage !== newPage) {
      ReactGA.pageview(newPage);
      currentPage = newPage;
    }
  }, [location, currentPage]);
};

export default usePageTracking;
