import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { de } from 'date-fns/locale';

import { initializeStore } from './redux';
import Router from './Router';
import Layout from '../common/Layout/Layout';
import themeMUI5 from '../common/EquisThemeMUI5';
import { setBaseUrl, updateRequestHeaders } from '../common/services/api';
import { useGoogleAnalytics } from '../common/tracking';

function Juryrapporte({
  userId,
  apiToken,
  apiUrl,
  authType,
  locale,
}) {
  useGoogleAnalytics();
  const [reduxStore, setReduxStore] = useState(null);

  // TODO: i18n
  if (userId == null || apiToken == null) {
    return <p>App nicht einsatzbereit!</p>;
  }

  useEffect(() => {
    setBaseUrl(apiUrl);
    updateRequestHeaders(userId, apiToken, authType);
    const initialStore = initializeStore(locale);
    setReduxStore(initialStore);
  }, []);

  if (reduxStore == null) {
    return null;
  }

  return (
    <ThemeProvider theme={themeMUI5}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
        <Provider store={reduxStore}>
          <Layout>
            <Router />
          </Layout>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default Juryrapporte;
