document.addEventListener('turbo:load', function() {
  $('#aa_auszahlungsbetrag')
    .on('press', function(ev) {
      var keyCode = ev.keyCode,
          valid = (keyCode > 45 && keyCode < 58) || keyCode == 44 || keyCode == 8;
      return valid;
    })
    .on('blur', function() {
      var $this = $(this),
          data = $this.data(),
          value = $this.val(),
          $target = $(data['targetSelector']),
          bearbeitungs_gebuehr = +data['bearbeitungsGebuehr'],
          saldo = +data['saldo'],
          calculated_value = 0;
      if(isNaN(value)) {
        value = 0;
      } else {
        value = Math.round(+value * 100) / 100;
        if(value > saldo) {
          value = saldo;
        }
        if(value > bearbeitungs_gebuehr) {
          calculated_value = value - bearbeitungs_gebuehr;
        }
      }
      $this.val(value);
      $target.val(Math.round(calculated_value * 100) / 100);
    });
});
