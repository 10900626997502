import Utils from "../global/utils.js";
require('tom-select')

var letzterTab = "#pruefungen";
var countdownTimer = undefined;

document.addEventListener("turbo:load", function() {
  if (document.querySelector("#nennungen-new")) {
    nennungFormInitialisieren();
    stallungenEventListeners();
  }

  if (document.querySelector(".nennung-pferdewechsel")) {
    nennungPferdeWechsel();
  }
});

document.addEventListener("nennung-form-initialisieren", function() {
  nennungFormInitialisieren();
});

function nennungPferdeWechsel() {
  document.querySelectorAll(".gastpferd-select").forEach((select) => {
    // Existierendes Gastpferd im Select auswählen
    const existierenderGastPferdName = document.querySelector(`#gastpferd-name-${select.dataset.nennungId}`).value
    select.querySelectorAll("option").forEach((option) => {
      if (option.dataset.gastpferdName === existierenderGastPferdName) {
        option.selected = true
      }
    })

    // Wenn PferdeSelect geändert wird, name des Gastpferds in das hidden field setzen
    select.addEventListener("change", (event) => {
      const nennungId = event.target.dataset.nennungId
      const hiddenGastPferdNameInput = document.querySelector(`#gastpferd-name-${nennungId}`);
      const gastPferdName = event.target.selectedOptions[0].dataset.gastpferdName || "";
      hiddenGastPferdNameInput.value = gastPferdName;
    });
  });
}

function nennungFormInitialisieren() {
  updateWizardClasses();

  var tab = new RegExp('[\?&]tab=([^&#]*)').exec(window.location.href);
  if (tab) {
    letzterTab = '#' + tab[1];
    aktionenNachTabwechsel(letzterTab);
  }


  $('ul.wizard li[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
    aktionenNachTabwechsel($(e.target)[0].dataset.bsTarget);
  });

  $('form.nennung-erstellen-form').on('change', '.gastpferd', function () {
    gastpferdNameSetzen($(this));
  });

  $('form.nennung-erstellen-form, form.voltige-nennung-mutieren-form').on('change', '.feld_validieren', function () {
    alleZeilenValidieren($(this).parents('.pruefung:first'));
  });

  $('.btn-next').click(function(){
    $('.wizard > .active').next('li').click();
  });
  $('.table-with-checkbox tr, .custom-table-with-checkbox li').on('click', function (event) {
    var row = $(this);
    if (row.hasClass('locked')) {
      event.preventDefault();
    } else {
      var checkbox = row.find('input[type="checkbox"]');
      if (checkbox.length > 0) {
        if (event.target != checkbox[0]) {
          checkbox.trigger('click');
        }
        row.toggleClass('checked', checkbox[0].checked);
        if (checkbox[0].checked) {
          $('.btn-next-pruefungen').removeAttr('disabled');
        } else {
          var anySelected = false;
          $.each($(row.closest('tbody')).find('input[type="checkbox"]'), function(index, item) {
            if (item.checked) {
              anySelected = true
            }
          });
          if (!anySelected) {
            $('.btn-next-pruefungen').attr('disabled', 'disabled');
          }
        }
      }
    }
  });

  $('a.nennung-erstellen').on('click', function (event) {
    event.preventDefault();
    var link = $(this);
    $.ajax(link.attr('href'), { success: function (data) {
      var zeile = $(data).appendTo(link.parents('.pruefung:first').find('.nennungen'));
      if (zeile.find('.athleten-select').length > 0) {
        initAthletenSelect();
      }
      zeile.find('.gastpferd').each(function (i, element){
        gastpferdNameSetzen($(element));
      });
      nennungCounterAktualisieren(zeile.parents('.pruefung:first'));
      updateVoltigeGruppen();
    }});
  });

  $('.nennungen').on('click', 'a.show-form', function (event) {
    event.preventDefault();
    $(this).parents('.nennung-zeile:first').addClass('edit');
  });
  $('.nennungen').on('click', '.hide-form', function (event) {
    event.preventDefault();
    var zeile = $(this).parents('.nennung-zeile:first')
    zeile.find('[data-update-field]').each(function (i, element){
      var feld = $(element);
      var wert = feld.val();
      if (feld[0].tagName.toLowerCase() == 'select') {
        wert = feld.find(':selected').html();
      }
      zeile.find('.zusammenfassung ' + feld.data('update-field')).text(wert);
    });
    zeile.removeClass('edit');
  });

  $('.nennungen').on('change', '.voltige_gruppe', function () {
    var gruppe_select = $(this);
    var zeile = gruppe_select.parents('.nennung-zeile:first');

    var gruppenname = zeile.find('input.gruppenname');
    if (gruppenname.val() == '') {
      gruppenname.val(gruppe_select.find(':selected').html());
    }

    const athletenIds = gruppe_select.find(':selected').attr('data-athleten-ids').split(',')
    const athletenSelect = zeile.find('select.athleten-select')
    updateAthletenSelect(athletenSelect, athletenIds)
  });

  $('.nennungen').on('change', '.updateVoltigeGruppen', function() {
    updateVoltigeGruppen();
  });

  $('.nennung-zeile .athleten-select').each(function (i, element){
    initAthletenSelect();
  });

  // Gastpferde richtig auswählen
  $('select.gastpferd').each(function (i, select){
    var select = $(this);
    var zeile = select.parents('.nennung-zeile:first');
    var gastpferd_name = zeile.find('.gastpferd_name_origin').val();
    var options = select.find('option').filter(function(i,opt){return opt.innerHTML == gastpferd_name;});
    if (options.length > 0) {
      $(options[0]).attr('selected', true);
    }
  });

  $('.nennungen').on('click', '.zeile-entfernen', function (event) {
    var zeile = $(this).parents('.nennung-zeile:first');
    var pruefung = $(zeile).parents('.pruefung:first');
    zeile.remove();
    nennungCounterAktualisieren(pruefung);
  });

  $('.nennungen').on('click', '.nennung-loeschen', function (event) {
    event.preventDefault();
    event.stopPropagation();
    var link = $(this);
    if (confirm(link.data('turboConfirm'))) {
      $.ajax({
        url: link.attr('href'),
        type: 'delete',
        dataType: 'json',
        headers: {
          "X-CSRF-Token": Utils.getMetaValue("csrf-token")
        },
        success: function (data) {
          var zeile = link.parents('.nennung-zeile:first');
          var pruefung = $(zeile).parents('.pruefung:first')
          zeile.remove();
          nennungCounterAktualisieren(pruefung);
        }
      });
    }
  });

  var waehrungFormatieren = function(wert) {
    var gerundeterWert = wert.toFixed(2);
    var mitHochkommas = gerundeterWert.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return mitHochkommas + ' CHF';
  };
  $('body').on('click', '.nennung-reservation-loeschen', function(event) {
    var zeile = $($(event.target).parents('.reservations-zeile:first'));
    var reservationLoeschenText = zeile.data('reservationLoeschenText');
    if (confirm(reservationLoeschenText)) {
      // Zeile auf nicht mehr aktiv setzen mit Text
      zeile.data('nennungAktiv', 'inaktiv');
      zeile.removeClass('text-success').addClass('text-error');
      zeile.find('.status-spalte').html(zeile.data('reservationGeloeschtText'));
      zeile.find('.betrag-spalte').html('');
      $('.total-betrag-nennungen').html('');
      zeile.find('.loeschen-spalte').html('');

      // Anzahl Nennungen und Totalbeträge neu rechnen
      var aktiveNennungen = aktiveNennungDetails();
      var saldo = parseFloat($('#saldo').data('saldo'));

      // Gerechnete Werte in HTML eintragen
      $('#total-nennungen').html(waehrungFormatieren(aktiveNennungen.total));
      var totalBetragSpan = $('.total-betrag-total')
      var neuerTotalBetrag = aktiveNennungen.total + parseFloat(totalBetragSpan.data('stallungen'))
      totalBetragSpan.html(waehrungFormatieren(neuerTotalBetrag));

      var betragDerVomNennkontoBezogenWird = 0;
      var beiPostfinanceZuBezahlen = neuerTotalBetrag - saldo
      if (beiPostfinanceZuBezahlen <= 0) {
        beiPostfinanceZuBezahlen           = 0
        betragDerVomNennkontoBezogenWird = neuerTotalBetrag
      } else if (neuerTotalBetrag > saldo) {
        betragDerVomNennkontoBezogenWird = saldo
      } else if (neuerTotalBetrag < saldo) {
        betragDerVomNennkontoBezogenWird = saldo - neuerTotalBetrag
      }
      $('#betrag-der-vom-nennkonto-bezogen-wird').html(waehrungFormatieren(betragDerVomNennkontoBezogenWird * -1));

      if (aktiveNennungen.anzahl <= 0) {
        $('#zahlung-starten').attr('disabled', 'disabled');
      }
      // Gelöschte Nennungen im Formular hinterlegen
      var reservationGeloeschteNennungenFeld = $('#reservation-geloeschte-nennungen');
      var reservationGeloeschteNennungen = JSON.parse(reservationGeloeschteNennungenFeld.val());
      reservationGeloeschteNennungen.push([zeile.data('pruefungId'), zeile.data('zeileIndex')]);
      reservationGeloeschteNennungenFeld.val(JSON.stringify(reservationGeloeschteNennungen));
    }
  });

  function aktiveNennungDetails() {
    var anzahl = 0;
    var total = 0;
    $('.reservations-zeile').each(function(index, zeile) {
      if ($(zeile).data('nennungAktiv') === 'aktiv') {
        anzahl = anzahl + 1;
        total = total + parseFloat($(zeile).data('bezahltesNenngeld'));
      }
    });

    return { anzahl: anzahl, total: total }
  }

  $('.nennung-erstellen-form').on('click', 'a.refresh-bestaetigung', function (event) {
    event.preventDefault();
    bestaetigungLaden();
  });

  function naechster_tab() {
    if (tabErlaubt($('ul.wizard li.active').next('li')[0].dataset.bsTarget)) {
      $('ul.wizard li.active').next('li').click()
    }
  };

  $('body').on('submit', '.nennung-erstellen-form', function(event) {
    if ($('ul.wizard li.active')[0].dataset.bsTarget === '#bestaetigung') {
      return true;
    } else {
      naechster_tab();
      return false;
    }
  });
  $('body').on('keydown', '.nennung-erstellen-form input[type="text"]', function(event) {
    if (event.key === 'Enter' || event.keyIdentifier === 'Enter' || event.keyCode === 13) {
      naechster_tab();
      event.preventDefault();
      return false;
    }
  });

  $('.tab-content #pruefungen').on('click', 'a.toggle-infos', function (event) {
    event.preventDefault();
    $(this).toggleClass('btn-inverse');
    $('.ausschreibung-infos').slideToggle();
  });

  $('body').on('click', '#zahlungen-agb-check', function() {
    // Anzahl Nennungen und Totalbeträge neu rechnen
    var aktiveNennungen = aktiveNennungDetails()
    if (aktiveNennungen.anzahl > 0) {
      $('#zahlung-starten').prop("disabled", !$('#zahlungen-agb-check').is(':checked'));
    }
  });

  $('body').on('click', '.schaltflaeche-definitiv-nennen', function() {
    definitivNennenBoxDarstellen();
    $('html, body').animate({
      scrollTop: $('#definitiv-nennen-box').offset().top
  }, 1000);
  });

  $('body').on('click', '#zahlung-starten', function (event) {
    // dass der body nicht verrutscht, wegen postfinance-modal
    setPositionOnBody();
    $('#nennungen-direkt-bezahlen').val(true);
    var beiPostFinanceZuBezahlen = parseFloat($('#bei-postfinance-zu-bezahlen').val())
    var formMussAsynchronAbgeschicktWerden = beiPostFinanceZuBezahlen > 0
    var form = $('form.nennung-erstellen-form');
    if (formMussAsynchronAbgeschicktWerden) {
      $('#zahlung-starten').prop("disabled", true);
      $('#loading-spinner').removeClass('d-none');
      event.preventDefault();
      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: form.serialize(),
        headers: {
          "X-CSRF-Token": Utils.getMetaValue("csrf-token")
        },
        error: function (data) {
          console.log({data});
        },
        success: function (data) {
          var postfinanceLightBoxUrl = data.lightbox_url;
          var script = document.createElement('script');
          script.src = postfinanceLightBoxUrl;
          script.onload = function () {
            // open lightbox
            if (window.LightboxCheckoutHandler == undefined) {
              alert("#{I18n.t('einzahlungen.bestaetigen.postfinance_nicht_erreichbar')}");
            } else {
              window.LightboxCheckoutHandler.startPayment(null, function(){
                alert("#{I18n.t('einzahlungen.bestaetigen.postfinance_nicht_erreichbar')}");
              });
            }
          }
          document.head.appendChild(script);
        }
      })
    }
  });
}

function setPositionOnBody() {
  var body = document.body;
  body.classList.add('position-relative');
}

function definitivNennenBoxDarstellen() {
  $('#definitiv-nennen-box').toggleClass('d-none');
}

function aktionenNachTabwechsel(tab_hash) {
  if (tabErlaubt(tab_hash)) {
    updateWizardClasses();
    switch (tab_hash) {
      case '#pruefungen':
        break;
      case '#reiter_pferd':
        $('#reiter_pferd .pruefung').addClass('d-none');
        $('.pruefung-checkbox:checked').each(function (i, element){
          $('#reiter_pferd .pruefung[data-id="' + $(element).val() + '"]').removeClass('d-none');
        });
        // sicherstellen, dass die Prüfungen mit Nennungen immer sichtbar sind
        $('.pruefung.d-none').each(function (i, element) {
          var pruefung = $(element);
          if (pruefung.find('.nennung-zeile').size > 0) {
            pruefung.removeClass('d-none');
          }
        });
        break;
      case '#stallungen':
        if ($('.nennung-zeile.hat-fehler').length > 0) {
          $('ul.wizard #reiter_pferd-link').click();
        }
        break;
      case '#bestaetigung':
        if ($('.nennung-zeile.hat-fehler').length > 0) {
          $('ul.wizard #reiter_pferd-link').click();
        }
        else if ($('.stallung-zeile.hat-fehler').length > 0) {
          $('ul.wizard #stallungen-link').click();
        } else {
          bestaetigungLaden();
        }
        if (countdownTimer === undefined) {
          var countdownHerunterzaehlen = function() {
            var sekundenContainer = $('#nennung-countdown-timer .sekunden .wert');
            var sekunden = parseInt(sekundenContainer.html());
            var neueSekunden = sekunden - 1;
            if (neueSekunden <= 0) {
              sekundenContainer.addClass('abgelaufen');
              sekundenContainer.html(0);
              $('#zahlung-starten').attr('disabled', 'disabled');
            } else {
              sekundenContainer.html(neueSekunden);
            }
            countdownTimer = setTimeout(countdownHerunterzaehlen, 1000);
          }
          countdownTimer = setTimeout(countdownHerunterzaehlen, 1000);

          document.addEventListener("turbo:before-cache", function() {
            clearTimeout(countdownTimer);
            countdownTimer = undefined;
          })
        }
        break;
    }
    letzterTab = tab_hash;
  } else {
    $(letzterTab + '-link').click();
  }
}

function tabErlaubt(neuerTab) {
  if (letzterTab === '#bestaetigung') {
    if (countdownTimer !== undefined) {
      var sekundenContainer = $('#nennung-countdown-timer .sekunden .wert');
      var sekunden = parseInt(sekundenContainer.html());
      var reservationsIds = JSON.parse($('#reservations-ids').val());
      var reservationenLeiderKeine = JSON.parse($('#reservationen-leider-keine').val());
      if (sekunden === 0 || reservationsIds.length === 0 || reservationenLeiderKeine || confirm($('#wollen-sie-countdown-abbrechen').val())) {
        $.ajax({
          url: '/nennungen/reservationen_loeschen',
          type: 'delete',
          data: { reservations_ids: reservationsIds },
          dataType: 'json',
          headers: {
            "X-CSRF-Token": Utils.getMetaValue("csrf-token")
          },
          success: function (data) {
            // noop - fire-and-forget, schlimmstenfalls läuft der Countdown aus
          }
        });
      } else {
        return false;
      }
    }
  }
  if (neuerTab === '#pruefungen') {
    return true;
  } else if (neuerTab === '#reiter_pferd' || neuerTab === '#stallungen') {
    return $('.pruefung-checkbox:checked').length > 0;
  } else if (neuerTab === '#bestaetigung') {
    return ($('.nennung-zeile').length || $('.stallung-zeile').length) &&
      !($('.nennung-zeile.hat-fehler').length || $('.stallung-zeile.hat-fehler').length);
  } else {
    return false;
  }
}

function initAthletenSelect() {
  athletenSelectFields().forEach((field) => {
    if (field.tomselect == undefined) {
      new TomSelect(
        field,
        { plugins: ['drag_drop'] }
      )
    }
  })
}

function updateAthletenSelect(athletenSelect, aktiveAthletenIds) {
  const tomSelectInstance = athletenSelect[0].tomselect
  tomSelectInstance.setValue(aktiveAthletenIds)
  athletenSelect.val(aktiveAthletenIds)
  tomSelectInstance.refreshItems()
}

function athletenSelectFields() {
  return document.querySelectorAll('select.athleten-select')
}

function updateWizardClasses() {
  $('ul.wizard li.done').removeClass('done');
  $('ul.wizard li.active').prevAll('li.step').addClass('done');
}

function alleZeilenValidieren(pruefung) {
  var nennungenDatenProPruefung = {};
  var sindEquipen = false;
  pruefung.find('.nennung-zeile').each(function (i, element) {
    var zeile = $(element);
    var pruefungElement = zeile.parents('.pruefung:first');
    var anzahlNennungen = parseInt(pruefungElement.find('.anzahl-nennungen:first').html());
    var maxNennungen = parseInt(pruefungElement.find('.max-nennungen:first').html());
    if (anzahlNennungen > maxNennungen) {
      fehlerFuerZeile(zeile, $('#reiter_pferd').data('pruefung-voll-hint'));
    } else {
      var pruefungId = $(pruefungElement).data('id'); // wird nur eine sein
      nennungenDatenProPruefung[pruefungId] = nennungenDatenProPruefung[pruefungId] || [];
      var nennungDaten = {};
      var zeileValidiert = false;
      zeile.find('input, select').each(function (i, inputElement) {
        if (inputElement.name.includes('equipen[')) {
          sindEquipen = true;
          if (!zeileValidiert) {
            zeile.removeClass('hat-fehler');
            equipeZeileValidieren(zeile);
            zeileValidiert = true;
          }
        } else if (inputElement.name === 'voltige_gruppe[]' || inputElement.name === '') {
          // noop
        } else if (inputElement.type === 'checkbox') {
          var nennungenFeldName = inputElement.name.replace('nennungen[][', '').replace(']', '');
          nennungDaten[nennungenFeldName] = inputElement.checked ? inputElement.value : false;
        } else if (inputElement.name === 'nennungen[][athleten_ids][]') {
          nennungDaten['athleten_ids'] = inputElement.tomselect.items;
        } else {
          var nennungenFeldName = inputElement.name.replace('nennungen[][', '').replace(']', '');
          nennungDaten[nennungenFeldName] = inputElement.value;
        }
      })
      nennungenDatenProPruefung[pruefungId].push(nennungDaten)
    }
  });
  if (!sindEquipen) {
    for (var pruefungId in nennungenDatenProPruefung) {
      var nennungenDaten = {'nennungen': JSON.stringify(nennungenDatenProPruefung[pruefungId])};
      $.ajax({
        type: 'POST',
        url: '/nennungen/validieren',
        data: nennungenDaten,
        headers: {
          "X-CSRF-Token": Utils.getMetaValue("csrf-token")
        },
        error: function (data) {
          pruefung.find('.nennung-zeile').each(function (i, element) {
            var zeile = $(element);
            zeile.removeClass('hat-fehler');
            if (!!JSON.parse(data.responseText)[i]) {
              fehlerFuerZeile(zeile, JSON.parse(data.responseText)[i]);
            } else {
              uniqFelderPruefen(zeile);
              nennungDuplikateSuchenFuer(zeile);
            }
          });
        },
        success: function (data) {
          pruefung.find('.nennung-zeile').each(function (i, element) {
            var zeile = $(element);
            zeile.removeClass('hat-fehler');
            uniqFelderPruefen(zeile);
            nennungDuplikateSuchenFuer(zeile);
          });
        }
      });
    }
  }
}

function equipeZeileValidieren(zeile) {
  var pruefung = zeile.parents('.pruefung:first');
  var anzahl_nennungen = parseInt(pruefung.find('.anzahl-nennungen:first').html());
  var max_nennungen = parseInt(pruefung.find('.max-nennungen:first').html());
  if (anzahl_nennungen > max_nennungen) {
    fehlerFuerZeile(zeile, $('#reiter_pferd').data('pruefung-voll-hint'));
  } else {
    $.ajax({
      type: 'POST',
      url: '/nennungen/validieren',
      data: zeile.find('input, select').serialize(),
      headers: {
        "X-CSRF-Token": Utils.getMetaValue("csrf-token")
      },
      error: function (data) {
        fehlerFuerZeile(zeile, JSON.parse(data.responseText)[0]);
      },
      success: function () {
        uniqFelderPruefen(zeile);
        nennungDuplikateSuchenFuer(zeile);
      }
    });
  }
}

function gastpferdNameSetzen(pferd_select) {
  var zeile = pferd_select.parents('.nennung-zeile:first');
  if (zeile.length == 0) zeile = pferd_select.parents('.stallung-zeile:first');
  zeile.find('.gastpferd_name_origin').val(pferd_select.find(':selected').data('gastpferdName'));
}

function uniqFelderPruefen(zeile) {
  var pruefung = zeile.parents('.pruefung:first');
  zeile.find('.feld_uniq').each(function (i, element) {
    var input = $(element);
    var count = 0;
    pruefung.find('[name="' + input.attr('name') + '"]').each(function (i, feld) {
      var ist_gastpferd = (input.hasClass('gastpferd') && input.val() == 5);
      if (!ist_gastpferd && $(feld).val() == input.val()) {
        count++;
      }
    });
    if (count > 1) {
      fehlerFuerZeile(zeile, input.data('uniq-hint'));
    }
  });
}

function nennungDuplikateSuchenFuer(zeile) {
  var count = 0;
  var suche_nach = zeile.find('.feld_duplikat').serialize();
  zeile.parents('.pruefung:first').find('.nennung-zeile:not(.equipe)').each(function (i, nennung_zeile){
    if (suche_nach == $(nennung_zeile).find('.feld_duplikat').serialize()) {
      count++;
    }
  });
  if (count > 1 && suche_nach.length > 0) {
    fehlerFuerZeile(zeile, $('#reiter_pferd').data('duplikat-hint'));
  }

  zeile.find('.paar').each(function(i, paare) {
    count = 0;
    suche_nach = $(paare).find('.feld_duplikat').serialize();
    zeile.parents('.pruefung:first').find('.nennung-zeile.equipe .paar').each(function (i, nennung_zeile){
      if (suche_nach == $(nennung_zeile).find('.feld_duplikat').serialize()) {
        count++;
      }
    });
    if (count > 1 && suche_nach.length > 0) {
      fehlerFuerZeile(zeile, 'lokales duplikat');
    }
  });
}

function fehlerFuerZeile(zeile, text) {
  zeile.addClass('hat-fehler');
  var error_text = zeile.find('p.alert');
  if (error_text.length == 0) {
    error_text = $('<p class="alert alert-danger" />').appendTo(zeile);
  }
  if (text.indexOf("La/Le Nennungen n'est pas valide,") >= 0) {
    text = text.replace("La/Le Nennungen n'est pas valide,", "L’engagement n’est pas valide,");
  }
  error_text.html(text);
}

function nennungCounterAktualisieren(pruefung) {
  var anzahl_nennungen_feld = pruefung.find('.anzahl-nennungen:first');

  var anzahl_nennungen = parseInt(pruefung.data('anzahl-nennungen'));
  pruefung.find('.nennung-zeile.neu').each(function (i, element){
    var zeile = $(element);
    if (zeile.hasClass('equipe')) {
      anzahl_nennungen += zeile.find('.paar').length;
    } else {
      anzahl_nennungen += 1;
    }
  });
  anzahl_nennungen_feld.html(anzahl_nennungen);
  alleZeilenValidieren(pruefung);
  if ($('.nennung-zeile').length || $('.stallung-zeile').length) {
    $('.btn-next-nennungen').removeAttr('disabled');
    $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').attr('disabled', 'disabled');
  } else {
    $('.btn-next-nennungen').attr('disabled', 'disabled');
    $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').removeAttr('disabled');
  }
}

function bestaetigungLaden() {
  $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').removeAttr('disabled');
  $('#bestaetigung .spinner').show();
  $('#bestaetigung .resultat').html('');
  setTimeout(function () {
    var form = $('form.nennung-erstellen-form');
    $.ajax({
      type: 'POST',
      url: '/nennungen/bestaetigen',
      data: form.serialize(),
      headers: {
        "X-CSRF-Token": Utils.getMetaValue("csrf-token")
      },
      success: function (data) {
        $('#bestaetigung .spinner').hide();
        $('#bestaetigung .resultat').html(data);
        if ($('.nennung-zeile').length || $('.stallung-zeile').length) {
          $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').attr('disabled', 'disabled');
        }
      },
      error: function (data) {
        $('#bestaetigung .spinner').hide();
        $('#bestaetigung .resultat').html('<h1>Error:</h1><pre>' + data.responseText.replace(/</g, '&lt;') + '<pre>');
        if ($('.nennung-zeile').length || $('.stallung-zeile').length) {
          $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').attr('disabled', 'disabled');
        }
      }
    });
  }, 500);
}


function updateVoltigeGruppen() {
  var vereinID = $('.updateVoltigeGruppen').val();

  if (vereinID) {
    $('.voltige_gruppe option[data-verein-id]').hide();
    $('.voltige_gruppe option[data-verein-id='+vereinID+']').show();
  }
}

// Stallungen
function stallungenEventListeners() {
  $('a.stallung-erstellen').on('click', function (event) {
    event.preventDefault();
    var link = $(this);
    $.ajax(link.attr('href'), { success: function (data) {
      var zeile = $(data).appendTo('.stallungen');
      zeile.find('input.datepicker:not(*[readonly="readonly"])').each(function(index, input) {
        var $input = $(input);
        $input.datepicker({
          minDate: $input.data('min_datum'),
          maxDate: $input.data('max_datum'),
          changeMonth: true,
          changeYear: true
        });
      });

      zeile.find('.gastpferd').each(function (i, element){
        let pferd_select = $(element);
        let zeile = pferd_select.parents('.nennung-zeile:first');
        if (zeile.length == 0) zeile = pferd_select.parents('.stallung-zeile:first');
        zeile.find('.gastpferd_name_origin').val(pferd_select.find(':selected').data('gastpferdName'));
      });
      stallungZeileValidieren(zeile);
      buttonsPruefen();
    }});
  });

  $('.stallungen').on('click', '.zeile-entfernen', function (event) {
    var zeile = $(this).parents('.stallung-zeile:first');
    zeile.remove();
    buttonsPruefen();
  });
  $('.stallungen').on('click', '.stallung-loeschen', function (event) {
    event.preventDefault();
    event.stopPropagation();
    var link = $(this);
    if (confirm(link.data('confirm'))) {
      $.ajax({
        url: link.attr('href'),
        headers: {
          "X-CSRF-Token": Utils.getMetaValue("csrf-token")
        },
        type: 'delete',
        dataType: 'json',
        success: function (data) {
          var zeile = link.parents('.stallung-zeile:first');
          zeile.remove();
          buttonsPruefen();
        }
      });
    }
  });

  $('form.nennung-erstellen-form').on('change', '.stallung_validieren', function () {
    var zeile = $(this).parents('.stallung-zeile:first');

    // fehler zurücksetzen
    zeile.removeClass('alert-danger');
    zeile.find('p.alert').remove();
    stallungZeileValidieren(zeile);
  });
}

function buttonsPruefen() {
  if ($('.nennung-zeile').length || $('.stallung-zeile').length) {
    $('.btn-next-nennungen').removeAttr('disabled');
    $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').attr('disabled', 'disabled');
  } else {
    $('.btn-next-nennungen').attr('disabled', 'disabled');
    $('.table-with-checkbox tbody > tr input[type="checkbox"]:checked').removeAttr('disabled');
  }
}

function stallungZeileValidieren(zeile) {
  var pruefung = zeile.parents('.pruefung:first');
  var anzahl_nennungen = parseInt(pruefung.find('.label > .anzahl-nennungen:first').html());
  var max_nennungen = parseInt(pruefung.find('.label > .max-nennungen:first').html());
  if (anzahl_nennungen > max_nennungen) {
    fehlerFuerZeile(zeile, $('#reiter_pferd').data('pruefung-voll-hint'));
  } else {
    $.ajax({
      type: 'POST',
      url: '/nennungen/validieren',
      headers: {
        "X-CSRF-Token": Utils.getMetaValue("csrf-token")
      },
      data: zeile.find('input, select').serialize(),
      error: function (data) {
        fehlerFuerZeile(zeile, JSON.parse(data.responseText)[0]);
      },
      success: function () {
        zeile.removeClass('hat-fehler');
        uniqFelderPruefen(zeile);
        nennungDuplikateSuchenFuer(zeile);
      }
    });
  }
}
