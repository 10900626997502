import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-into-view"
export default class extends Controller {
  static values = {
    checkVisibility: { type: Boolean, default: false },
    scollBehavior: { type: String, default: "smooth" },
  };

  async connect() {
    if (this.checkVisibilityValue) {
      // Prüfen, ob das letzte Element vor diesem Element sichtbar ist
      // Kann z.B. genutzt werden um sicherzustellen, dass wir uns am Ende einer Liste befinden, bevor wir nach unten scrollen.
      // (Ohne dieses Vorgehen: Man scrollt bei einer Liste nach oben, per Websocket kommt am Ende der Liste ein Element rein mit diesem Controller und man wird nach unter befördert)
      const containerSelector = this.element.dataset.containerSelector;
      const children = document.querySelector(containerSelector).children;
      const lastChildBeforeThisElement = children[children.length - 2];

      const lastChildIsVisible = await this.elementIsVisible(lastChildBeforeThisElement);

      if (lastChildIsVisible) {
        this.element.scrollIntoView({ behavior: this.scollBehaviorValue });
      }
      return;
    }

    this.element.scrollIntoView({ behavior: this.scollBehaviorValue });
  }

  elementIsVisible(domElement) {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1);
        observer.disconnect();
      });
      observer.observe(domElement);
    });
  }
}
