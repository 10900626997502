const VERANSTALTUNGEN_LADEN = 'VERANSTALTUNGEN_LADEN';
const VERANSTALTUNG_LADEN = 'VERANSTALTUNG_LADEN';

export const veranstaltungenLaden = veranstaltungen => ({ type: VERANSTALTUNGEN_LADEN, veranstaltungen });

export const veranstaltungLaden = veranstaltung => ({ type: VERANSTALTUNG_LADEN, veranstaltung });

export const veranstaltungenReducer = (state, action) => {
  switch (action.type) {
    case VERANSTALTUNGEN_LADEN:
      return {
        ...state,
        liste: action.veranstaltungen,
      };
    case VERANSTALTUNG_LADEN:
      return {
        ...state,
        current: action.veranstaltung,
      };
    default:
      return state || { liste: [], current: {} };
  }
};
