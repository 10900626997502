import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
} from '@mui/material';

import PersonAuswaehlen from '../Dialoge/PersonAuswaehlen';
import { t, t_var } from '../../common/i18n/translate';
import { useAppSelector } from '../redux/hooks';
import './RatingFrage.css';

function PersonFrage({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);
  const [showDialog, setShowDialog] = useState(false);
  const { antwort } = antwortDetails;
  const person = antwortDetails.details || { id: '', name: '' };

  const onDialogClose = (neuePerson) => {
    setShowDialog(false);
    if (neuePerson !== null) {
      if (neuePerson.id) {
        updateAntwort(true, neuePerson);
      } else {
        updateAntwort(false, null);
      }
    }
  };

  return (
    <ListItem divider className="personFrage">
      <PersonAuswaehlen value={person} showDialog={showDialog} onDialogClose={onDialogClose} />

      <ListItemText
        primary={t_var(frage, 'titel')}
        secondary={(
          <Typography component="span" variant="body2">
            {t_var(frage, 'untertitel')}
            <span className="antwort">
              {' '}
              {person.id ? `${person.name}, ${person.ort}` : ''}
            </span>
          </Typography>
        )}
      />
      <ListItemSecondaryAction>
        <Button
          variant={antwort === true ? 'contained' : null}
          color={antwort === true ? 'primary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => setShowDialog(true)}
        >
          {t('ja')}
        </Button>
        <Button
          variant={antwort === false ? 'contained' : null}
          color={antwort === false ? 'secondary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => updateAntwort(false, null)}
        >
          {t('nein')}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default PersonFrage;
