import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useGoogleAnalytics = () => {
  const environment = document.getElementById('environment')?.getAttribute('value');

  // init on mount
  useEffect(() => {
    if (environment === 'production') {
      ReactGA.initialize('G-LJPRGWT7P5');
    }
    if (environment === 'staging') {
      ReactGA.initialize('G-QC5BXRL8Z6');
    }
  }, []);
};

export default useGoogleAnalytics;
