import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert";

// Connects to data-controller="alert"
export default class extends Controller {
  static values = {
    buttonText: { type: String, default: "OK" },
  }

  showAlert({ params: { text } }) {
    alert(text);
  }

  showStyledAlert({ params: { text } }) {
    swal(text, {
      button: this.buttonTextValue,
    });
  }
}
