import React from 'react';
import { Box } from '@mui/material';

import { t } from './i18n/translate';

function PersonKontakt({ person, boxStyle }) {
  return (
    <Box sx={boxStyle}>
      {person.person_name}
      <br />
      <span style={{ fontSize: 'smaller' }}>
        {person.email && (
        <>
          <strong>
            {t('email')}
            :
          </strong>
          {' '}
          <a href={`mailto:${ person.email}`}>{person.email}</a>
          <br />
        </>
        )}
        {person.telefon_privat && (
        <>
          <strong>
            {t('telefon_p')}
            :
          </strong>
          {' '}
          <a href={`tel:${ person.telefon_privat}`}>{person.telefon_privat}</a>
          <br />
        </>
        )}
        {person.telefon_arbeit && (
        <>
          <strong>
            {t('telefon_g')}
            :
          </strong>
          {' '}
          <a href={`tel:${ person.telefon_arbeit}`}>{person.telefon_arbeit}</a>
          <br />
        </>
        )}
        {person.telefon_mobil && (
        <>
          <strong>
            {t('telefon_m')}
            :
          </strong>
          {' '}
          <a href={`tel:${ person.telefon_mobil}`}>{person.telefon_mobil}</a>
          <br />
        </>
        )}
      </span>
    </Box>
  );
}

export function KontaktOkPraesident({ beteiligungen }) {
  return (
    <>
      {beteiligungen.filter(b => b.funktion_id === 11).map((ok_praesident, i) => (
        <PersonKontakt
          key={ok_praesident.id}
          boxStyle={{ marginTop: (i > 0 ? 1 : 0) }}
          person={ok_praesident}
        />
      ))}
    </>
  );
}

export function KontaktOkSekretaer({ beteiligungen }) {
  return (
    <>
      {beteiligungen.filter(b => b.funktion_id === 12).map((ok_sekretaer, i) => (
        <PersonKontakt
          key={ok_sekretaer.id}
          boxStyle={{ marginTop: (i > 0 ? 1 : 0) }}
          person={ok_sekretaer}
        />
      ))}
    </>
  );
}

export function KontaktOasVerantwortlicher({ beteiligungen }) {
  return (
    <>
      {beteiligungen.filter(b => b.funktion_id === 21).map((oas_verantwortlicher, i) => (
        <PersonKontakt
          key={oas_verantwortlicher.id}
          boxStyle={{ marginTop: (i > 0 ? 1 : 0) }}
          person={oas_verantwortlicher}
        />
      ))}
    </>
  );
}

export default PersonKontakt;
