document.addEventListener('turbo:load', function() {
  var $imageCropper = $('.image-cropper');
  if ($imageCropper.length) {
    var Size = function(width, height) {
      this.width = width;
      this.height = height;
      this.ratio = width / height;
    };

    Size.prototype.forceToMax = function(size) {
      return this.forceToMaxWidth(size.width).forceToMaxHeight(size.height)
    };

    Size.prototype.forceToMaxWidth = function(maxWidth) {
      var width = this.width;
      if (this.width > maxWidth) {
        width = maxWidth;
      }
      return new Size(width, this.height).forceToRatio(this.ratio);
    };

    Size.prototype.forceToMaxHeight = function(maxHeight) {
      var height = this.height;
      if (this.width > maxHeight) {
        height = maxHeight;
      }
      return new Size(this.width, height).forceToRatio(this.ratio);
    };

    Size.prototype.forceToRatio = function(wantedRatio) {
      var width = this.width;
      var height = this.height;
      if (this.ratio < wantedRatio) {
        height = (this.height * this.ratio) / wantedRatio;
      }
      else if (this.ratio > wantedRatio) {
        width = (this.width / this.ratio) * wantedRatio;
      }
      return new Size(width, height);
    };

    Size.prototype.scaleBy = function(factor) {
      return new Size(this.width * factor, this.height * factor);
    };

    var aspectRatio        = $('.photo-cropper').data('aspect-ratio') || this.ratio;
    var maxUploadSize      = new Size(500, 500/aspectRatio);
    var maxPreviewSize     = new Size(500, 400);


    var $editorImageUploader = $('#crop-image-modal');
    $editorImageUploader.on('hidden.bs.modal', function() {
      resetUploader();
    });
    function resetUploader() {
      $editorImageUploader.find('input#imageSelect').val('');
      $editorImageUploader.find('.btn.save').hide();
      clearPreview();
    }
    function clearPreview() {
      shutDownCropper();

      var canvas = document.getElementById('image-preview');
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

      canvas = document.getElementById('image-cropped');
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    $('.image-cropper input#imageSelect').on('change', function (event) {
      if (event.target.files) {
        setUpForFile(event.target.files[0]);
        $imageCropper.find('.btn.save').show();
      } else {
        $editorImageUploader.modal('hide');
        resetUploader();
        alert('Ihr Browser unterstütz den Dateiupload nur ungenügend. \nBitte installieren Sie die neueste Version ihres Browsers.');
      }
    });

    function setUpForFile(file) {
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var reader = new FileReader();

        reader.onload = function(e) {
          setUpCroppingFor(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        resetUploader();
      }
    }

    function setUpCroppingFor(imageData) {
      var image = new Image();
      image.src = imageData;
      image.onload = function() {
        copyToCanvas(this);
        setUpCropper();
      };

      $('#apsect-ratio-default').on('click', function () {
        aspectRatio = this.ratio;
        setUpCropper();
      });

      $('#apsect-ratio-16-9').on('click', function () {
        aspectRatio = 16/9;
        setUpCropper();
      });

      $('#apsect-ratio-4-3').on('click', function () {
        aspectRatio = 4/3;
        setUpCropper();
      });

      $('#apsect-ratio-3-2').on('click', function () {
        aspectRatio = 3/2;
        setUpCropper();
      });

      // Ratio umkehren
      $('#apsect-ratio-turn').on('click', function () {
        aspectRatio = aspectRatio ** (-1); // Kehrwert berechnen
        setUpCropper();
      });

      function copyToCanvas(img) {
        var sourceSize = new Size(img.width, img.height);
        var previewSize = sourceSize.forceToMax(maxPreviewSize);

        var previewCanvas = document.getElementById('image-preview');
        previewCanvas.height = previewSize.height;
        previewCanvas.width = previewSize.width;
        previewCanvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height, 0, 0, previewSize.width, previewSize.height);
      }

      function setUpCropper() {
        var previewCanvas = document.getElementById('image-preview');
        var canvasSize = new Size(previewCanvas.width, previewCanvas.height);
        var defaultCrop = canvasSize.forceToRatio(aspectRatio).scaleBy(0.9);
        var x = (canvasSize.width - defaultCrop.width) / 2;
        var y = (canvasSize.height - defaultCrop.height) / 2;

        $(previewCanvas).imgAreaSelect({
          aspectRatio: '' + Math.round(defaultCrop.width) + ':' + Math.round(defaultCrop.height),
          x1: Math.ceil(x),
          y1: Math.ceil(y),
          x2: Math.floor(x + defaultCrop.width),
          y2: Math.floor(y + defaultCrop.height),
          handles: true,
          onSelectEnd: cropSelectionChanged
        });
        var imageFile = document.getElementById('imageSelect').files[0];
        var reader = new FileReader();
        reader.onload = function () {
          var image = new Image();
          image.onload = function () {
            var canvas = document.getElementById('image-cropped');
            canvas.width  = image.width;
            canvas.height = image.height;
            canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);
            prepareSubmitForm();
          };
          image.src = reader.result;
        };
        reader.readAsDataURL(imageFile);
      }
    }

    function shutDownCropper() {
      $('#image-preview').imgAreaSelect({remove: true});
    }

    function cropSelectionChanged(previewCanvas, selection) {
      if (selection.width > 0 && selection.height > 0) {
        var imageFile = document.getElementById('imageSelect').files[0];
        var reader = new FileReader();
        reader.onload = function () {
          var image = new Image();
          image.onload = function () {
            doCrop(this, previewCanvas, selection);
            prepareSubmitForm();
          };
          image.src = reader.result;
        };
        reader.readAsDataURL(imageFile);
      } else {
        $imageCropper.find('.btn.save').hide();
      }
    }

    function doCrop(img, previewCanvas, selection) {
      var scaleFactor = img.width / previewCanvas.width;
      var cropX = selection.x1 * scaleFactor;
      var cropY = selection.y1 * scaleFactor;
      var cropSize = new Size(selection.width, selection.height).scaleBy(scaleFactor);

      var uploadSize = cropSize.forceToMax(maxUploadSize);
      var canvas = document.getElementById('image-cropped');
      canvas.width  = uploadSize.width;
      canvas.height = uploadSize.height;
      canvas.getContext('2d').drawImage(img, cropX, cropY, cropSize.width, cropSize.height, 0, 0, canvas.width, canvas.height);
    }

    function prepareSubmitForm() {
      $('input#image-data').val(document.getElementById('image-cropped').toDataURL("image/jpeg"));
    }
  }
}); 
