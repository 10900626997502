import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Icon, Paper, Typography } from '@mui/material';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import AusschreibungBild from './AusschreibungBild';

import { listAusschreibungBilder, updateVeranstaltung } from '../../../common/modules/api';
import Formular from '../../../common/Formular';
import Spinner from '../../../common/Spinner';
import { t } from '../../../common/i18n/translate';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';
import { benutzerWurdeImpersoniert } from '../../../common/modules/helpers';

function Kopfdaten(props) {
  const [positionen, setPositionen] = useState(null);
  const [bildWarnungen, setBildWarnungen] = useState([]);

  // TODO nicht in props rumfummeln...
  props.formData.id = props.veranstaltung.id;

  const ausschreibungBilderNeuLaden = () => {
    listAusschreibungBilder(props.veranstaltung.id, (response) => {
      setPositionen(response.positionen);
      setBildWarnungen(response.bild_warnungen);
    });
  };

  useEffect(() => {
    ausschreibungBilderNeuLaden();
  }, []);

  if (positionen === null) {
    return <Spinner />;
  }

  const formFields = [
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [{ feldTyp: 'text', name: 'erste_titelzeile' }],
    },
    {
      key: 2,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [{ feldTyp: 'text', name: 'zweite_titelzeile' }],
    },
    {
      key: 3,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [{ feldTyp: 'text', name: 'dritte_titelzeile' }],
    },
  ];

  let bildreiheAnzeigen = positionen.length === 0
    || positionen.includes('bildreihe_7_1')
    || positionen.includes('bildreihe_7_2')
    || positionen.includes('bildreihe_7_3')
    || positionen.includes('bildreihe_7_4')
    || positionen.includes('bildreihe_7_5')
    || positionen.includes('bildreihe_7_6')
    || positionen.includes('bildreihe_7_7');
  let ganzeBreiteAnzeigen = positionen.length === 0 || positionen.includes('ganze_breite');
  let linksRechtsAnzeigen = positionen.length === 0 || positionen.includes('links') || positionen.includes('rechts');

  if (!bildreiheAnzeigen && !ganzeBreiteAnzeigen && !linksRechtsAnzeigen) {
    // seltsame Bilder in der DB (Position "mitte"?) - alle anzeigen
    bildreiheAnzeigen = true;
    ganzeBreiteAnzeigen = true;
    linksRechtsAnzeigen = true;
  }

  let zuKleineBilder = 0;
  (bildWarnungen || []).forEach((item, index) => {
    if (item.zu_klein) {
      zuKleineBilder += 1;
    }
  });

  return (
    <Paper className="padding-1" elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
      <div>
        {
          bildreiheAnzeigen && (
          <div className="flex-row">
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_1" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_2" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_3" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_4" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_5" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_6" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            <div className="flex-1">
              <AusschreibungBild position="bildreihe_7_7" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
          </div>
          )
        }
        {
          ganzeBreiteAnzeigen && (
          <div className="flex-row">
            <div className="flex-1">
              <AusschreibungBild position="ganze_breite" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
          </div>
          )
        }
        <div className="flex-row">
          {
            linksRechtsAnzeigen && (
            <div className="flex-1">
              <AusschreibungBild position="links" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            )
          }
          <div className="flex-2 flex-column align-center justify-center text-center">
            <Typography variant="subtitle1">{props.formData.erste_titelzeile}</Typography>
            <Typography variant="subtitle2">{props.formData.zweite_titelzeile}</Typography>
            <Typography variant="subtitle2">{props.formData.dritte_titelzeile}</Typography>
          </div>
          {
            linksRechtsAnzeigen && (
            <div className="flex-1">
              <AusschreibungBild position="rechts" neuLadenCallback={ausschreibungBilderNeuLaden} bildWarnungen={bildWarnungen} />
            </div>
            )
          }
        </div>
      </div>
      <div className="mt-2">
        <Formular
          disabled={
            props.veranstaltung.id &&
            !benutzerWurdeImpersoniert() && !(
              props.veranstaltung.status === 'in_erfassung' ||
              props.veranstaltung.status === 'zur_voranzeige' ||
              props.veranstaltung.status === 'in_ausschreibung'
            )
          }
          formId="kopfdaten"
          fields={formFields}
          defaultValues={props.formData}
          handleSubmit={updateVeranstaltung}
        />
      </div>
      {(zuKleineBilder > 0) && (
      <div className="flex-row" style={{ border: '1px solid red', marginTop: '20px', padding: '10px 10px 0 10px' }}>
        <div className="flex-1" style={{ maxWidth: '15%' }}>
          <span style={{ color: 'rgba(128, 128, 128, 0.8)', fontSize: '40px' }}>
            <Icon fontSize="inherit">warning</Icon>
            <PhotoSizeSelectLargeIcon fontSize="inherit" />
          </span>
        </div>
        <div className="flex-1">
          <Typography display="inline">
            {zuKleineBilder}
            {' '}
            {zuKleineBilder === 1 ? t('bilder_zu_klein_1') : t('bilder_zu_klein_n')}
          </Typography>
        </div>
      </div>
      )}
    </Paper>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  formData: state.veranstaltungen.current.forms.kopfdaten,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Kopfdaten);
