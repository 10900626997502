import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';

import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { updatePruefung, deletePruefung, downloadVeranstaltung } from '../../../common/modules/api';
import { objIsEmpty } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';
import { veranstaltungLaden } from '../../store/modules/veranstaltungen';
import { pruefungenTabelle } from '../AusschreibungVorschau/pruefungsDetails';
import { benutzerWurdeImpersoniert } from '../../../common/modules/helpers';

import {
  dressurFelder,
  fahrenFelder,
  concoursFelder,
  springenFelder,
  voltigeFelder,
  paraDressageFelder,
  disziplinFallbackFelder,
  enduranceFelder,
} from './disziplin_felder';

function PruefungBearbeiten(props) {
  const params = useParams();
  const [aktuelleDisziplin, setAktuelleDisziplin] = useState(NaN);
  const [popoverAnker, setPopoverAnker] = useState(null);
  const [selektiertePruefung, setSelektiertePruefung] = useState({ datum: new Date(props.veranstaltung.von) });

  const { veranstaltungId } = params;
  const { pruefungId } = params;

  useEffect(() => {
    downloadVeranstaltung(veranstaltungId, newData => props.veranstaltungLaden(newData));
  }, [veranstaltungId]);

  if (objIsEmpty(props.veranstaltungen) || objIsEmpty(props.veranstaltungen.current) || objIsEmpty(props.stammdaten)) return <Spinner />;

  if (pruefungId && (!selektiertePruefung.id || pruefungId.toString() !== selektiertePruefung.id.toString())) {
    props.veranstaltung.forms.pruefungen.forEach((pruefung) => {
      if (pruefung.id.toString() === pruefungId.toString()) {
        setSelektiertePruefung(pruefung);
      }
    });
  }

  let showForm = selektiertePruefung.titel ? 'titel' : 'pruefung';
  if (params.pruefungId === 'neue_pruefung') {
    showForm = 'pruefung';
  } else if (params.pruefungId === 'neue_titelzeile') {
    showForm = 'titel';
  }

  const handleDelete = selektiertePruefung.id ? () => deletePruefung(props.veranstaltung.id, selektiertePruefung.id, (antwort) => {
    window.location.href = `#/${props.veranstaltung.id}/edit/pruefungen`;
    window.location.reload();
  }) : null;

  const handleSubmit = (data) => {
    updatePruefung(props.veranstaltung.id, data, (antwort) => {
      const neueUri = `#/${props.veranstaltung.id}/edit/pruefungen`;
      if (window.location.hash === neueUri) {
        window.location.reload();
      } else {
        window.location.href = neueUri;
        window.location.reload();
      }
    });
  };

  const cancelForm = () => {
    window.location.href = `#/${props.veranstaltung.id}/edit/pruefungen`;
  };

  const popoverAnzeigen = (event) => {
    setPopoverAnker(event.target);
  };
  const popoverSchliessen = () => {
    setPopoverAnker(null);
  };

  const popoverFuerDisziplin = (disziplin_id) => {
    let icon = null;
    if (disziplin_id === 2 || disziplin_id === 6 || disziplin_id === 3) { // dressur, springen, fahren
      icon = (
        <Button id="popover-warnung-button" variant="contained" onClick={popoverAnzeigen}>
          <Icon style={{ fontSize: '30px', cursor: 'pointer' }} color="error">warning</Icon>
        </Button>
      );
      if (disziplin_id !== aktuelleDisziplin) {
        setAktuelleDisziplin(disziplin_id);
      }
    }
    let popoverInhalt = null;
    if (disziplin_id === 2) { // dressur
      popoverInhalt = (
        <div style={{ padding: '15px' }}>
          <Typography>{t('pruefung_warnung_dressur_1')}</Typography>
          <ul>
            <li><Typography>{t('pruefung_warnung_dressur_2')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_3')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_4')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_5')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_6')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_7')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_8')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_9')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_dressur_10')}</Typography></li>
          </ul>
          <Typography>{t('pruefung_warnung_dressur_11')}</Typography>
          <div>&nbsp;</div>
          <Typography>{t('pruefung_warnung_dressur_12')}</Typography>
        </div>
      );
    }
    if (disziplin_id === 6) { // springen
      popoverInhalt = (
        <div style={{ padding: '15px' }}>
          <Typography>{t('pruefung_warnung_springen_1')}</Typography>
          <ul>
            <li><Typography>{t('pruefung_warnung_springen_2')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_springen_3')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_springen_4')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_springen_5')}</Typography></li>
          </ul>
        </div>
      );
    }
    if (disziplin_id === 3) { // Fahren
      popoverInhalt = (
        <div style={{ padding: '15px' }}>
          <Typography>{t('pruefung_warnung_ca_1')}</Typography>
          <ul>
            <li><Typography>{t('pruefung_warnung_ca_2')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_ca_3')}</Typography></li>
            <li><Typography>{t('pruefung_warnung_ca_4')}</Typography></li>
          </ul>
        </div>
      );
    }
    let popover = null;
    if (disziplin_id === 2 || disziplin_id === 6 || disziplin_id === 3) {
      popover = (
        <Popover
          id={disziplin_id}
          open={popoverAnker !== null}
          anchorEl={popoverAnker}
          onClose={popoverSchliessen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ float: 'right' }}>
            <IconButton onClick={() => setPopoverAnker(null)} className="close-btn">
              <Icon>close</Icon>
            </IconButton>
          </div>
          {popoverInhalt}
        </Popover>
      );
    }
    return (
      <>
        {icon}
        {popover}
      </>
    );
  };

  const formularTitelField = {
    key: 'titel',
    feldTyp: 'dynamic_component',
    dynamicComponent: fieldValues => (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <Typography variant="h6" style={{ marginBotton: '50px' }}>
            {t(`${showForm === 'titel' ? 'titelzeile' : 'pruefung'}_${selektiertePruefung.id ? 'bearbeiten' : 'erstellen'}`)}
          </Typography>
          <div>&nbsp;</div>
        </div>
        {popoverFuerDisziplin(parseInt(fieldValues.disziplin_id))}
      </Grid>
    ),
  };

  const maxNennungenFeld = props.veranstaltung.nennvariante === 'nennphase' ? {
    feldTyp: 'text',
    name: 'max_nennungen',
    required: true,
  } : {
    feldTyp: 'whitespace',
  };
  const formFields = [
    {
      feldTyp: 'hidden',
      name: 'id',
    },
    formularTitelField,
    {
      feldTyp: 'karte',
      kartenTitel: t('vorschau'),
      displayCondition: values => !!values.id,
      children: [
        {
          key: 'vorschau',
          feldTyp: 'component',
          component: (
            <div className="ausschreibung-vorschau">
              {pruefungenTabelle([selektiertePruefung], props.veranstaltung.locale)}
            </div>
          ),
        },
      ],
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('allgemein'),
      children: [
        {
          key: 'allg1',
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            {
              feldTyp: 'text',
              name: 'nummer',
              required: true,
            },
            {
              feldTyp: 'datepicker',
              name: 'datum',
              required: true,
            },
            {
              feldTyp: 'autocomplete',
              name: 'disziplin_id',
              label: t('disziplin'),
              options: props.stammdaten.disziplinen,
              optionValue: 'id',
              optionLabel: 'label',
              required: true,
              disabled: !!selektiertePruefung.id,
            },
            {
              feldTyp: 'text',
              name: 'index',
              type: 'number',
              required: true,
              defaultValue: props.veranstaltung.forms.pruefungen.length + 1,
            },
          ],
        },
        {
          key: 'allg2',
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            maxNennungenFeld,
            {
              feldTyp: 'text',
              name: 'nenngeld',
              required: true,
            },
            {
              feldTyp: 'text',
              name: 'preise',
            },
            {
              feldTyp: 'autocomplete',
              name: 'modus_id',
              label: t('modus'),
              required: true,
              options: props.stammdaten.modus,
              optionValue: 'id',
              optionLabel: 'label',
            },
          ],
        },
      ],
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 1,
      children: enduranceFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 2,
      children: dressurFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 3,
      children: fahrenFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 4,
      children: concoursFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 6,
      children: springenFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 9,
      children: voltigeFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => parseInt(values.disziplin_id) === 12,
      children: paraDressageFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('disziplin'),
      displayCondition: values => !([1, 2, 3, 4, 6, 9, 12].includes(parseInt(values.disziplin_id))),
      children: disziplinFallbackFelder(props),
    },
    {
      feldTyp: 'karte',
      kartenTitel: t('nachnennphase'),
      displayCondition: values => selektiertePruefung.nachnennungen_anzeigen,
      children: [
        {
          key: 'nach1',
          feldTyp: 'wrapper',
          className: 'flex-row',
          children: [
            {
              feldTyp: 'text',
              name: 'nenngeld2',
              type: 'number',
            },
            {
              feldTyp: 'text',
              name: 'max_nennungen',
              type: 'number',
            },
            {
              feldTyp: 'text',
              name: 'anzahl_veranstalter_startplaetze',
              type: 'number',
            },
          ],
        },
      ],
    },
  ];

  const titleFormFields = [
    {
      feldTyp: 'hidden',
      name: 'id',
    },
    {
      feldTyp: 'hidden',
      name: 'nenngeld',
      defaultValue: 0,
    },
    formularTitelField,
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          feldTyp: 'text',
          name: 'index',
          type: 'number',
          parentClass: 'mw-100',
          required: true,
          defaultValue: props.veranstaltung.forms.pruefungen.length + 1,
        },
        {
          feldTyp: 'text',
          name: 'titel',
          required: true,
        },
      ],
    },
  ];

  return (
    <div className="flex-row width-100 no-margin">
      <div className="margin-1 flex-1">
        <Formular
          disabled={
            props.veranstaltung.id &&
            !benutzerWurdeImpersoniert() &&
            !(props.veranstaltung.status === 'in_erfassung' || props.veranstaltung.status === 'zur_voranzeige' || props.veranstaltung.status === 'in_ausschreibung')
          }
          formId={`pruefung-${ selektiertePruefung.id}`}
          fields={showForm === 'titel' ? titleFormFields : formFields}
          defaultValues={selektiertePruefung}
          handleSubmit={handleSubmit}
          handleCancel={cancelForm}
          handleDelete={handleDelete}
          wrapInPaper
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PruefungBearbeiten);
