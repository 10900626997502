import React from 'react';
import {
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

function LanguageToggle({ links, lang: currentLang }) {
  const handleLang = (event, newLang) => {
    window.location = newLang;
  };

  return (
    <ToggleButtonGroup
      color="active"
      value={currentLang}
      exclusive
      onChange={handleLang}
      size="small"
    >
      { Object.entries(links).map(([langId, langLink]) => (
        <ToggleButton key={langId} value={langLink} selected={langId === currentLang}>{langId}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default LanguageToggle;
