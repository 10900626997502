document.addEventListener("turbo:load", function() {
  $('body').on('change', 'input.format-phone', function(e) {
    var $input = $(this);
    var number = $input.val();
    number = number.replace(/\([0-9]{0,}\)/g, ''); // (0) entfernen
    number = number.replace(/[^+,0-9,\s]/g, ''); // alles ausser Nummern, Leerzeichen und + entfernen


    if (number.indexOf('+41') == 0) {
      number = number.replace(/\s/g, ''); // Leerzeichen entfernen
      number = [number.substring(0, 3),
                number.substring(3, 5),
                number.substring(5, 8),
                number.substring(8, 10),
                number.substring(10, 12)];
      number = number.join(' ');
    }
    else if (number.indexOf('0') == 0) {
      number = number.replace(/\s/g, ''); // Leerzeichen entfernen
      number = [number.substring(0, 3),
                number.substring(3, 6),
                number.substring(6, 8),
                number.substring(8, 10)];
      number = number.join(' ');
    }
    $input.val(number.trim());
  });
});
