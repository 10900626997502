import React from 'react';
import { t } from '../../../common/i18n/translate';
import { default as MDTextField } from '@mui/material/TextField';
import {
  Grid,
  Typography,
} from '@mui/material';

const gewinnpunkteValue = (fieldValues, props, gewinnpunkteBezeichnung) => {
  const gewinnpunkteVomBenutzer = fieldValues[gewinnpunkteBezeichnung];

  const alleGewinnpunkteDerKategorie = props.stammdaten.pruefungskategorien_gewinnpunkte.find(k => k.id === fieldValues.kategorie_id);
  const gewinnpunkteDerKategorie = alleGewinnpunkteDerKategorie?.[gewinnpunkteBezeichnung];

  return gewinnpunkteVomBenutzer || gewinnpunkteDerKategorie || 0
}

export const gewinnpunkteFelder = (props) => [
  {
    key: "gwp_beschraenkungen_brevet",
    feldTyp: 'dynamic_component',
    dynamicComponent: (fieldValues, setFieldValues) => (
      <Grid container spacing={2} className='gwp-grid'>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography>
            {t('brevet')}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <Typography className='gwp-felder-title'>
                {t('reiter')}
              </Typography>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_brevet_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_brevet_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_brevet_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_brevet_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <Typography className='gwp-felder-title'>
                {t('pferd')}
              </Typography>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_brevet_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_brevet_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_brevet_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_brevet_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <Typography className='gwp-felder-title'>
                {t('paar')}
              </Typography>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_brevet_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_brevet_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_brevet_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_brevet_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    displayCondition: values => values.pruefung_hat_gwp_beschraenkungen,
  },
  {
    key: "gwp_beschraenkungen_regional",
    feldTyp: 'dynamic_component',
    dynamicComponent: (fieldValues, setFieldValues) => (
      <Grid container spacing={2} className='gwp-grid'>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography>
            {t('regional')}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_regional_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_regional_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_regional_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_regional_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_regional_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_regional_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_regional_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_regional_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_regional_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_regional_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_regional_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_regional_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    displayCondition: values => values.pruefung_hat_gwp_beschraenkungen,
  },
  {
    key: "gwp_beschraenkungen_national",
    feldTyp: 'dynamic_component',
    dynamicComponent: (fieldValues, setFieldValues) => (
      <Grid container spacing={2} className='gwp-grid'>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography>
            {t('national')}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_national_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_national_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_reiter_national_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_reiter_national_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_national_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_national_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_pferd_national_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_pferd_national_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="gwp-felder-von-bis">
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_national_minimal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_national_minimal: value,
                    });
                  }}
                  fullWidth
                />
                <div className="gwp-felder-trennzeichen">
                  /
                </div>
                <MDTextField
                  value={gewinnpunkteValue(fieldValues, props, "gewinnpunkte_paar_national_maximal")}
                  onChange={(e) => {
                    e.persist();
                    let value = e.target.value;
                    if (value === "") {
                      value = null;
                    }
                    setFieldValues({
                      ...fieldValues,
                      gewinnpunkte_paar_national_maximal: value,
                    });
                  }}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    displayCondition: values => values.pruefung_hat_gwp_beschraenkungen,
  }
]
