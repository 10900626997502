import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-5-modal"
export default class extends Controller {
  static values = {
    autoShow: { type: Boolean, default: true },
    preventClose: { type: Boolean, default: false }
  }

  connect() {
    this.modal = new bootstrap.Modal(this.element)

    if (this.autoShowValue) {
      this.show()
    }

    if (this.preventCloseValue) {
      this.element.addEventListener("hidePrevented.bs.modal", (event) => {
        event.preventDefault()
      })
    }

    this.boundhideModal = this.hide.bind(this);
    window.addEventListener("equis:hide-bs-modal", this.boundhideModal);
  }
  
  disconnect() {
    window.removeEventListener("equis:hide-bs-modal", this.boundhideModal);
  }

  show() {
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  close() {
    this.hide()
  }
}
