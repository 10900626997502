import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'
import { mobileAppUniqueDeviceId } from "../../packs/global/utils";
import { pushNotificationTokenCacheLoeschen } from "../../packs/turbo/native_actions/push_notification_tokens";

// Dies ist unser Bridge Controller, mit dem wir Nachrichten an die Turbo Native App senden können.
// Wichtig: Dies hat nichts mit der Strada Bridge zu tun.

// Connects to data-controller="native--bridge"
export default class extends Controller {
  static values = {
    action: String,
    payload: Object,
    autoSend: { type: Boolean, default: false },
  };

  connect() {
    if (this.autoSendValue) {
      this.postJSToNative();
    }
  }

  // Generische Möglichkeit eine Nachricht an die Turbo Native App zu senden
  postJSToNative() {
    window.bridge.postJSToNative(this.actionValue, this.payloadValue);
  }

  // Wenn eine Turbo Native App abgemeldet werde soll, machen wir manuell einen request auf /mobile_app_abmelden um alle MobileAppBezogene Daten zu löschen
  // anschliessend informieren wir die App per JS Bridge um der App die Möglichkeit zu geben, gespeicherte Daten zu löschen.
  // Zu guter letzt leiten wir den Benutzer auf die Login Seite weiter.
  async signOut({ params: { mitRedirect } }) {
    const response = await post('/mobile_app_abmelden', { body: { unique_device_id: mobileAppUniqueDeviceId } })
    if (response.ok) {
      pushNotificationTokenCacheLoeschen();
      window.bridge.postJSToNative("signedOut");
      if (mitRedirect === true) {
        Turbo.visit("/benutzer/sign_in");
      }
    }
  }
}
