import React from 'react';
import { connect } from 'react-redux';

import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';

import './index.scss';
import { t, getLanguage } from '../../../common/i18n/translate';

import { okCallbackFallback, veranstalterRechteAnfordern } from '../../../common/modules/api';
import { objIsEmpty } from '../../../common/modules/helpers';
import Spinner from '../../../common/Spinner';
import Formular from '../../../common/Formular';

function RechteBeantragen(props) {
  if (objIsEmpty(props.stammdaten)) return <Spinner />;

  let formularOderTabelle = null;

  if (props.stammdaten.berechtigungen.length > 0) {
    formularOderTabelle = (
      <>
        <Typography>{t('veranstaltungen_rechte_liste')}</Typography>
        <div className="veranstalter-berechtigungen">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('veranstalter')}
                </TableCell>
                <TableCell>
                  {t('regionalverband')}
                </TableCell>
                <TableCell>
                  {t('akzeptiert')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              props.stammdaten.berechtigungen.map(berechtigung => (
                <TableRow key={berechtigung.veranstalter_id}>
                  <TableCell>{berechtigung.veranstalter}</TableCell>
                  <TableCell>{berechtigung.regionalverband}</TableCell>
                  <TableCell>{berechtigung.akzeptiert ? t('ja') : t('nein')}</TableCell>
                </TableRow>
              ))
            }
            </TableBody>
          </Table>
        </div>
        <Typography><em>{t('veranstaltungen_rechte_warten')}</em></Typography>
      </>
    );
  } else {
    const formFields = [
      {
        key: '0',
        feldTyp: 'autocomplete',
        name: 'regionalverband_id',
        label: t('regionalverband'),
        options: props.stammdaten.regionalverbaende,
        optionValue: 'id',
        optionLabel: 'label',
        required: true,
      },
      {
        key: '1',
        feldTyp: 'autocomplete',
        name: 'veranstalter_id',
        label: t('veranstalter'),
        options: props.stammdaten.alle_veranstalter,
        optionValue: 'id',
        optionLabel: 'name',
        required: true,
        filterOptions: (data, options) => {
          if (data.regionalverband_id) {
            return options.filter((v, index) => v.regionalverband_id === data.regionalverband_id);
          }
          return options;
        },
        additionalChanges: (value) => {
          let neuerRegionalverband;
          props.stammdaten.alle_veranstalter.forEach((v, index) => {
            if (v.id === value) {
              neuerRegionalverband = v.regionalverband_id;
            }
          });
          return { regionalverband_id: neuerRegionalverband };
        },
      },
      {
        key: '2',
        feldTyp: 'text',
        name: 'kommentar',
        multiline: true,
        required: true,
      },
    ];

    formularOderTabelle = (
      <>
        <Typography>{t('veranstaltungen_rechte_beantragen')}</Typography>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <Formular
          fields={formFields}
          disabled={false}
          submitButtonKey="rechte_anfordern"
          handleSubmit={(daten) => {
            if (daten.regionalverband_id && daten.veranstalter_id && daten.kommentar) {
              veranstalterRechteAnfordern(daten.regionalverband_id, daten.veranstalter_id, daten.kommentar, okCallbackFallback);
            }
          }}
          handleCancel={() => (document.location = '/')}
          defaultValues={{}}
        />
      </>
    );
  }

  return (
    <div className="rechte-beantragen">
      <Paper className="padding-1">
        <Typography variant="h1">{t('veranstaltungen')}</Typography>
        <Typography>{t('veranstaltungen_nicht_ohne_rechte')}</Typography>
        {formularOderTabelle}
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
  stammdaten: state.stammdaten[getLanguage()],
});

export default connect(mapStateToProps, null)(RechteBeantragen);
