import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-2-modal"
export default class extends Controller {
  static values = {
    autoShow: { type: Boolean, default: true }
  }

  connect() {
    if (this.autoShowValue) {
      this.show()
    }
  }

  show() {
    $(this.element).modal('show')
  }
}
