import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

document.addEventListener("turbo:load", function() {
  initTooltips();
})

document.addEventListener("tooltips-initialisieren", function() {
  initTooltips();
})

function initTooltips() {
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}
