import React, { useEffect, useMemo } from 'react';
import { CircularProgress, Typography, Paper, List, ListItem, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import Frage from './Fragen/Frage';
import { loadJuryrapport, completeJuryrapport } from './redux/Juryrapport';
import { t, t_var } from '../common/i18n/translate';
import { useAppSelector, useAppDispatch } from './redux/hooks';
import './Juryrapport.css';

function Juryrapport() {
  const {
    veranstaltung,
    rapport,
    fragen,
    antworten,
    selfserviceBenutzer,
    error,
    isLoading,
  } = useAppSelector(state => state.juryrapport);
  const dispatch = useAppDispatch(state => state.juryrapport);
  const { juryrapport_id: rapportId } = useParams();
  const weitereInfos = useMemo(() => {
    if (veranstaltung == null) {
      return [];
    }
    const infos = [
      {
        label: t('disziplin'),
        value: veranstaltung.disziplinen.map(d => t_var(d, 'bezeichnung')).join(', '),
      },
      {
        label: t('status'),
        value: <span className="status">{rapport.status}</span>,
      },
    ];
    veranstaltung.beteiligungen.filter(b => b.funktion.code === 'JP').forEach((b) => {
      infos.push({
        label: t_var(b.funktion, 'bezeichnung'),
        value: `${b.name}, ${b.ort}`,
      });
    });

    selfserviceBenutzer.forEach((b) => {
      infos.push({
        label: t('verantwortlich'),
        value: `${b.vorname} ${b.nachname}, ${b.ort}`,
      });
    });

    return infos;
  }, [veranstaltung, rapport, selfserviceBenutzer]);
  const alleFragenAusgefuellt = useMemo(() => fragen.filter(f => f.typ !== 'titel')
    .map(f => antworten.find(a => a.frage_id === f.id))
    .every(a => a !== null && a !== undefined), [fragen, antworten]);
  const veranstaltungInfoText = useMemo(() => (veranstaltung ? [
    veranstaltung.von_bis,
    `${veranstaltung.disziplinen.map(d => d.neuer_code).join(', ')} / ${veranstaltung.modus_code}`,
    `${veranstaltung.ort} ${veranstaltung.kanton}`,
  ].join(' | ') : ''), [veranstaltung]);

  useEffect(() => {
    loadJuryrapport(rapportId)(dispatch);
  }, [rapportId]);

  if (error) {
    return (
      <center style={{ flex: 1 }}>
        <Typography color="error" variant="h4">
          {error}
        </Typography>
      </center>
    );
  }

  if (isLoading) {
    return (
      <div className="circularProgress">
        <CircularProgress />
      </div>
    );
  }

  const statusClass = rapport.status === 'offen' ? '' : 'rapport-readonly';
  return (
    <div className={`juryrapport-fragen-wrapper ${statusClass}`}>
      <Paper className="juryrapport-fragen">
        <div className="juryrapport-head">
          <div className="flex-row">
            <Typography variant="h4" color="primary">
              {t('juryrapport')}
            </Typography>
            <div className="flex-1" />
            <Typography variant="h6" className="flex-self-align-end">
              {veranstaltung.sequenz_nummer || ''}
            </Typography>
          </div>
          <Typography variant="subtitle1">
            {veranstaltungInfoText}
          </Typography>

          <table className="weitere-felder">
            <tbody>
              {weitereInfos.map((infos, index) => (
                <tr key={infos.label}>
                  <td style={{ paddingRight: '0.5em' }}>
                    <Typography variant="body2">
                      {(weitereInfos[index - 1] || {}).label === infos.label ? '' : infos.label}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="subtitle2">{infos.value}</Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <List>
          <ListItem divider />
          {fragen.map(frage => <Frage key={frage.id} frage={frage} />)}
        </List>
        <center className="abschliessenWrapper">
          {rapport.status === 'offen' && (
            <Button
              variant="contained"
              color="primary"
              disabled={!alleFragenAusgefuellt}
              onClick={() => completeJuryrapport(rapportId)(dispatch)}
            >
              {t('juryrapport_abschliessen')}
            </Button>
          )}
        </center>
      </Paper>
    </div>
  );
}

export default Juryrapport;
