import React from 'react';
import { Outlet } from 'react-router-dom';
import { Paper } from '@mui/material';

import EventsTabNav from './EventsTabNav';

function RootScreen() {
  return (
    <Paper>
      <EventsTabNav />
      <div className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1"><Outlet /></div>
      </div>
    </Paper>
  );
}

export default RootScreen;
