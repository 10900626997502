import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { t } from '../../common/i18n/translate';

function UnbekannteFrage({ frage }) {
  return (
    <ListItem divider dense>
      <Typography style={{ color: 'red' }}>
        {t('unbekannte_frage')}
        {' '}
        {frage.typ}
      </Typography>
    </ListItem>
  );
}

export default UnbekannteFrage;
