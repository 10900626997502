document.addEventListener("turbo:load", function() {
  $('table.table-sortable').sortableTable();
  init_popover();
});


function init_popover() {
  var $window = $(window);
  try {
    // Bootstrap 3
    $('.info-popover')
    .popover({
      trigger: 'hover',
      html: function(popOverElement, sourceElement) {
        return !!sourceElement.data('popover-html');
      },
      placement: function(popOverElement, sourceElement) {
        if ($(sourceElement).data('placement')) {
          return $(sourceElement).data('placement');
        }
        var windowAbsoluteHeight = $window.height() + $window.scrollTop(),
            popOverAbsoluteHeight = $(sourceElement).position().top + 300;
        // Da das PopOver-Element dynamisch erstellt wird resp. noch ohne Inhalt ist...
        // können wir die effektive Höhe nicht bestimmen, darum wird hier der Wert von 300 als Höhe verwendet
        if(popOverAbsoluteHeight > windowAbsoluteHeight) {
          return 'top';
        }
        return 'bottom';
      }
    });
  } catch (e) {
    // Bootstrap 5
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
  }
}
