import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="footer"
export default class extends Controller {
  static targets = ['footer', 'footerHandle']
  static values = {
    autoShow: { type: Boolean, default: false },
    preventClosing: { type: Boolean, default: false }
  }

  connect() {
    if (!this.hasFooterTarget) return

    useClickOutside(this, { element: this.footerTarget })

    if (this.autoShowValue) {
      this.showFooter()
    }
  }

  clickOutside(event) {
    if (!this.footerTarget.classList.contains("hide-footer")) {
      this.hideFooter()
    }
  }

  showFooter() {
    this.footerTarget.classList.remove("hide-footer");
    this.footerTarget.style.display = "block";

    // bei kleinen Bildschirmen den Sidedrawer schliessen
    if (window.innerWidth <= 768) {
      const event = new Event('close-sidedrawer');
      window.dispatchEvent(event);
    }
  }

  hideFooter() {
    if (this.preventClosingValue) {
      return
    }

    this.footerTarget.classList.add("hide-footer");

    setTimeout(() => {
      this.footerTarget.style.display = "none";
    }, 200);
  }
}
