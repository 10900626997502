jQuery.fn.showAjaxLoadingIndicator = function(){
  $(this).replaceWith('<div class="ajax-loading-indicator"><i class="fa-solid fa-spinner"></i></div>')
}

document.addEventListener("turbo:load", function() {
  initAutocompleters();
});

document.addEventListener("autocompleters-initialisieren", function() {
  initAutocompleters();
});

function initAutocompleters() {
  $('.person_autocompleter').each(function(index, suchfeld) {
    var $suchfeld = $(suchfeld);
    $suchfeld.autocomplete({
      source: function(request, response){
        var src = $suchfeld.data('autocomplete-url') + '?term=' + request.term;
        if (this.element.hasClass('mit_selfservice_benutzer')) {
          src += '&mit_selfservice_benutzer=true';
        }
        if (this.element.hasClass('braucht_fei_eintrag')) {
          src += '&braucht_fei_eintrag=true';
        }
        $.get(src).then(response)
      },
      minLength: 2,
      select: function(event, ui) {
        const customEvent = new CustomEvent("autocomplete:select");
        event.target.dispatchEvent(customEvent);
        updatePersonFields($(this), ui.item.id, ui.item.adresse_block_komplett, ui.item.im_ausland, ui.item.hat_mail);
      }
    });
  });

  $('.person_name_autocompleter').autocomplete({
    source: "/registration/autocomplete_person_by_name",
    minLength: 3,
    open: function() { $('.ui-autocomplete.ui-menu').width(500) },
    select: function(event, ui) {
      var hiddenFieldId = $(this).data('hidden-field-id');
      if (hiddenFieldId) {
        $(hiddenFieldId).val(ui.item.id);
      }
    }
  });

  $('.pferd_autocompleter').autocomplete({
    source: function(request, response){
      var src = '/pferde/autocomplete?term=' + request.term;
      if (this.element.hasClass('nur_aktive')) {
        src += '&nur_aktive=true';
      }
      if (this.element.hasClass('nur_lebende')) {
        src += '&nur_lebende=true';
      }
      if (this.element.hasClass('braucht_fei_eintrag')) {
        src += '&braucht_fei_eintrag=true';
      }
      if (this.element.hasClass('pferde_nur_internationale_nennungen')) {
        src += '&pferde_nur_internationale_nennungen=true';
      }
      $.get(src).then(response).catch(function(){ response(null); });
    },
    minLength: 2,
    select: function(event, ui) {
      var hiddenFieldId = $(this).data('hidden-field-id');
      if (hiddenFieldId) {
        $(hiddenFieldId).val(ui.item.id);
      }
    }
  });

  $('.reiter_autocompleter').autocomplete({
    source: function(request, response){
      var src = '/reiter/autocomplete?term=' + request.term;
      if (this.element.hasClass('braucht_fei_eintrag')) {
        src += '&braucht_fei_eintrag=true';
      }
      $.get(src).then(response).catch(function(){ response(null); });
    },
    minLength: 2,
    select: function(event, ui) {
      var hiddenFieldId = $(this).data('hidden-field-id');
      if (hiddenFieldId) {
        $(hiddenFieldId).val(ui.item.id);
      }
    }
  });

  $('.domain-autocompleter-plz').autocomplete({
    source: "/plz",
    minLength: 1,
    delay: 0,
    autoFocus: true,
    select: function(event, ui) {
      var data = $(this).data();
      $.each(['plz', 'ort', 'kanton', 'land'], function(index, value) {
        var field_id = data[value + 'FieldId'];
        if(field_id) {
          var $oldval = $(field_id).val();
          var $newval = ui.item[value];
          if($newval !== $oldval) {
            $(field_id).val($newval);
            $(field_id).trigger('autofill');
          }
        }
      });
    }
  });

  $('.veranstalter_person_autocompleter').autocomplete({
    source: "/personen/autocomplete",
    select: function(event, ui) {
      var hiddenFieldId = $(this).data('hidden-field-id');
      if (hiddenFieldId) {
        $(hiddenFieldId).val(ui.item.id);
      }
    }
  });

  $('.autocomplete-reset')
      .on('click', function() {
        var $form = $(this).parents('form');
        var $autocompleteInput = $(this).parents('.controls').find('.ui-autocomplete-input');
        var $hiddenField    = $form.find($autocompleteInput.data('hidden-field-id'));
        var $adresseField   = $form.find($autocompleteInput.data('adresse-field-id'));
        var $adresseBtn     = $form.find($autocompleteInput.data('adresse-field-id') + ' + .adress_aenderungs_antrag_button').find('a');
        var $imAuslandField = $form.find($autocompleteInput.data('im-ausland-field-id'));

        $autocompleteInput.val('');
        $hiddenField.val('');
        $adresseField.val('');
        $adresseBtn.hide();
        $imAuslandField.selectedIndex = 1;
      });


  var old_searchterm = '';
  $('.verein_suche').on('keyup', function () {
    var searchterm = $(this).val();
    if (searchterm.length > 2) {
      if (searchterm != old_searchterm) {
        old_searchterm = searchterm;
        $.get('/vereine/suche', {
          term: searchterm
        }).done(function (data) {
          $('.verein_resultate').html(data);
        }).fail(function (error) {
          $('.verein_resultate').html(error);
        });
      }
    } else {
      $('.verein_resultate').html('');
    }
  });
  $('#vereindaten').on('click', '.verein-result-table tr.verein', function () {
    var row = $(this);
    $('.verein-result-table tr.selected').removeClass('selected');
    row.addClass('selected');
    $('#formular_objekt_verein_id').val(row.data('verein-id'));
    $('#edit_formular_objekt').submit();
  });
  $('#vereindaten').on('click', '.verein-result-table tr.verein a', function (event) {
    event.stopPropagation();
  });
  $('#selbstregistrierungs-verein').on('click', '.verein-result-table tr.verein', function () {
    // Code fast identisch auch in selfservice/app/views/selbstregistrierung/new.html.haml
    var row = $(this);
    $('tr.verein').hide();
    $('.vereins-control-group').hide();
    row.addClass('selected');
    row.show();
    if(!row.hasClass('has-trash')) {
      row.find('td:last').prepend('<span class="pull-right"><a href="javascript:resetVereinID()"><span class="fa-solid fa-trash text-error"></span></a></span>');
      row.addClass('has-trash')
    }
    $('#verein_id').val(row.data('verein-id'));
  });
  $('#selbstregistrierungs-verein').on('click', '.verein-result-table tr.verein a', function (event) {
    event.stopPropagation();
  });


  var $erweiterteSucheButtons = $('form a.erweiterte-suche');
  if($erweiterteSucheButtons.length > 0) {
    $erweiterteSucheButtons.on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      var $this = $(this);
      var $suchDialog = $('#erweiterte-suche');

      $suchDialog.data('id-field-id',          $this.data('id-field-id'));
      $suchDialog.data('label-field-id',       $this.data('label-field-id'));
      $suchDialog.data('erweiterte-suche-url', $this.data('erweiterte-suche-url'));

      $suchDialog.find('#vorname').val('');
      $suchDialog.find('#nachname').val('');
      $suchDialog.find('#ort').val('');

      $suchDialog.find('div.resultate').html('');
      $suchDialog.modal('show');
    });
    $.ajax({
      url: '/personen/erweiterte_suche', // Das Template nehmen wir immer von dort, erweiterte-suche-url ist hier egal.
      cache: false,
      success: function(html) {
        $('body').append(html);
        var $suchDialog = $('#erweiterte-suche');
        $suchDialog.find('a.do-erweiterte-suche').on('click', function() {
          erweiterteSucheAbsetzen();
        });
        $suchDialog.find('input[type="text"]').on('keydown', function(event) {
          if(event.which == 13) { // Enter
            erweiterteSucheAbsetzen();
          }
        });
      }
    });
  }
}

function erweiterteSucheAbsetzen() {
  var $suchDialog = $('#erweiterte-suche');
  $.ajax({
    url: $suchDialog.data('erweiterte-suche-url'),
    method: 'get',
    contentType: "application/json",
    data: {
      suche: {
        vorname:  $suchDialog.find('#vorname').val(),
        nachname: $suchDialog.find('#nachname').val(),
        ort:      $suchDialog.find('#ort').val(),
        kurs_id:  $suchDialog.find('#kurs_id').val()
      }
    },
    cache: false,
    beforeSend: function() {
      $suchDialog.find('.erweiterte-suche-resultate').showAjaxLoadingIndicator();
    },
    success: function(html) {
      $suchDialog.find('div.resultate').html(html);
      erweiterteSucheResultateKlickbarMachen();
    }
  });
}

function erweiterteSucheResultateKlickbarMachen() {
  $('.erweiterte-suche-auswahl').on('click', function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#erweiterte-suche').modal('hide');

    var person_id    = $(this).data('id');
    var person_label = $(this).data('label');
    var adresse      = $(this).data('adresse');
    var im_ausland   = $(this).data('im_ausland');
    var hat_mail     = $(this).data('hat_mail');

    var autocompleteInputId = $('#erweiterte-suche').data('label-field-id');
    var $autocompleteInput  = $("input[name='" + autocompleteInputId + "'], #" + autocompleteInputId);
    $autocompleteInput.val(person_label);

    updatePersonFields($autocompleteInput, person_id, adresse, im_ausland, hat_mail)
  });
}


var $duplikateSucheButtons = $('a.duplikate-suche');
if($duplikateSucheButtons.length > 0) {
  $duplikateSucheButtons.on('click', function() {
    var $this = $(this);
    var $suchDialog = $('#duplikate-suche');
    var personData = $this.data('person');

    $suchDialog.data('person-id', personData.id);
    $suchDialog.data('duplikate-suche-url', $this.data('duplikate-suche-url') || '/personen/duplikate_suche');

    $suchDialog.find('#vorname').val(personData.vorname);
    $suchDialog.find('#nachname').val(personData.nachname);

    $suchDialog.find('div.resultate').html('');
    $suchDialog.modal('show');

    duplikateSucheAbsetzen();
  });
  $.ajax({
    url: '/personen/duplikate_suche', // Das Template nehmen wir immer von dort, duplikate-suche-url ist hier egal.
    cache: false,
    success: function(html) {
      $('body').append(html);
      var $suchDialog = $('#duplikate-suche');
      $suchDialog.find('a.do-duplikate-suche').on('click', function() {
        duplikateSucheAbsetzen();
      });
      $suchDialog.find('input[type="text"]').on('keydown', function(event) {
        if(event.which == 13) { // Enter
          duplikateSucheAbsetzen();
        }
      });
    }
  });
}

function duplikateSucheAbsetzen() {
  var $suchDialog = $('#duplikate-suche');
  $.ajax({
    url: $suchDialog.data('duplikate-suche-url'),
    method: 'get',
    contentType: "application/json",
    data: {
      person_id: $suchDialog.data('person-id'),
      suche: {
        vorname:  $suchDialog.find('#vorname').val(),
        nachname: $suchDialog.find('#nachname').val()
      }
    },
    cache: false,
    beforeSend: function() {
      $suchDialog.find('.duplikate-suche-resultate').showAjaxLoadingIndicator();
    },
    success: function(html) {
      $suchDialog.find('div.resultate').html(html);
    }
  });
}


function updatePersonFields($autocompleteField, id, adresse, im_ausland, hat_mail) {
  var $form = $autocompleteField.parents('form');
  var $hiddenField    = $form.find($autocompleteField.data('hidden-field-id'));
  var $adresseField   = $form.find($autocompleteField.data('adresse-field-id'));
  var $adresseBtn     = $form.find($autocompleteField.data('adresse-field-id') + ' + .adress_aenderungs_antrag_button').find('a');
  var $imAuslandField = $form.find($autocompleteField.data('im-ausland-field-id'));

  if ($autocompleteField.data('ohne-mail-warnen').toString() === 'true') {
    if (hat_mail.toString() === 'false') {
      $autocompleteField.closest('.control-group').addClass('error');
      if ($autocompleteField.closest('.control-group').find('.help-inline').filter(function() {
          return $(this).text() === $($('.mail-warnung')[0]).text();
        }).length <= 1) {
        $autocompleteField.closest('.control-group').find('.mail-warnung').show();
      }
    } else {
      if ($autocompleteField.closest('.control-group').find('.help-inline').length <= 1) {
        $autocompleteField.closest('.control-group').removeClass('error');
      }
      $autocompleteField.closest('.control-group').find('.mail-warnung').hide();
    }
  }

  $hiddenField.val(id);
  $adresseField.val(adresse);
  if ($adresseBtn.length > 0) { // Nur wenn ein solcher Knopf vorhanden ist.
    $adresseBtn.attr('href', $adresseBtn.attr('href').replace(/\/\d+\/adress_aenderung\//, '/' + id + '/adress_aenderung/'));
    if (id == 0) {
      $adresseBtn.hide('fast');
    } else {
      $adresseBtn.show('slow');
    }
  }
  $imAuslandField.val(im_ausland);
}
