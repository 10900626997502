import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dispatch-event"
export default class extends Controller {
  static values = {
    name: String,
    elementSelector: { type: String, default: "document" },
    autoSend: { type: Boolean, default: true },
  };

  connect() {
    if (this.autoSendValue) {
      this.dispatchCustomEvent();
    }
  }

  dispatchCustomEvent(event) {
    const customEvent = new Event(this.nameValue)
    this.element.dispatchEvent(customEvent)
  }

  get element() {
    switch (this.elementSelectorValue) {
      case "document":
        return document;
      case "window":
        return window;
      default:
        return document.querySelector(this.elementSelectorValue);
    }
  }
}
