const STAMMDATEN_LADEN = 'STAMMDATEN_LADEN';

export const stammdatenLaden = stammdaten => ({ type: STAMMDATEN_LADEN, stammdaten });

export const stammdatenReducer = (state, action) => {
  switch (action.type) {
    case STAMMDATEN_LADEN:
      return {
        ...state,
        ...action.stammdaten,
      };
    default:
      return state || {};
  }
};
