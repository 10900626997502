import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js"

// Connects to data-controller="request"
export default class extends Controller {

  async makeRequest(event) {
    // Wenn element ein Link oder Formular Submit Button ist -> Nichts machen
    if (event.target.nodeName === "A" || event.target.type === "submit") {
      return;
    }

    this.url = this.element.dataset.url;
    this.body = this.element.dataset.body;
    this.method = this.element.dataset.method || "post";
    this.responseKind = this.element.dataset.responseKind || "turbo-stream";

    // Wenn element ein Select -> Selected value zu url hinzufügen
    if (event.target.nodeName === "SELECT") {
      this.url += event.target.selectedOptions[0].value
    }
    
    const request = new FetchRequest(this.method, this.url, { body: this.body, responseKind: this.responseKind })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      if (this.responseKind === "html") {
        document.body.innerHTML = body
      }
    }
  }
  
}
