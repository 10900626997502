import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
} from '@mui/material';

import NummerAuswaehlen from '../Dialoge/NummerAuswaehlen';
import { t, t_var } from '../../common/i18n/translate';
import { useAppSelector } from '../redux/hooks';

function NummerFrage({ frage, antwortDetails, updateAntwort }) {
  const { rapport } = useAppSelector(state => state.juryrapport);
  const [showDialog, setShowDialog] = useState();

  const config = frage.config || {};
  const { antwort } = antwortDetails;
  const nummer = antwortDetails.details || config.default || 0;

  const onDialogClose = (neueNummer) => {
    if (neueNummer !== null) {
      neueNummer = parseInt(neueNummer);
      updateAntwort(neueNummer > 0, neueNummer);
    }

    setShowDialog(false);
  };

  return (
    <ListItem divider className="nummerFrage">
      <NummerAuswaehlen
        value={nummer}
        label={t_var(frage, 'untertitel')}
        showDialog={showDialog}
        onDialogClose={onDialogClose}
      />

      <ListItemText
        primary={t_var(frage, 'titel')}
        secondary={(
          <Typography component="span" variant="body2">
            {t_var(frage, 'untertitel')}
            <span className="antwort">
              {' '}
              {nummer}
            </span>
          </Typography>
        )}
      />
      <ListItemSecondaryAction>
        <Button
          variant={antwort === true ? 'contained' : null}
          color={antwort === true ? 'primary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => setShowDialog(true)}
        >
          {t('ja')}
        </Button>
        <Button
          variant={antwort === false ? 'contained' : null}
          color={antwort === false ? 'secondary' : 'default'}
          disabled={rapport.status !== 'offen'}
          onClick={() => updateAntwort(false, 0)}
        >
          {t('nein')}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default NummerFrage;
