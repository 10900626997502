import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Switch,
  Tooltip,
  Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { t } from '../../common/i18n/translate';
import { PAGINATION_ITEMS_PER_PAGE } from '../../common/constants';
import {
  veranstalterAbfragen,
  veranstalterSpeichern,
  excelExport,
} from '../redux/modules/veranstalter';
import { setPage } from '../redux/modules/veranstalterFilter';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import VeranstalterFilter from './VeranstalterFilter';
import VeranstalterEditDialog from './VeranstalterEditDialog';

function Veranstalter() {
  const [veranstalterInBearbeitung, setVeranstalterInBearbeitung] = useState(null);
  const [sortModel, setSortModel] = useState({ field: 'name', order: 'ASC' });
  const { veranstalterFilter: filter, veranstalter } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const handleVeranstalterAbfragen = (fuerPage, mitFilter, sortField, sortOrder) => veranstalterAbfragen(fuerPage, mitFilter, sortField, sortOrder)(dispatch);
  // TODO: inefficient to handleVeranstalterAbfragen again just because status of one/some veranstaltung changed
  const handleVeranstalterSpeichern = async (durchfuehrungData) => {
    await veranstalterSpeichern(durchfuehrungData)(dispatch);
    handleVeranstalterAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  };
  const handleSetPage = neuePage => dispatch(setPage(neuePage));

  useEffect(() => {
    handleVeranstalterAbfragen(filter.page, filter.filter, sortModel.field, sortModel.order);
  }, [filter, sortModel]);

  return (
    <>
      <Paper className="flex-row width-100 no-margin">
        <div className="margin-1 flex-1">
          <div className="flex-row">
            <div className="flex-1">&nbsp;</div>
            <Button onClick={e => setVeranstalterInBearbeitung({})} variant="contained" size="small">
              <Icon fontSize="small" color="action">add</Icon>
            </Button>
            {/* {veranstalter.liste.length > 0 && (
              <Button
                sx={{ marginLeft: '1em' }}
                onClick={() => excelExport(filter.filter)}
                variant="contained"
              >
                {t('export_xlsx')}
              </Button>
            )} */}
          </div>
          <VeranstalterFilter />
          <DataGrid
            // STYLING
            density="compact"
            sx={{ marginTop: 2 }}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            // DATA CONFIG
            columns={[
              { field: 'name', headerName: t('name'), flex: 3 },
              { field: 'rayon', headerName: t('sektion_rayon'), flex: 1 },
              { field: 'partner_id', headerName: t('partner_id'), flex: 1 },
              { field: 'regionalverband_code', headerName: t('regionalverband_gekuerzt'), flex: 1, sortable: false },
              {
                field: 'regionalverband_mitglied',
                headerName: t('mitglied'),
                renderCell: p => (
                  <Switch
                    checked={p.row.regionalverband_mitglied}
                    disabled
                    color="primary"
                    size="small"
                  />
                ),
                flex: 1,
              },
              {
                field: 'aktiv',
                headerName: t('aktiv'),
                renderCell: p => (
                  <Switch
                    checked={p.row.aktiv}
                    disabled
                    color="primary"
                    size="small"
                  />
                ),
                flex: 1,
              },
              {
                field: 'aktionen',
                headerName: t('aktionen'),
                renderCell: p => (
                  <Tooltip title={t('edit')}>
                    <IconButton size="small">
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                  </Tooltip>
                ),
                flex: 1,
                sortable: false,
              },
            ]}
            rows={veranstalter.liste}
            loading={veranstalter.isLoading}
            // SORTING
            sortingMode="server"
            onSortModelChange={(newSortModel) => {
              if (newSortModel.length > 0) {
                // resette auf erste page und loese neuen request mit sortierung aus
                handleSetPage(1);
                setSortModel({ field: newSortModel[0].field, order: newSortModel[0].sort.toLocaleUpperCase() });
              }
            }}
            // PAGINATION
            paginationMode="server"
            pageSize={PAGINATION_ITEMS_PER_PAGE}
            rowsPerPageOptions={[]}
            rowCount={veranstalter.total ?? 0}
            page={filter.page - 1}
            onPageChange={newPage => handleSetPage(newPage + 1)}
            // OTHER PROPS
            onRowClick={params => setVeranstalterInBearbeitung(params.row)}
          />
        </div>
      </Paper>
      <VeranstalterEditDialog
        veranstalter={veranstalterInBearbeitung}
        onDialogClose={(data) => {
          if (data) {
            handleVeranstalterSpeichern(data);
          }
          setVeranstalterInBearbeitung(null);
        }}
        title={`${t('veranstalter')} ${(Object.keys(veranstalterInBearbeitung ?? {}).length > 0 ? t('edit') : t('add')).toLocaleLowerCase()}`}
      />
    </>
  );
}

export default Veranstalter;
