document.addEventListener("turbo:load", function() {
  $.datepicker.regional['de'] = {
    closeText: 'Schliessen',
    currentText: 'Heute',
    monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
    monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
    dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
    dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
    dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
    dateFormat: 'dd.mm.yy',
  };
  $.datepicker.setDefaults($.datepicker.regional['de']);

  $('input.datepicker:not(*[readonly="readonly"])').datepicker();

  // $('input.datepicker:not(*[readonly="readonly"])').each( function(index, input) {
  //   $(input).datepicker({
  //     minDate: $(input).attr('data_min_datum'),
  //     maxDate: $(input).attr('data_max_datum'),
  //     changeMonth: true,
  //     changeYear: true,
  //     yearRange: $(input).attr('data_year_range') || "c-9:c+10"
  //   });
  // });
});

document.addEventListener("turbo:render", function() {
  $('input.datepicker:not(*[readonly="readonly"])').datepicker();
});
