import type { ThunkAction } from 'redux-thunk';
import type { Action } from 'redux';

import { apiService } from '../../common/services';

type ThunkActionCreator = ThunkAction<void, State, unknown, Action<string>>;

interface ActionWithData extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: string]: any;
  // [propName: string]: unknown,
}

interface Rapport {
  id: number,
  veranstaltung_id: number,
  disziplin_id: number,
  status: string,
}
interface Disziplin {
  id: number,
  code: string,
  neuer_code: string,
  bezeichnung_de: string,
  bezeichnung_fr: string,
}
interface Beteiligung {
  id: number,
  name: string,
  ort: string,
  geschlecht: string,
  funktion: {
    id: number,
    code: string,
    bezeichnung_de: string,
    bezeichnung_fr: string,
  }
}
type Veranstaltung = {
  id: number,
  sequenz_nummer: number,
  von: string,
  bis: string,
  von_bis: string,
  ort: string,
  kanton: string,
  disziplinen: Disziplin[],
  typ_code: string,
  modus_code: string,
  beteiligungen: Beteiligung[],
};
interface Frage {
  id: number,
  typ: string,
  titel_de: string,
  titel_fr: string,
  untertitel_de: string,
  untertitel_fr: string,
  parent_id: number,
  children: Frage[],
  config: unknown,
}
interface Antwort {
  id?: number,
  frage_id: number,
  antwort: boolean | null,
  details: unknown,
  juryrapport_id?: number,
}
interface PferdAktion {
  pferd_aktion_id?: number,
  pferd_id: string,
  pferd_name: string,
  pferd_label: string,
  juryrapport_id?: number,
}
interface SelfserviceBenutzer {
  id: number,
  vorname: string,
  nachname: string,
  ort: string,
}

const LOAD_JURYRAPPORT = 'equis/selfservice/juryrapporte/load_juryrapport';
const LOAD_JURYRAPPORT_SUCCESS = 'equis/selfservice/juryrapporte/load_juryrapport_success';
const LOAD_JURYRAPPORT_FAIL = 'equis/selfservice/juryrapporte/load_juryrapport_fail';
const UPLOAD_ANTWORT = 'equis/selfservice/juryrapporte/upload_antwort';
const UPLOAD_ANTWORT_SUCCESS = 'equis/selfservice/juryrapporte/upload_antwort_success';
const UPLOAD_ANTWORT_FAIL = 'equis/selfservice/juryrapporte/upload_antwort_fail';
const COMPLETE_JURYRAPPORT = 'equis/selfservice/juryrapporte/complete_juryrapport';
const COMPLETE_JURYRAPPORT_SUCCESS = 'equis/selfservice/juryrapporte/complete_juryrapport_success';
const COMPLETE_JURYRAPPORT_FAIL = 'equis/selfservice/juryrapporte/complete_juryrapport_fail';
const SET_ANTWORT = 'equis/selfservice/juryrapporte/set_antwort';
const SET_PFERD_AKTION = 'equis/selfservice/juryrapporte/set_pferd_aktion';
const REMOVE_PFERD_AKTION = 'equis/selfservice/juryrapporte/remove_pferd_aktion';
const REMOVE_ALL_PFERD_AKTION = 'equis/selfservice/juryrapporte/remove_all_pferd_aktion';

type State = {
  rapport: Rapport,
  veranstaltung: Veranstaltung,
  fragen: Frage[],
  antworten: Antwort[],
  pferdAktionen: PferdAktion[],
  selfserviceBenutzer: SelfserviceBenutzer,
  isLoading: boolean,
  error: string | null,
};

const INITIAL_STATE: State = {
  rapport: null,
  veranstaltung: null,
  fragen: [],
  antworten: [],
  pferdAktionen: [],
  selfserviceBenutzer: null,
  isLoading: true,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: State = INITIAL_STATE, action: ActionWithData): State => {
  switch (action.type) {
    case LOAD_JURYRAPPORT:
    case COMPLETE_JURYRAPPORT:
      return { ...state, isLoading: true };
    case LOAD_JURYRAPPORT_SUCCESS: {
      const { rapport, fragen, veranstaltung, antworten, pferd_aktionen, selfservice_benutzer, angemeldeter_benutzer } = action.loadedJuryrapport;
      return {
        ...state,
        rapport,
        veranstaltung,
        fragen,
        antworten,
        pferdAktionen: pferd_aktionen,
        selfserviceBenutzer: selfservice_benutzer,
        isLoading: false,
        error: null,
      };
    }
    case UPLOAD_ANTWORT_SUCCESS: {
      const neueAntworten = [...state.antworten];
      const index = neueAntworten.findIndex(a => a.frage_id === action.frageId);

      if (index !== -1) {
        neueAntworten[index].antwort = action.neueAntwort.antwort;
        neueAntworten[index].details = action.neueAntwort.details;
      } else {
        neueAntworten.push({
          frage_id: action.frageId,
          antwort: action.neueAntwort.antwort,
          details: action.neueAntwort.details,
        });
      }

      return {
        ...state,
        antworten: neueAntworten,
        isLoading: false,
      };
    }
    case COMPLETE_JURYRAPPORT_SUCCESS:
      const { rapport } = action.loadedJuryrapport;
      return {
        ...state,
        rapport,
        isLoading: false,
      };
    case SET_ANTWORT: {
      const neueAntworten = [...state.antworten];
      const index = neueAntworten.findIndex(a => a.frage_id === action.frageId);

      if (index !== -1) {
        neueAntworten[index].antwort = action.neueAntwort.antwort;
        neueAntworten[index].details = action.neueAntwort.details;
      } else {
        neueAntworten.push({
          frage_id: action.frageId,
          antwort: action.neueAntwort.antwort,
          details: action.neueAntwort.details,
        });
      }

      return {
        ...state,
        antworten: neueAntworten,
      };
    }
    case SET_PFERD_AKTION: {
      const pferdAktionen = [...state.pferdAktionen];
      const index = pferdAktionen.findIndex(a => a.pferd_aktion_id === action.pferdAktionId);

      if (index !== -1) {
        pferdAktionen[index].pferd_id = action.neuePferdAktion.pferd_id;
        pferdAktionen[index].pferd_name = action.neuePferdAktion.pferd_name;
      } else {
        pferdAktionen.push({
          pferd_aktion_id: action.pferdAktionId,
          pferd_id: action.neuePferdAktion.pferd_id,
          pferd_name: action.neuePferdAktion.pferd_name,
          juryrapport_id: action.neuePferdAktion.juryrapport_id,
        });
      }

      return {
        ...state,
        pferdAktionen,
      };
    }
    case REMOVE_PFERD_AKTION: {
      const pferdAktionen = [...state.pferdAktionen];

      const index = pferdAktionen.findIndex(a => a.pferd_aktion_id === action.pferdAktionId);
      if (index > -1) {
        pferdAktionen.splice(index, 1);
      }

      return {
        ...state,
        pferdAktionen,
      };
    }
    case REMOVE_ALL_PFERD_AKTION: {
      return {
        ...state,
        pferdAktionen: [],
      };
    }
    case LOAD_JURYRAPPORT_FAIL:
    case UPLOAD_ANTWORT_FAIL:
    case COMPLETE_JURYRAPPORT_FAIL:
      return {
        ...state,
        error: action.errorMsg,
      };
    default:
      return state;
  }
};

export const loadJuryrapport = (juryrapportId: number): ThunkActionCreator => async (dispatch) => {
  dispatch({ type: LOAD_JURYRAPPORT });
  const response = await apiService.get(`backend/juryrapporte/${juryrapportId}`);

  if (response?.data) {
    dispatch({
      type: LOAD_JURYRAPPORT_SUCCESS,
      loadedJuryrapport: response.data,
    });
  } else {
    // TODO: nanann
    dispatch({ type: LOAD_JURYRAPPORT_FAIL, errorMsg: 'Juryrapport konnnte nicht geladen werden.' });
  }
};

export const completeJuryrapport = (juryrapportId: number): ThunkActionCreator => async (dispatch) => {
  dispatch({ type: COMPLETE_JURYRAPPORT });

  const response = await apiService.put(`backend/juryrapporte/${juryrapportId}?perform=submit`);

  if (response?.data) {
    dispatch({
      type: COMPLETE_JURYRAPPORT_SUCCESS,
      loadedJuryrapport: response.data,
    });
  } else {
    dispatch({ type: COMPLETE_JURYRAPPORT_FAIL, errorMsg: response.data.messages });
  }
};

export const uploadAntwort = (juryrapportId: number, frageId: number, antwort: boolean | null, details: unknown): ThunkActionCreator => async (dispatch) => {
  dispatch({ type: UPLOAD_ANTWORT });
  const neueAntwort = { antwort, details };

  const response = await apiService.post(`backend/juryrapporte/${juryrapportId}/set_antwort`, { frage_id: frageId, antwort: neueAntwort });

  if (response?.data) {
    dispatch({
      type: UPLOAD_ANTWORT_SUCCESS,
      frageId,
      neueAntwort,
    });
  } else {
    dispatch({ type: UPLOAD_ANTWORT_FAIL, errorMsg: response.data.messages });
  }
};

export const setAntwort = (frageId: number, antwort: boolean | null, details: unknown): ThunkActionCreator => async (dispatch) => {
  const neueAntwort = { antwort, details };

  dispatch({
    type: SET_ANTWORT,
    frageId,
    neueAntwort,
  });
};

export const setPferdeAktionFuerJueryrapport = (pferdAktionId: number, neuePferdAktion: PferdAktion, rapportId: number): ThunkActionCreator => async (dispatch) => {
  dispatch({
    type: SET_PFERD_AKTION,
    pferdAktionId,
    neuePferdAktion,
  });
};

export const removePferdeAktionFromStore = (pferdAktionId: number): ThunkActionCreator => async (dispatch) => {
  dispatch({
    type: REMOVE_PFERD_AKTION,
    pferdAktionId,
  });
};

export const removeAllPferdeAktionFromStore = (pferdAktionId: number): ThunkActionCreator => async (dispatch) => {
  dispatch({
    type: REMOVE_ALL_PFERD_AKTION,
  });
};

export const uploadPferdeAktion = (rapportID: number, pferdAktionId: number, typ: string, pferdId: string): ThunkActionCreator => async (dispatch) => {
  const response = await apiService.post(`backend/juryrapporte/${rapportID}/pferd_aktionen`, { pferd_aktion_id: pferdAktionId, pferd_aktion_typ: typ, pferd_aktion_pferd_id: pferdId });
  if (response?.data) {
    return response.data;
  }
  alert(`${response.status}: ${response.statusText}`);
};

export const deletePferdAktionen = (rapportID: number, pferdAktionIds: number): ThunkActionCreator => async (dispatch) => {
  const response = await apiService.delete(`backend/juryrapporte/${rapportID}/pferd_aktionen?pferd_aktion_ids=${pferdAktionIds}`);
  if (response?.data) {
    return response.data;
  }
  alert(`${response.status}: ${response.statusText}`);
};
