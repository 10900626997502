import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast-handler"
export default class extends Controller {
  static values = {
    animation:  { type: Boolean, default: false },
    autohide: { type: Boolean, default: true },
    duration:  { type: Number, default: 2000 },
  }

  connect() {
    this.toast = bootstrap.Toast.getOrCreateInstance(this.element, this.toastOptions)
    this.toast.show()
  }

  disconnect() {
    this.toast.dispose()
  }

  beforeTurboCache() {
    this.element.remove()
  }

  get toastOptions() {
    return {
      animation: this.animationValue,
      autohide: this.autohideValue,
      delay: this.durationValue,
    }
  }
}
