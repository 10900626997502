import { StreamActions } from "@hotwired/turbo"
import { toast } from "../global/toast"
import { isMobileApp } from "../global/utils"
import { bootstrapToast } from "../utils/bs_toast"

StreamActions.notify = function() {
  const message = this.getAttribute("message")

  // Optionale Optionen
  const type = this.getAttribute("type")
  const duration = this.getAttribute("duration")
  const autohide = this.getAttribute("autohide")
  const animation = this.getAttribute("animation")

  // Nur Optionen setzen, wenn sie übergeben wurden
  const options = {}
  if (type) options.type = type
  if (duration) options.duration = duration
  if (autohide) options.autohide = autohide
  if (animation) options.animation = animation

  showFlashMessage(message, options)
}

StreamActions.dispatch_js_event = function () {
  const eventName = this.getAttribute("event_name");
  const event = new Event(eventName);
  window.dispatchEvent(event);
};

StreamActions.close_bs_modal = function() {
  window.dispatchEvent(new CustomEvent("equis:hide-bs-modal"));
}

StreamActions.postfinance_light_box_oeffnen = function() {
  const postfinanceLightBoxUrl = this.getAttribute("lightbox_js_url");
  const script = document.createElement('script');
  script.src = postfinanceLightBoxUrl;
  script.onload = function () {
    if (window.LightboxCheckoutHandler == undefined) {
      alert("PostFinance ist momentan nicht erreichbar");
    } else {
      window.LightboxCheckoutHandler.startPayment(null, function(){
        alert("PostFinance ist momentan nicht erreichbar");
      });
    }
  }
  document.head.appendChild(script);
}

function showFlashMessage(message, options) {
  if (isMobileApp) {
    bootstrapToast(message, options)
  } else {
    toast(options.type, message)
  }
}
