import axios from 'axios';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const setBaseUrl = (baseURL: string) => {
  instance.defaults.baseURL = baseURL;
};

export const updateRequestHeaders = (userId: string, apiToken: string, authType: string) => {
  instance.defaults.headers.common['x-auth-id'] = userId;
  instance.defaults.headers.common['x-auth-token'] = apiToken;
  instance.defaults.headers.common['x-auth-type'] = authType;
};

export default instance;
