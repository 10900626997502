// https://github.com/jonatanklosko/material-ui-confirm/blob/master/src/ConfirmationDialog.js
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { t } from './i18n/translate';

function ConfirmationDialog({ open, options, onCancel, onConfirm, onClose }) {
  const {
    title = t('bestaetigen'),
    description,
    content,
    confirmationText = t('ja'),
    cancellationText = t('nein'),
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null}>
      {title && (
        <DialogTitle {...titleProps}>{title}</DialogTitle>
      )}
      {content ? (
        <DialogContent {...contentProps}>
          {content}
        </DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions>
        <Button {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </Button>
        <Button color="primary" {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
