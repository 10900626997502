import React, { useState, useEffect } from 'react';
import { Icon, Button, CircularProgress } from '@mui/material';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { connect } from 'react-redux';

import { loadAusschreibungBild, uploadAusschreibungBild, deleteAusschreibungBild } from '../../../common/modules/api';
import DropZone from './DropZone';
import './AusschreibungBild.css';

function AusschreibungBild(props) {
  const [uploading, setUploading] = useState(false);
  const [bild, setBild] = useState();

  useEffect(() => {
    loadAusschreibungBild(props.veranstaltung.id, props.position, 'original', (url) => {
      setBild(url);
    });
  }, []);

  const handleChange = (files) => {
    const file = files[0];
    setUploading(true);

    uploadAusschreibungBild(
      props.veranstaltung.id,
      {
        position: props.position,
        bild: file,
      },
      (url) => {
        setUploading(false);
        setBild(url);
        props.neuLadenCallback();
      },
    );
  };

  let istZuKlein = false;
  const warnung = props.bildWarnungen.find(item => item.position === props.position);
  if (warnung) {
    istZuKlein = warnung.zu_klein;
  }

  const zuKleinContent = (
    <span style={{ position: 'absolute', padding: '120px 0 0 0', color: 'rgba(128, 128, 128, 0.8)', fontSize: '40px' }}>
      <Icon fontSize="inherit">warning</Icon>
      <PhotoSizeSelectLargeIcon fontSize="inherit" />
    </span>
  );

  return (
    <div className="kopf-bild">
      {bild ? (
        <div className="image-wrapper align-center">
          {istZuKlein && zuKleinContent}
          <img src={bild} alt={props.position} />
          <Button
            variant="contained"
            onClick={() => {
              deleteAusschreibungBild(props.veranstaltung.id, props.position, () => {
                setBild(undefined);
                props.neuLadenCallback();
              });
            }}
          >
            <Icon color="error">close</Icon>
          </Button>
        </div>
      ) : (
        <div className="upload-area flex-column align-center">
          <DropZone callback={handleChange} />
          {uploading && (
            <div className="uploading">
              <CircularProgress />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AusschreibungBild);
