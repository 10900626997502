var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// ReactRails bei Turbo Events mounten und unmounten
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:before-cache', ReactRailsUJS.handleUnmount)

// ReactRails kann zusätzlich mit diesen Events mounten und unmounten werden
document.addEventListener('mount-react-rails', ReactRailsUJS.handleMount)
document.addEventListener('unmount-react-rails', ReactRailsUJS.handleUnmount)
