import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { List, ListItem, ListItemText } from '@mui/material';
import { connect } from 'react-redux';

import './ProzessFortschritt.scss';
import { getLanguage } from '../../../common/i18n/translate';

function ProzessFortschritt({ veranstaltung, stammdaten }) {
  const prozessListe = {
    in_erfassung: <CreateIcon />,
    gemeldet: <CreateIcon />,
    zur_annahme: <VisibilityIcon />,
    zur_voranzeige: <SpellcheckIcon />,
    in_ausschreibung: <SpellcheckIcon />,
    zur_kontrolle_svps: <VisibilityIcon />,
    zur_kontrolle_extern: <VisibilityIcon />,
    geplant: <AllInboxIcon />,
    durchgefuehrt: <DoneIcon />,
    abgesagt: <ClearIcon />,
  };

  return (
    <div className="prozess-fortschritt-container">
      <List>
        {Object.entries(prozessListe).map(([statusKey, iconJsx], index) => (
          <ListItem classes={{ root: veranstaltung.status === statusKey ? 'aktuell' : '' }} key={statusKey}>
            {iconJsx}
            <ListItemText classes={{ root: 'prozess-listen-text' }}>{stammdaten.veranstaltung_status.find(s => s.code === statusKey)?.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltung: state.veranstaltungen.current,
  stammdaten: state.stammdaten[getLanguage()],
});

export default connect(mapStateToProps, null)(ProzessFortschritt);
