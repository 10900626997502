import detectBrowserLanguage from 'detect-browser-language';

import { de } from './de';
import { fr } from './fr';
import { it } from './it';

const defaultLang = 'de';
const allTranslations = {
  de,
  fr,
  it,
};

export const setLanguage = (lang) => {
  let willUseLang = lang;
  if (Object.keys(allTranslations).indexOf(lang) === -1) {
    willUseLang = defaultLang;
  }
  localStorage.setItem('lang', willUseLang);
  return willUseLang;
};

export const getLanguage = () => localStorage.getItem('lang') || defaultLang;

const localLang = localStorage.getItem('lang');
const browserLang = detectBrowserLanguage().substr(0, 2);
const hiddenFieldLang = document.getElementById('locale') && document.getElementById('locale').value;
const lang = setLanguage(hiddenFieldLang || localLang || browserLang);

export const t = (key, overrideLocale = undefined) => {
  const translations = allTranslations[overrideLocale || lang] || {};
  return translations[key] || `MISSING_TRANSLATION: ${key}`;
};

export const t_var = (object, key) => {
  const translated_key = `${key}_${lang}`;
  if (object[translated_key] !== undefined) {
    return object[translated_key] || '';
  }
  return `MISSING_VARIABLE: ${translated_key}`;
};
