import type { Configuration } from 'rollbar';
import { apiService } from './services';

interface Person {
  id: number,
  name: string,
  ort: string,
  kanton: string,
}

interface Pferd {
  pferd_id: number,
  pferd_name: string,
  pferd_label: string,
  fei_passnummer: string,
}

interface PLZ {
  id: number,
  value: number,
  label: string,
  ort: string,
  kanton: string,
  land: string,
}

export const suchePersonen = async (query: string): Promise<Person[]> => {
  const response = await apiService.get(`personensuche?q=${query}`);
  return response?.data ?? [];
};

export const suchePferde = async (query: string): Promise<Pferd[]> => {
  const response = await apiService.get(`pferdesuche?q=${query}`);
  return response?.data ?? [];
};

export const suchePLZ = async (query: string): Promise<PLZ[]> => {
  const response = await apiService.get(`plz?term=${query}`);
  return response?.data ?? [];
};

export const fetchRollbarConfig = async (callback: (config: Configuration | null) => void): Promise<void> => {
  const response = await apiService.get('rollbar_configuration');
  if (callback && response?.data) {
    callback(response?.data);
  }
};
