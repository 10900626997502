import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
} from '@mui/material';

import { t, getLanguage } from '../../../common/i18n/translate';
import Formular from '../../../common/Formular';
import { updateStallungen, deleteStallungen } from '../../../common/modules/api';
import { MUI_PAPER_ELEVATION_LEVELS } from '../../../common/constants';
import { benutzerWurdeImpersoniert } from '../../../common/modules/helpers';

function Stallungen(props) {
  const stallungsangebotAktualisieren = (data) => {
    updateStallungen(props.veranstaltung.id, {
      ...data,
      stallungsangebot_aktiv: true,
    });
  };
  const stallungsangebotLoeschen = () => {
    deleteStallungen(props.veranstaltung.id);
  };

  const formFields = [
    {
      key: 1,
      feldTyp: 'wrapper',
      className: 'flex-row',
      children: [
        {
          feldTyp: 'autocomplete',
          name: 'berechnungsschema',
          options: props.stammdaten.stallung_berechnungsschemata,
          optionValue: 'berechnungsschema',
          optionLabel: 'label',
          required: true,
        },
        {
          feldTyp: 'datepicker',
          name: 'von',
          requiredCondition: values => values.stallungsangebot_aktiv,
          disabledCondition: values => !values.stallungsangebot_aktiv,
        },
        {
          feldTyp: 'datepicker',
          name: 'bis',
          requiredCondition: values => values.stallungsangebot_aktiv,
          disabledCondition: values => !values.stallungsangebot_aktiv,
        },
        {
          feldTyp: 'text',
          name: 'betrag',
          requiredCondition: values => values.stallungsangebot_aktiv,
          disabledCondition: values => !values.stallungsangebot_aktiv,
        },
      ],
    },
  ];

  if (props.veranstaltung.forms.stallungen.stallungsangebot_aktiv) {
    return (
      <Formular
        formId="stallungen"
        disabled={props.veranstaltung.id &&
          !benutzerWurdeImpersoniert() && !(
            props.veranstaltung.status === 'in_erfassung'
            || props.veranstaltung.status === 'zur_voranzeige'
            || props.veranstaltung.status === 'in_ausschreibung'
          )
        }
        fields={formFields}
        defaultValues={props.stallungen}
        handleSubmit={stallungsangebotAktualisieren}
        handleDelete={() => {
          stallungsangebotLoeschen();
        }}
        deleteText={t('stallungsangebot_zurueckziehen')}
        wrapInPaper
      />
    );
  }

  return (
    <div className="flex-column flex-1">
      <Card elevation={MUI_PAPER_ELEVATION_LEVELS.SECOND}>
        <div style={{ padding: '20px' }}>
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              {!props.veranstaltung.forms.stallungen.stallungsangebot_aktiv && (
                <Button
                  onClick={(e) => {
                    stallungsangebotAktualisieren({
                      von: props.veranstaltung.forms.veranstaltungsdaten.von,
                      bis: props.veranstaltung.forms.veranstaltungsdaten.bis,
                      betrag: 0,
                    });
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t('stallungsangebot_erfassen')}
                </Button>
              )}
            </div>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  veranstaltungen: state.veranstaltungen,
  stammdaten: state.stammdaten[getLanguage()],
  veranstaltung: state.veranstaltungen.current,
  stallungen: state.veranstaltungen.current.forms.stallungen,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Stallungen);
