import ReduxThunk from 'redux-thunk';

import ReduxLogger from './ReduxLogger';

// @ts-expect-error will be defined in rails layout
const environment = document.getElementById('environment').value;

const middleware = environment === 'development' ? [ReduxLogger, ReduxThunk] : [ReduxThunk];

export default middleware;
