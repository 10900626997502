import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Veranstaltungsdaten from './Veranstaltungsdaten';
import './index.scss';
import { veranstaltungLaden } from '../store/modules/veranstaltungen';

function VeranstaltungErstellen({ veranstaltungLaden }) {
  useEffect(() => {
    veranstaltungLaden({});
  }, []);

  return <Veranstaltungsdaten />;
}

const mapDispatchToProps = dispatch => ({
  veranstaltungLaden: (veranstaltung) => {
    dispatch(veranstaltungLaden(veranstaltung));
  },
});

export default connect(null, mapDispatchToProps)(VeranstaltungErstellen);
